import Table from 'react-bootstrap/Table'

const ElPasoTable = () => {

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th colSpan={3}>Texas 100 Most Congested Roadways List: Locations</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3}>#142: Gateway Blvd /IH 10 (US 54 to Hawkins Blvd), 4.09 miles		</td>
                    </tr>
                    <tr>
                        <td>121K annual person-hours delay per mile</td>
                        <td>495K annual person-hours delay</td>
                        <td>$11.1M annual congestion cost ($2.7M/mile)</td>

                    </tr>

                </tbody>
                <thead>
                    <tr>
                        <th colSpan={3}>Texas 50 Most Congested Freight Bottlenecks: Locations</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3}>#97: Gateway Blvd /IH 10 (US 54 to Hawkins Blvd), 4.09 miles		</td>
                    </tr>
                    <tr>
                        <td>8.4K annual person-hours delay per mile</td>
                        <td>34K annual person-hours delay</td>
                        <td>$2.0M annual congestion cost ($490K/mile)</td>

                    </tr>
                </tbody>
            </Table>
            <h6>Table 1. Problematic Segments Identified in the Texas 100 Most Congested Roadways List and Texas 50 Most Congested Freight Bottlenecks</h6>
        </div>
    );
}

export default ElPasoTable;
