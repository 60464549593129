import Box from "@mui/material/Box";
import BorderScs from "../SupplyChainSensitives/BorderScs.js";

const BorderScsView = () => {
  return (
    <Box sx={{ mt: 15 }}>
      <BorderScs />
    </Box>
  );
};
export default BorderScsView;
