import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);

export default function SideBarChart(props) {
  const { chartHeight, titleText, subtitleText, chartData } = props;

  let i = 0;
  let seriesData = [];
  for (i = 0; i < chartData.length; i += 1) {
    seriesData.push({
      name: chartData[i].activity,
      y: chartData[i].scfi,
    });
  }
  const options = {
    chart: {
      type: "column",
      height: chartHeight,
      //   options3d: {
      //     enabled: true,
      //     alpha: 15,
      //     beta: 20,
      //     depth: 50,
      //     viewDistance: 50,
      //   },
      // plotBackgroundColor: null,
      // plotBorderWidth: null,
      // plotShadow: false,
    },
    title: {
      text: titleText,
      align: "center",
    },
    subtitle: {
      text: subtitleText,
      align: "center",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        // enabled: false,
        text: "SCFI",
      },
    },
    legend: {
      enabled: false,
    },
    // plotOptions: {
    //   column: {
    //     depth: 25,
    //   },
    // },
    series: [
      {
        data: chartData.map((itm) => itm.scfi),
        colorByPoint: true,
      },
    ],

    series: [
      {
        name: "SCFI of Transportation Activities",
        colorByPoint: true,
        data: seriesData,
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
