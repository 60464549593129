import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  BpCheckedIcon,
  BpIcon,
  CheckboxIcon,
  CheckboxCheckedIcon,
} from "../../common/style";
import { colorSchemes } from "../../common/constants";

import {
  Grid,
  Typography,
  Box,
  Slider,
  FormControl,
  InputLabel,
  Switch,
  Tooltip,
  Popover,
  Divider,
  Select,
  MenuItem,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormGroup,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/FileDownload";
import LayersIcon from "@mui/icons-material/Layers";
import Checkbox from "@mui/material/Checkbox";

const LayerControls = (props) => {
  const { baseMap, setBaseMap, mapParameters, setMapParameters } = props;

  const [PopupEl, setPopupEl] = useState(null);
  const PopupClick = (event) => {
    setPopupEl(event.currentTarget);
  };
  const PopupClose = () => {
    setPopupEl(null);
  };
  const open = Boolean(PopupEl);
  const [cargoAirport, setCargoAirport] = useState(false);
  const [ports, setPorts] = useState(false);
  const [truckParking, setTruckParking] = useState(false);
  const [railDwellTimes, setRailDwellTimes] = useState(false);
  const [airportDelay, setAirportDelay] = useState(false);
  const [countryAirportDelay, setCountryAirportDelay] = useState(false);
  const [aisData2021, setAisData2021] = useState(false);

  const MAPBOX_URL =
    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={access_token}";
  //"https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={access_token}";

  const MAPBOX_STREETS_V10_URL =
    "https://api.tiles.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token={access_token}";

  const MAPBOX_ATTRIBUTION = `Map data &copy; 
<a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, 
<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>,
Imagery © <a href="https://www.mapbox.com/">Mapbox</a>`;

  const baseLayers = [
    {
      name: "Streets",
      url: MAPBOX_STREETS_V10_URL,
      id: "mapbox/streets-v11",
    },
    {
      name: "Satellite",
      url: MAPBOX_URL,
      id: "mapbox/satellite-v9",
    },
    {
      name: "Satellite-Streets",
      url: MAPBOX_URL,
      id: "mapbox/satellite-streets-v11",
    },
    {
      name: "Light",
      url: MAPBOX_URL,
      id: "mapbox/light-v10",
    },
    {
      name: "Dark",
      url: MAPBOX_URL,
      id: "mapbox/dark-v10",
    },
    {
      name: "Navigation - Day",
      url: MAPBOX_URL,
      id: "mapbox/navigation-day-v1",
    },
    {
      name: "Navigation - Night",
      url: MAPBOX_URL,
      id: "mapbox/navigation-night-v1",
    },
  ];

  const basemapClick = (event) => {
    setMapParameters({
      ...mapParameters,
      baseMap: event.currentTarget.value,
    });
  };

  useEffect(() => {
    setMapParameters({
      ...mapParameters,
      showCargoAirports: cargoAirport ? "visible" : "none",
      showPorts: ports ? "visible" : "none",
      showTruckParking: truckParking ? "visible" : "none",
      showRailDwellTimes: railDwellTimes ? "visible" : "none",
      showAirportDelay: airportDelay ? "visible" : "none",
      showCountrywideAirportDelay: countryAirportDelay ? "visible" : "none",
      showAisData2021: aisData2021 ? "visible" : "none",
     
    });
  }, [
    cargoAirport,
    truckParking,
    ports,
    railDwellTimes,
    airportDelay,
    countryAirportDelay,
    aisData2021,
    
  ]);

  const handleChange = (event) => {
    console.log("event-check", event.target);
    if (event.target.name === "cargo-airports")
      setCargoAirport(event.target.checked);

    if (event.target.name === "ports") setPorts(event.target.checked);

    if (event.target.name === "truckParking")
      setTruckParking(event.target.checked);
    if (event.target.name === "railDwellTimes")
      setRailDwellTimes(event.target.checked);
    if (event.target.name === "airportDelay")
      setAirportDelay(event.target.checked);
      if (event.target.name === "countrywideAirportDelay")
      setCountryAirportDelay(event.target.checked);
    if (event.target.name === "aisData2021")
      setAisData2021(event.target.checked);
  };

  return (
    <div className="map-control-panel" style={{ top: "75px" }}>
      <div>
        <IconButton
          className="icon settings-icon"
          aria-label="map settings"
          size="small"
          onClick={PopupClick}
          onMouseEnter={PopupClick}
        >
          <Tooltip
            title={<span style={{ fontSize: 14 }}>{"Layer Controls"}</span>}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "common.white",
                  color: "common.black",
                  border: "1px solid",
                },
              },
            }}
            placement="right"
          >
            <LayersIcon />
          </Tooltip>
        </IconButton>
        <Popover
          id="map-settings-popover"
          open={open}
          anchorEl={PopupEl}
          onClose={PopupClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ minWidth: 200, maxWidth: 200, m: 1, pl: 1 }}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <FormGroup sx={{}}>
                  <FormControl>
                    <FormLabel
                      id="basemaps-radio-buttons-group-label"
                      sx={{ m: 0, b: 0 }}
                    >
                      Basemaps:
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="basemaps-radio-buttons-group-label"
                      defaultValue={mapParameters.baseMap}
                      name="basemaps-radio-buttons-group"
                      value={mapParameters.baseMap}
                    >
                      {baseLayers.map((itm) => (
                        <FormControlLabel
                          key={itm.name}
                          value={itm.id}
                          control={
                            <Radio
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                            />
                          }
                          label={
                            <Typography className="basemap-layers">
                              {itm.name}
                            </Typography>
                          }
                          onChange={basemapClick}
                          sx={{ m: 0, b: 0 }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormControl fullWidth size="small">
                    <FormLabel
                      id="layers-checkbox-group-label"
                      sx={{ m: 0, b: 0 }}
                    >
                      Layers:
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checkedIcon={<CheckboxCheckedIcon />}
                          icon={<CheckboxIcon />}
                          checked={
                            mapParameters.showCargoAirports === "visible"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          name="cargo-airports"
                        />
                      }
                      label={
                        <Typography className="map-layers">
                          Cargo Airports
                        </Typography>
                      }
                      sx={{ m: 0, b: 0 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checkedIcon={<CheckboxCheckedIcon />}
                          icon={<CheckboxIcon />}
                          checked={
                            mapParameters.showPorts === "visible" ? true : false
                          }
                          onChange={handleChange}
                          name="ports"
                        />
                      }
                      label={
                        <Typography className="map-layers">Ports</Typography>
                      }
                      sx={{ m: 0, b: 0 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checkedIcon={<CheckboxCheckedIcon />}
                          icon={<CheckboxIcon />}
                          checked={
                            mapParameters.showTruckParking === "visible"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          name="truckParking"
                        />
                      }
                      label={
                        <Typography className="map-layers">
                          Truck Parking
                        </Typography>
                      }
                      sx={{ m: 0, b: 0 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checkedIcon={<CheckboxCheckedIcon />}
                          icon={<CheckboxIcon />}
                          checked={
                            mapParameters.showRailDwellTimes === "visible"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          name="railDwellTimes"
                        />
                      }
                      label={
                        <Typography className="map-layers">
                          Rail Dwell Times
                        </Typography>
                      }
                      sx={{ m: 0, b: 0 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checkedIcon={<CheckboxCheckedIcon />}
                          icon={<CheckboxIcon />}
                          checked={
                            mapParameters.showAirportDelay === "visible"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          name="airportDelay"
                        />
                      }
                      label={
                        <Typography className="map-layers">
                          Airport Delay
                        </Typography>
                      }
                      sx={{ m: 0, b: 0 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checkedIcon={<CheckboxCheckedIcon />}
                          icon={<CheckboxIcon />}
                          checked={
                            mapParameters.showCountrywideAirportDelay === "visible"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          name="countrywideAirportDelay"
                        />
                      }
                      label={
                        <Typography className="map-layers">
                          Country wide Airport Delay
                        </Typography>
                      }
                      sx={{ m: 0, b: 0 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checkedIcon={<CheckboxCheckedIcon />}
                          icon={<CheckboxIcon />}
                          checked={
                            mapParameters.showAisData2021 === "visible"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          name="aisData2021"
                        />
                      }
                      label={
                        <Typography className="map-layers">
                          AIS Data 2021
                        </Typography>
                      }
                      sx={{ m: 0, b: 0 }}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      </div>
      {/* <Divider />
      <div>
        <IconButton
          className="icon export-icon"
          aria-label="export"
          size="small"
          // href="https://arcg.is/1rarjC0"
          href="https://arcg.is/1LK4aj1"
          target="_blank"
        >
          <Tooltip
            title={<span style={{ fontSize: 14 }}>{"Link to downloads"}</span>}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "common.white",
                  color: "common.black",
                  border: "1px solid",
                },
              },
            }}
            placement="right-start"
          >
            <DownloadIcon />
          </Tooltip>
        </IconButton>
      </div> */}
    </div>
  );
};

LayerControls.propTypes = {
  style: PropTypes.object,
};

export default LayerControls;
