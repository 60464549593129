const houston_docs={
    "description" : "Houston",
    "steps": 
    [
      {
        "heading": "  A Countor Map from Barbours Cut Terminal in Houston.",
        "title": "Step 1.Review Contour Maps.",
        "description": " Contour Maps show regional travel times for a selected time of the day to or from a freight generator, such as the Barbours Cut Terminal (see Figure 1). The most congested corridors have a shorter distance from the generator to the contour. For the weekday 5 p.m. contour map, you note that freight leaving the terminal and traveling west along I-10 doesn’t even get to the western edge of Beltway 8 after an hour, while freight eastbound on I-10 can travel much farther in an hour. You decide to take a closer look, reasoning that improvements along the I-10 corridor would make a significant impact on freight movement westbound from the terminal. "
      },
      {
        "heading": "Heat Map of Truck Patterns at Houston Area",
        "title": "Step 2. Analyze Heat Maps:",
        "description": "Heat Maps use GPS data to show vehicle paths (trips) crossing a selected segment during a certain time period, revealing problematic areas. Because origin/destination data is necessary to conduct this analysis, this Freight Fluidity Analysis method cannot be performed in this area."
      },
      {
        "title": "Step 3. Check Travel-Time Traces: ",
        "description": "Travel-time traces show the average travel time and the 95th percentile travel time for a length of roadway beginning at various times of day. The 95th percentile time reflects the variability in the travel times through the corridor due to construction, crashes, or other incidents. For the Houston area, the travel-time traces have been conducted for westbound I-10 from the Louisiana border to downtown Houston. You immediately note a substantial gap between the weekday 95th percentile travel time and the average weekday travel time (see Figure 2). This indicates congestion is causing the delay and not a constant bottleneck caused by other factors (e.g., construction). "
      },
      {
        "title": "Step 4. Review the Texas 100 Most Congested Roadways List and Texas 50 Most Congested Freight Bottlenecks:  ",
        "description": " Next, cross-checking the Texas 100 Most Congested Roadways List and Te xas   50 Most Congested Freight Bottlenecks shows that several segments on I-10 (see Figure 3) in the Houston area are on both lists. Below are the three most congested segments on I-10 selected for a closer look, based on preliminary contour and time-trace analysis (see Table 1)."
      },
      {
        "heading":"Problematic Segments in the Houston Area"
        
      }
    ]
  }

  export default houston_docs;