import React, {
  useState,
} from "react";


import {
  Grid,
  Box,
  Tabs,
  Tab,
} from "@mui/material";

import { supplyChainFluidityOptions } from "../../common/constants";

import ScfBackground from "../supplyChainFluidity/ScfBackground";
import ScfCaseStudy from "../supplyChainFluidity/ScfCaseStudy";

const SupplyChainFluidityView = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {

    setTabValue(newValue);


  };

  const [selectedRoute, setSelectedRoute] = useState(
    supplyChainFluidityOptions[0].options[0].option
  );

  return (
    <Box sx={{ mt: 12 }}>
      <Grid container spacing={0} className="supply-chain-fluidity">

        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ backgroundColor: '#caf2fa' }}>
            <Tab label=" Supply Chain Fluidity Background" maxWidth={false} sx={{
              fontWeight: 'bold',
              fontSize: '1.2rem',
              maxWidth: 'none'
            }} />
            <Tab label={"Case Studies "} sx={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
            }} />


          </Tabs>
          {tabValue === 0 &&
            <ScfBackground />}
          {tabValue > 0 && (
            <ScfCaseStudy />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default SupplyChainFluidityView;
