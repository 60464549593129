import Box from "@mui/material/Box";
import CommodityCorridors from "../commodityCorridors/CommodityCorridors";

const CommodityCorridorsView = () => {
  return (
    <Box sx={{ mt: 12 }}>
      <CommodityCorridors />
    </Box>
  );
};
export default CommodityCorridorsView;
