// Uses Azure Storage for some data.
// Github secret REACT_APP_AZURE_STORAGE_CONNECTION_STRING updated 23June2023, expires 31Aug2024
// Local env variables updated same date.

import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

//components
import MenuAppBar from "./components/ViewPage/MenuAppBar";
import Home from "./components/ViewPage/Home";
import ViewLocationVisuals from "./components/ViewPage/ViewLocationVisuals";
import CommodityCorridorsView from "./components/ViewPage/CommodityCorridorsView.js";
import SupplyChainFluidityView from "./components/ViewPage/SupplyChainFluidityView";
import SupplyChainFluidity from "./components/supplyChainFluidity/ScfBackground.js";

import SupplyChainFluidityCaseStudies from './components/supplyChainFluidity/ScfCaseStudy';
import BorderScsView from './components/ViewPage/BorderSCSensitives'
import FrftResiliency from "./components/ViewPage/FrftResiliency";

import { data } from "jquery";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN;

export default function App() {
  return (
    <div className="wrapper">
      <MenuAppBar></MenuAppBar>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/case-studies" element={<ViewLocationVisuals />}></Route>
        <Route
          path="/commodity-corridors"
          element={<CommodityCorridorsView />}
        ></Route>
        <Route
          path="/supply-chain-fluidity"
          element={<SupplyChainFluidityView />}
        ></Route>
        <Route path="/border-supply-chain" element={<BorderScsView />}></Route>
        <Route path="/freight-resiliency" element={<FrftResiliency/>}></Route>
        {/* <Route path="/supply-chain-fluidity/case-studies" element={<SupplyChainFluidityCaseStudies />}></Route> */}
      </Routes>
    </div>
  );
}
