import React from "react";
import { Link } from "react-router-dom";
import { Grid, AppBar, Box, Toolbar, Typography, Divider } from "@mui/material";
import { DropdownButton, Dropdown, ButtonGroup, Button } from "react-bootstrap";
import { locations } from "../../common/constants";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function MenuAppBar() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (<>
    <Box sx={{ flexGrow: 1, alignItems: "center" }}>
      {/* <AppBar className="sticky" id="menuappbar">
        <Toolbar>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
           
            <Grid item xs={1}>
              <Box
                component="img"
                sx={{
                  height: "8vh",
                  // mt: 1,
                  // ml: 1,
                }}
                alt="The house from the offer."
                src="/Transparent Background Color3lineLOGO_TxDOT_WHITE_300dpi.png"
              />
            </Grid>
            <Grid item xs={1} display="flex">
              <Box
                component="img"
                alt="The house from the offer."
                src="/TTI-Color-reverse.png"
                display="flex"
                height={"4vh"}
                alignItems={"center"}
                // sx={{ mt: 1 }}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h4"
                component="div"
                // sx={{ lineHeight: 2.535, letterSpacing: "0.2rem" }}
                align="center"
              >
                Freight Fluidity
              </Typography>
            </Grid>
            <Grid item xs={7} sm={7} md={7} sx={{ lineHeight: 5.535 }}>
              <ButtonGroup aria-label="Basic example">
                <Button variant="contained">
                  <Link className="menu-nav-link" to="/">
                    Home
                  </Link>
                </Button>
                <DropdownButton
                  variant="contained"
                  title="Case Studies"
                  className="nav-dropdown"
                >
                  {locations.map((item) => (
                    <Dropdown.Item key={item.name} eventKey={item.name}>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/case-studies?location=${item.lookup}`}
                      >
                        {item.label}
                      </Link>
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <Button variant="contained">
                  <Link className="menu-nav-link" to="/commodity-corridors">
                    Commodity Corridors
                  </Link>
                </Button>
                <Button variant="contained">
                  <Link className="menu-nav-link" to="/supply-chain-fluidity">
                    Supply Chain Fluidity
                  </Link>
                </Button>
                <Button variant="contained">
                  <Link className="menu-nav-link" to="/border-supply-chain">
                    Border Supply Chain Sensitives
                  </Link>
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar> */}
      <AppBar className="sticky" id="menuappbar">
        <Toolbar className="mainToolbar">
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >

            <Grid item xs={4}>
              <Typography
                variant="h4"
                component="div"
                align="left"
              >
                <Link style={{ textDecoration: "none", color: "white" }} to="/">Freight Fluidity</Link>
              </Typography>
            </Grid>
            <Grid item xs={5.5}>

            </Grid>


            <Grid item xs={1}>
              <Box
                component="img"
                align="right"
                sx={{
                  height: "8vh",

                  // text-align-last: right;
                }}
                alt="The house from the offer."
                src="/Transparent Background Color3lineLOGO_TxDOT_WHITE_300dpi.png"
              />
            </Grid>
            <Grid item xs={1.5} display="flex">
            <a rel="noreferrer" href="https://mobility.tamu.edu/" target="_blank">
              <Box
                component="img"
                alt="The house from the offer."
                src="/TTI-Color-reverse.png"
                display="flex"
                height={"4vh"}
              // alignItems={"right"}
              /></a>
            </Grid>
          </Grid>

        </Toolbar>
        <Toolbar className="subToolbar">
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} >
              <ButtonGroup className='appNavButtonGroup' aria-label="App navigation button group" >
                <Button variant="contained">
                  <Link className="menu-nav-link" to="/">
                    Home
                  </Link>
                </Button>
                <DropdownButton
                  variant="contained"
                  title="Case Studies"
                  className="nav-dropdown"
                >
                  {locations.map((item) => (
                    <Dropdown.Item key={item.name} eventKey={item.name}>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/case-studies?location=${item.lookup}`}
                      >
                        {item.label}
                      </Link>
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <Button variant="contained">
                  <Link className="menu-nav-link" to="/commodity-corridors">
                    Commodity Corridors
                  </Link>
                </Button>
                <Button variant="contained">
                  <Link className="menu-nav-link" to="/supply-chain-fluidity">
                    Supply Chain Fluidity
                  </Link>
                </Button>
                <Button variant="contained">
                  <Link className="menu-nav-link" to="/border-supply-chain">
                    Border Supply Chain Sensitives
                  </Link>
                </Button>
                <Button variant="contained">
                  <Link className="menu-nav-link" to="/freight-resiliency">
                    Freight Resiliency
                  </Link>
                </Button>
                <>
                  <Button variant="contained" className="menu-nav-link" sx={{color:"white"}}  onClick={handleClickOpen}>
                   Contact us
                   </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Contact Us"}
                    </DialogTitle>
                    <Divider></Divider>
                    <DialogContent>
                      <DialogTitle id="alert-dialog-description">
                        Tyler Graham  <br></br>
                        Texas Department of Transportation (TxDOT)<br></br>
                        <a href="mailto:Tyler.Graham1@txdot.gov">Tyler.Graham1@txdot.gov</a>
                      </DialogTitle>
                      <DialogTitle id="alert-dialog-description">
                        L.D. White <br></br>
                        Texas A&M Transportation Institute (TTI)<br></br>
                        <a href="mailto:l-white@tti.tamu.edu">l-white@tti.tamu.edu</a>
                      </DialogTitle>
                      <DialogContentText id="alert-dialog-description">

                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button variant="contained" onClick={handleClose} >Close</Button>

                    </DialogActions>
                  </Dialog>
                </>
              </ButtonGroup>
            </Grid>

          </Grid>

        </Toolbar>
      </AppBar>
    </Box>
    <Box >

    </Box>
  </>
  );
}
