import React from "react";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);

export default function PieChart(props) {
  const {
    arrivals,
    departures,
    chartRef,
} = props;
//console.log('seriesdata',    arrivals,departures);
const arrival= arrivals/(arrivals+departures)*100;

const departure= departures/(arrivals+departures)*100;

  const options = {
    chart: {
      plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height:'260px',
    },

    title: {
      text: 'Average Countrywide Airport delay to Alliance FortWorth',
      align: 'left'
  },
  tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  },
  accessibility: {
      point: {
          valueSuffix: '%'
      }
  },
  plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              distance:15,
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
      }
  },
  series: [{
      name: 'Average Delays',
      colorByPoint: true,
      data: [{
          name: 'Arrivals',
          y: arrival,
          sliced: true,
          selected: true
      }, {
          name: 'Departures',
          y: departure,
      },
    ]
  }]

  
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
}
