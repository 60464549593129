import React, { useState, useEffect } from "react";

import {
  Grid,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";


import DonutMultiLayerChart from "../Highcharts/DonutMultiLayerChart";
import SideBarChart from "../Highcharts/SideBarChart";

import ScrollArrow from "../ViewPage/ScrollTop";


import InputLabel from "@mui/material/InputLabel";

import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { HashLink } from "react-router-hash-link";

import { supplyChainFluidityOptions } from "../../common/constants";

function ScfCaseStudy() {

  const [selectedCaseStudy, setSelectedCaseStudy] = useState(supplyChainFluidityOptions[0]);
  const [selectedRoute, setSelectedRoute] = useState(
    selectedCaseStudy.options[0].option
  );
  let routeOptions = selectedCaseStudy.options;

  const [tableRowClicked, setTableRowClicked] = useState(null);

  useEffect(() => {
    setTableRowClicked(routeOptions.filter((itm) => itm.option === selectedRoute)[0]
      .webMap)
    let routeData = selectedCaseStudy.options.filter(
      (itm) => itm.option === selectedRoute
    );

    if (routeData[0].summaryData) setSummaryData(routeData[0].summaryData);

    if (routeData[0].scfiContributionsPercent)
      setScfiContributions([
        {
          name: "Node",
          y: routeData[0].scfiContributionsPercent[0].node,
          color: "#DD571C",
          drilldown: {
            name: "Node",
            categories: ["Node"],
            data: [routeData[0].scfiContributionsPercent[0].node],
            color: ["#DD571C"],
            opacity: [0.05],
          },
        },
        {
          name: "Transportation",
          y: roundToDecimals(
            routeData[0].scfiContributionsPercent[0].transportaion.maritime +
            routeData[0].scfiContributionsPercent[0].transportaion.rail +
            routeData[0].scfiContributionsPercent[0].transportaion.truck,
            1
          ),
          color: "#276221",
          drilldown: {
            name: "Transportation",
            categories: ["Maritime", "Rail", "Truck"],
            data: [
              routeData[0].scfiContributionsPercent[0].transportaion.maritime,
              routeData[0].scfiContributionsPercent[0].transportaion.rail,
              routeData[0].scfiContributionsPercent[0].transportaion.truck,
            ],
            color: ["blue", "green", "yellow"],
            opacity: [1, 1, 1],
          },
        },
      ]);
  }, [selectedRoute]);

  const handleRouteChange = (event) => {
    setSelectedRoute(event.target.value);
  };

  const columns = [
    {
      field: "id",
      headerName: "Leg",
      type: "number",
      flex: 0.1,
      //   width: 90,
      headerClassName: "scfi-table-header",
    },
    {
      field: "activity",
      headerName: "Supply Chain Activity or Trip",
      //   width: 400,
      flex: 2.4,
      headerClassName: "scfi-table-header",
    },
    {
      field: "travelDwellTimeHrs",
      headerName: "Travel/Dwell Time (Hrs.)",
      type: "number",
      flex: 1.1,
      //   width: 90,
      headerClassName: "scfi-table-header",
    },
    {
      field: "freeFlowHrs",
      headerName: "Free-flow (Hrs.)",
      type: "number",
      flex: 0.75,
      //   width: 90,
      headerClassName: "scfi-table-header",
    },
    {
      field: "scfi",
      headerName: "SCFI",
      type: "number",
      flex: 0.5,
      //   width: 90,
      headerClassName: "scfi-table-header",
    },
  ];
  const [summaryData, setSummaryData] = useState(
    selectedCaseStudy.options[0].summaryData
  );
  const handleRowClick = (event) => {
    let coordinates;
    var row = event.currentTarget.innerText;

    summaryData.filter((option) => {
      if (row.includes(option.activity)) {
        coordinates = option.coordinates;
      }
    })
    routeOptions.filter((itm) => {
      if (itm.option === selectedRoute) {
        setTableRowClicked(itm.webMap.replace(/(center=).*?(&)/, '$1' + coordinates + '$2'))
      }
    })
  }

  function roundToDecimals(value, numDecimals) {
    let powerOften = Math.pow(10, numDecimals);
    return Math.round(value * powerOften) / powerOften;
  }
  const [scfiContributions, setScfiContributions] = useState([
    {
      name: "Node",
      y: selectedCaseStudy.options[0].scfiContributionsPercent[0].node,
      //   color: colors[2],
      color: "#DD571C",
      drilldown: {
        name: "Node",
        categories: ["Node"],
        data: [selectedCaseStudy.options[0].scfiContributionsPercent[0].node],
        color: ["#DD571C"],
        opacity: [0.05],
      },
    },
    {
      name: "Transportation",
      y: roundToDecimals(
        selectedCaseStudy.options[0].scfiContributionsPercent[0].transportaion
          .maritime +
        selectedCaseStudy.options[0].scfiContributionsPercent[0].transportaion
          .rail +
        selectedCaseStudy.options[0].scfiContributionsPercent[0].transportaion
          .truck,
        1
      ),
      color: "#276221",
      drilldown: {
        name: "Transportation",
        categories: ["Maritime", "Rail", "Truck"],
        data: [
          selectedCaseStudy.options[0].scfiContributionsPercent[0].transportaion
            .maritime,
          selectedCaseStudy.options[0].scfiContributionsPercent[0].transportaion
            .rail,
          selectedCaseStudy.options[0].scfiContributionsPercent[0].transportaion
            .truck,
        ],
        color: ["blue", "green", "yellow"],
        opacity: [1, 1, 1],
      },
    },
  ]);

  const [title, setTitle] = useState({
    index: 0,
    caseStudy: supplyChainFluidityOptions[0].caseStudy
  });
  const handleChange = (event) => {
    const selectedIndex = event.target.value;
    const selectedItem = supplyChainFluidityOptions[selectedIndex];
    setTitle({
      index: selectedIndex,
      caseStudy: selectedItem.caseStudy
    });
    console.log(event);
    setSelectedCaseStudy(supplyChainFluidityOptions[event.target.value])

    setSelectedRoute(
      supplyChainFluidityOptions[event.target.value].options[0].option
    );

  };



  return (
    <Box>

      <Grid container spacing={0} sx={{ pt: 2 }}>
        <Grid item xs={4}>
          <FormControl fullWidth
            sx={{ ml: 1, mr: 1, mb: 0.5, pl: 1, pr: 1, pb: 0.5 }}
          >
            <InputLabel id="route-option-select-input-label1" sx={{ pl: 1 }}>
              <Typography sx={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
              }}>
                Case Studies:
              </Typography>
            </InputLabel>
            <Select
              label="Case Studies"
              labelId="route-option-select-label1"
              id="route-option-select1"
              value={title.index}
              onChange={handleChange}
              sx={{
                fontWeight: 'bold',

              }}>


              {supplyChainFluidityOptions.map((itm, index) => (
                <MenuItem key={index} value={index}>
                  {itm.caseStudy}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth
            sx={{ ml: 1, mr: 1, mb: 0.5, pl: 1, pr: 1, pb: 0.5 }}
          >
            <InputLabel id="route-option-select-input-label" sx={{ pl: 1 }}>
              <Typography sx={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
              }}>
                {selectedCaseStudy.caseStudy} Route/Path Options:
              </Typography>
            </InputLabel>
            <Select
              label={selectedCaseStudy.caseStudy + " Route/Path Options:"}
              labelId="route-option-select-label"
              id="route-option-select"
              value={selectedRoute}
              onChange={handleRouteChange}
              sx={{
                fontWeight: 'bold',
              }}>

              {routeOptions.map((itm, index) => (
                <MenuItem value={itm.option}>{itm.option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} justifyContent={"flex-end"}>
          <HashLink to="#scfi_by_leg" smooth>
            Supply Chain Fluidity (SCFI) by Leg &nbsp;&nbsp;
          </HashLink>
          <HashLink to="#contributions_to_scfi" smooth>
            Contributions to SCFI &nbsp;&nbsp;
          </HashLink>
          <HashLink to="#scfi_transport_activities" smooth>
            SCFI for Transportation Activities &nbsp;
          </HashLink>

        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ p: 2 }}>
        {(selectedCaseStudy.caseStudy === "Motor Vehicle" || selectedCaseStudy.caseStudy === "Computers")
          &&
          <Grid item xs={12} sx={{ textAlign: "center" }}>

            <iframe
              src={tableRowClicked}
              title="Supply chain fluidity iframe"
              width="100%"
              height="500px"
              frameborder="0"
              // allowfullscreen
              allow="geolocation"
            ></iframe>


          </Grid>
        }
        {(selectedCaseStudy.caseStudy !== "Motor Vehicle" || selectedCaseStudy.caseStudy !== "Computers")
          && <>
            <span id="scfi_transport_activities" />
            <Grid item xs={6}>
              <Divider
                component="div"
                role="presentation"
                textAlign="center"
                sx={{ m: 1 }}
              >
                <Chip
                  label={
                    <Typography
                      variant="h6"
                      sx={{ margin: 1, padding: 2 }}
                    >
                      SCFI for Transportation Activities
                    </Typography>
                  }
                  sx={{ backgroundColor: "#a9a9a9" }}
                />
              </Divider>
              <SideBarChart
                titleText={
                  "SCFI of Transportation Activities - " +
                  selectedCaseStudy.options[0].option
                }
                chartData={summaryData.filter(
                  (itm) => itm.type === "transportation"
                )}
              />
            </Grid>
          </>
        }
        <div id="scfi_by_leg" />
        <Grid item xs={6} sx={{ textAlign: "center", pl: 1 }}>
          <Divider
            component="div"
            role="presentation"
            textAlign="center"
            sx={{ m: 1 }}
          >
            <Chip
              label={
                <Typography
                  variant="h6"
                  sx={{ margin: 2, padding: 2 }}
                >
                  Supply Chain Fluidity (SCFI) by Leg
                </Typography>
              }
              sx={{ backgroundColor: "#a9a9a9" }}
            />
          </Divider>
          <TableContainer component={Paper}>
            <Table stickyHeader sx={{ minWidth: 500, padding:"1rem" }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell align="right" className="scfi-table-cell" >{column.headerName}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody >
                {summaryData.map((row) => (
                  <TableRow hover className="tableRow" onClick={handleRowClick}
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}
                  >
                    <TableCell className="scfi-table-cell" component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell className="scfi-table-cell" sx={{ lineHeight: "2.43rem" }}>{row.activity}</TableCell>
                    <TableCell className="scfi-table-cell" align="center">{row.freeFlowHrs.toLocaleString()}</TableCell>
                    <TableCell className="scfi-table-cell" align="center">{row.travelDwellTimeHrs.toLocaleString()}</TableCell>
                    <TableCell className="scfi-table-cell" align="center">{row.scfi.toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container>

        <div id="contributions_to_scfi" />
        <Grid
          item
          xs={6}
          sx={{ textAlign: "center" }}
          id="contributions_to_scfi"
        >
          <Divider
            component="div"
            role="presentation"
            textAlign="center"
            sx={{ m: 1 }}
          >
            <Chip
              label={
                <Typography
                  variant="h6"
                  sx={{ margin: 1, padding: 1 }}
                >
                  Contributions to SCFI
                </Typography>
              }
              sx={{ backgroundColor: "#a9a9a9" }}
            />
          </Divider>
          <DonutMultiLayerChart
            chartHeight={400}
            chartData={scfiContributions}
            pieInnerSize={100}
            pieDepth={45}
            titleText={
              "Contribution of Nodes and Transportation to SCFI - " +
              selectedCaseStudy.options[0].option
            }
          />
        </Grid>

      </Grid>
      <span>
        <ScrollArrow />
      </span>
    </Box>
  );
}
export default ScfCaseStudy;
