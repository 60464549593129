import I35_Table from '../../data/table/i35_Table';
import HoustonTable from '../../data/table/HoustonTable';
import DFW_table from '../../data/table/DFW_table';
import ElPasoTable from '../../data/table/ElPasoTable';

const TableView = (props) => {

    const { statusGuide } = props;
    return (
        <div>
            {statusGuide === "DFW" && <I35_Table />}
            {statusGuide === "Houston" && <HoustonTable />}
            {statusGuide === "I-35" && <I35_Table />}
            {statusGuide === "El Paso" && <ElPasoTable />}

        </div>
    );
}

export default TableView;
