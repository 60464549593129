import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import series from "./traces.js";
require("highcharts/modules/annotations")(Highcharts);

const Traveltrace = (props) => {
  const {
    travelTimeTracesTitle,
    travelTimeCategories,
    travelTimeTraces,
  } = props;

  const chartOptions = {
    chart: {
      zoomType: "xy",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: travelTimeTraces.chartTitle,
    },
    xAxis: {
      title: {
        text: "Time of Day for Start of Trip",
      },
      categories: travelTimeCategories,
    },

    yAxis: {
      title: {
        text: "Travel time (minutes)",
      },
    },
    series: [
      {
        name: "Weekday Travel Time",
        data: travelTimeTraces.wd_meanttmin,
        marker: false,
      },
      {
        name: "Weekend Travel Time",
        data: travelTimeTraces.we_meanttmin,
        marker: false,
      },
      {
        name: "Weekday 95th Percentile TT",
        data: travelTimeTraces.wd_pcttt95min,
        marker: false,
      },
      {
        name: "Weekend 95th Percentile TT",
        data: travelTimeTraces.we_pcttt95min,
        marker: false,
      },
    ],
    // annotations: [
    //   {
    //     labels: [
    //       {
    //         point: "max",
    //         text: "Max",
    //       },
    //       {
    //         point: "min",
    //         text: "Min",
    //         backgroundColor: "white",
    //       },
    //     ],
    //   },
    // ],
  };
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={chartOptions}
      />
    </div>
  );
};

export default Traveltrace;
