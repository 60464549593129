import React, { useRef, useState, useEffect } from "react";
import "../../index.css";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

import { locations, commodities } from "../../common/constants";

import { downloadAzureStorageGJ } from "../../data-loaders/downloadAzureStorage";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN;

const Tx100View = (props) => {
  const { statusGuide } = props;
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [zoom, setZoom] = useState(9);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-97.2293, 32.9342],
      zoom: zoom,
      maxPitch: 65,
    });

    // Add zoom and rotation controls to the map.
    map.current.addControl(
      new mapboxgl.NavigationControl({ visualizePitch: true }),
      "top-left"
    );

    map.current.on("load", () => {
      // Add a data source containing GeoJSON data.
      // console.log("tx100Data-map-check", tx100Data);
      downloadAzureStorageGJ(
        "tx100",
        "tx100_repyear2020_trank_lte_100.geojson"
      ).then((response) => {
        map.current.addSource("tx100", {
          type: "geojson",
          data: response,
        });

        map.current.addLayer({
          id: "tx100-layer",
          type: "line",
          source: "tx100", // reference the data source
          layout: { visibility: "visible", "line-cap": "round" },
          paint: {
            "line-color": [
              "interpolate",
              ["linear"],
              ["get", "trank"],
              1,
              "red",
              50,
              "yellow",
              100,
              "green",
            ],
            "line-width": [
              "interpolate",
              ["linear"],
              ["get", "trank"],
              1,
              8,
              100,
              3,
            ],
          },
        });
      });
    });

    // Create a popup, but don't add it to the map yet.
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.current.on("mouseenter", "tx100-layer", (e) => {
      // Change the cursor style as a UI indicator.
      map.current.getCanvas().style.cursor = "pointer";

      // Copy coordinates array.
      // const coordinates = e.features[0].geometry.coordinates.slice();
      const coordinates = e.features[0].geometry.coordinates[0];
      // const description = e.features[0].properties.description;
      //   console.log("coordinates-check", e.features[0].geometry.coordinates[0]);

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup
        //   .setLngLat(coordinates)
        // .setLngLat(e.features[0].geometry.coordinates[0])
        .setLngLat([e.lngLat.lng, e.lngLat.lat])
        .setHTML(
          '<div className="mappop">' +
            '<p style="font-size:14px;margin-bottom: 0px;padding-bottom: 0px;"> <strong>Road: </strong>' +
            e.features[0].properties.road +
            "</p>" +
            '<p style="font-size:14px;margin-bottom: 0px;padding-bottom: 0px;"> From ' +
            e.features[0].properties.from_road +
            " to " +
            e.features[0].properties.to_road +
            "</p>" +
            '<p style="font-size:14px;margin-bottom: 0px;padding-bottom: 0px;"> <strong>Truck Ranking: </strong>' +
            e.features[0].properties.trank +
            "</p>" +
            '<p style="font-size:14px;margin-bottom: 0px;padding-bottom: 0px;"> <strong>Overall Ranking: </strong>' +
            e.features[0].properties.arank +
            "</p>" +
            "</div>"
        )
        .addTo(map.current);
    });

    map.current.on("mouseleave", "tx100-layer", () => {
      map.current.getCanvas().style.cursor = "";
      popup.remove();
    });
  });

  useEffect(() => {
    map.current.setCenter(
      locations.filter((obj) => obj.name === statusGuide)[0].location
    );
    map.current.setZoom(zoom);
    map.current.setPitch(0);
    map.current.setBearing(0);
  }, [statusGuide]);

  return (
    <div>
      <div ref={mapContainer} id="tx100map" className="map-container">
        <div className="legend">
          <h5>Truck Bottleneck Rankings</h5>
          <div>
            <span className="rank1"></span>Ranking 1
          </div>
          <div>
            <span className="rank50"></span>Ranking 50
          </div>
          <div>
            <span className="rank100"></span>Ranking 100
          </div>
        </div>
      </div>
      <h6>Texas 100 Problematic segments</h6>
    </div>
  );
};
export default Tx100View;
