  const i35_docs={
    "description" : "I35",
    "steps": 
    [
      {
        "heading": "A Contour Map from Alliance Airport on I-35",
        "title": "Step 1.Review Contour Maps.",
        "description": " Contour Maps show regional travel times for a selected time of the day to or from a freight generator, such as an airport (see Figure 1). The most congested corridors have a shorter distance from the generator to the contour. For the weekday 5 p.m. contour map, you notice the distance traveled from Alliance south on I-35W is shorter than on other major corridors leaving the airport. You decide to take a closer look."
      },
      {
        "heading": "Heat Map of Truck Patterns at Alliance Airport on I-35",
        "title": "Step 2. Analyze Heat Maps:",
        "description": "Heat Maps use GPS data to show vehicle paths (trips) crossing a selected segment during a certain time period, revealing problematic areas. Conducting a Heat Map Analysis (see Figure 2), you see many trucks in the analysis section along the I-35W corridor from Alliance to just south of I-20. (Note: The analysis considered truck traffic southbound through the selected link, outlined in red, during the evening peak hour.) The analysis shows increased truck traffic in the Alliance area and how truck-traffic flows impact the study corridor. You also note the large numbers of trucks coming from I-35W and US-287, many of which continue southward on I-35W and US-287 south of town. A smaller number moves on to I-20 and I-30 heading east."
      },
      {
        "title": "Step 3. Check Travel-Time Traces: ",
        "description": "Travel-time traces show the average travel time and the 95th percentile travel time for a length of roadway beginning at various times of day. The 95th percentile time reflects the variability in the travel times through the corridor due to construction, crashes, or other incidents. You immediately note a substantial gap between the weekday 95th percentile travel time and the average weekday travel time for the Travel-Time Trace from Denton to Hillsboro (see Figure 3). This indicates congestion is causing the delay and not a constant bottleneck caused by other factors (e.g., construction). "
      },
      {
        "title": "Step 4. Review the Texas 100 Most Congested Roadways List and Texas 50 Most Congested Freight Bottlenecks",
        "description": "Next, cross-checking the Texas 100 Most Congested Roadways List and Texas 50 Most Congested Freight Bottlenecks shows three segments on I-35W from SH 170 to I-30 are on both (see Table 1). "
      },
      {
        "heading":"Problematic Segments in the I35 Area"
        
      }
    ]
  }

  export default i35_docs;