import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { useLocation } from "react-router-dom";

import {
  commodities,
  railDwellTimes,
  airportDelay,
  countrywideAirportDelay
} from "../../common/constants";
import { downloadAzureStorageGJ } from "../../data-loaders/downloadAzureStorage";
import MapSettings from "./MapSettings";

import Map, {
  Source,
  Layer,
  NavigationControl,
  Marker,
  Popup,
} from "react-map-gl";

import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Tooltip,
  CircularProgress,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";


import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import axios from "axios";

import LayerControls from "./LayerControls";

import cargoAirportsIconSvg from "../../assets/icons/flight_yellow_24dp.svg";
import portsIconSvg from "../../assets/icons/anchor_white_24dp.svg";
import truckParkingIconSvg from "../../assets/icons/local_parking_white_on_green_24dp.svg";
import trainIconSvg from "../../assets/icons/train_FILL0_wght400_GRAD0_opsz48.svg";
import airportIconSvg from "../../assets/icons/flight_FILL0_wght400_GRAD0_opsz48.svg";
import countryIcon from "../../assets/icons/countrywideFlight.svg";

import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

import GroupedBarChart from "../Highcharts/GroupedBarChart";
import PieChart from "../Highcharts/PieChart";

const CommodityCorridors = () => {
  const mapRef = useRef(null);
  const [mapFeatures, setMapFeatures] = useState(null);
  const [geojsonData, setGeoJsonData] = useState(null);
  const [layerStyle, setLayerStyle] = useState({
    id: "comm-corridor-layer",
    type: "fill-extrusion",
  });
  const statusGuide = "/commodity-corridors";

  const [mapParameters, setMapParameters] = useState({
    roadwayPerformance: "trkdelay",
    commodities: "Route_tons",
    selectedCommoditiesCategory: "Route_tons",
    colorScheme: ["#2b83ba", "#ffffbf", "#d7191c"],
    barHeight: "commodities",
    barColor: "roadwayPerformance",
    barOpacity: 0.85,
    showRoads: "visible",
    showCargoAirports: "none",
    showPorts: "none",
    showTruckParking: "none",
    showRailDwellTimes: "none",
    showAirportDelay: "none",
    showCountrywideAirportDelay: "none",
    showDelayLines: "none",
    showAisData2021: "none",
    baseMap: "mapbox/streets-v11",
  });

  const [mapViewParameters, setMapViewParameters] = useState({
    longitude: -99.9018,
    latitude: 30.5,
    zoom: 5.5,
    pitch: 50,
  });

  const mapViewParameters1 = {
    longitude: -95.7129,
    latitude: 37.0702,
    zoom: 3.9,
    pitch: 10,
  };


  function setCommodityDetais() {
    let commodityDetailsTemp = [];
    commodities.map((itm) => {
      commodityDetailsTemp.push({
        ...itm,
        minCommodity: {
          tons: 100000000,
          values: 100000000,
        },
        maxCommodity: { tons: 0, values: 0 },
        minRoadPerf: {
          trkdelay: 100000000,
          trkpti95: 100000000,
        },
        maxRoadPerf: {
          trkdelay: 0,
          trkpti95: 0,
        },
      });
    });
    return commodityDetailsTemp;
  }

  const [commodityDetails, setCommodityDetails] = useState(
    setCommodityDetais()
  );
  const [commodityGroup, setCommodityGroup] = useState("All Commodities");
  const handleCommodityGroupChange = (event) => {
    // console.log("event-check", event);
    let commodityGroup = event.target.value;
    setCommodityGroup(event.target.value);

    let selectedCommoditiesCategory = mapParameters.selectedCommoditiesCategory;
    let tonsValueField = "Route_tons";
    if (selectedCommoditiesCategory === "Route_tons") {
      tonsValueField = commodities.filter(
        (obj) => obj.name === commodityGroup
      )[0].variables.tons;
    }
    if (selectedCommoditiesCategory === "Route_valu") {
      tonsValueField = commodities.filter(
        (obj) => obj.name === commodityGroup
      )[0].variables.values;
    }
    // setMapParameters({
    //   roadwayPerformance: mapParameters.roadwayPerformance,
    //   commodities: tonsValueField,
    //   selectedCommoditiesCategory: selectedCommoditiesCategory,
    //   colorScheme: mapParameters.colorScheme,
    //   barHeight: mapParameters.barHeight,
    //   barColor: mapParameters.barColor,
    //   barOpacity: mapParameters.barOpacity,
    //   showRoads: mapParameters.showRoads,
    // });

    setMapParameters({
      ...mapParameters,
      commodities: tonsValueField,
      selectedCommoditiesCategory: selectedCommoditiesCategory,
    });
  };

  // console.log("mapParameters-check", mapParameters);
  const handleCommodityChange = (event) => {
    let tonsValueField = "Route_tons";
    let selectedCommoditiesCategory = "Route_tons";
    if (event.target.value === "Route_tons") {
      tonsValueField = commodities.filter(
        (obj) => obj.name === commodityGroup
      )[0].variables.tons;
      selectedCommoditiesCategory = "Route_tons";
    }
    if (event.target.value === "Route_valu") {
      tonsValueField = commodities.filter(
        (obj) => obj.name === commodityGroup
      )[0].variables.values;
      selectedCommoditiesCategory = "Route_valu";
    }

    // setMapParameters({
    //   roadwayPerformance: mapParameters.roadwayPerformance,
    //   commodities: tonsValueField,
    //   selectedCommoditiesCategory: selectedCommoditiesCategory,
    //   colorScheme: mapParameters.colorScheme,
    //   barHeight: mapParameters.barHeight,
    //   barColor: mapParameters.barColor,
    //   barOpacity: mapParameters.barOpacity,
    //   showRoads: mapParameters.showRoads,
    // });

    setMapParameters({
      ...mapParameters,
      commodities: tonsValueField,
      selectedCommoditiesCategory: selectedCommoditiesCategory,
    });

  };

  useEffect(() => {
    const fetchCommodityDataAgo = async (dataUrl, index) => {
      const urlPromise = await axios.get(dataUrl);
      return urlPromise;
    };

    let districtIDs = [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
    ];
    let promises = [];
    let promiseResult = [];

    promises = districtIDs.map((r, index) => {
      const dataUrl =
        `https://services6.arcgis.com/1UWmeVkkResmC9lR/ArcGIS/rest/services/Texs_Network_Performance_2015_Commodities_Buffered/FeatureServer/0/query?where=Di=` +
        r +
        "&Route_tons+>+0" +
        "&Route_tons+%3E+0&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&relationParam=&returnGeodetic=false&" +
        "outFields=RIA_RTE_ID,FRM_DFO,TO_DFO,trkdelay,trkpti95,PoultryTon, CotGraiTon,ProduceTon,EnergyTon,ElectroTon,AutoTon,PoultValue,CotGraValu,ProduValue,EnergValue,ElectValue,AutoValue,Route_tons,Route_valu" +
        "&returnGeometry=true&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=";
      return fetchCommodityDataAgo(dataUrl, index);
    });

    promiseResult = Promise.allSettled(promises);

    let fTemp = [];

    promiseResult.then((responses) => {
      responses.forEach((response, index) => {
        let fc = response.value.data;
        // console.log("fc-check", fc.features);
        fTemp = fTemp.concat(fc.features);
        // console.log("fTemp-check", fTemp);
      });
      // .catch((err) => console.error(err));

      let tempData = fTemp;

      setGeoJsonData({
        type: "FeatureCollection",
        features: tempData,
      });
      setMapFeatures({
        type: "FeatureCollection",
        features: tempData,
      });

      let maxValuePerf = 0;
      let minValuePerf = 100;
      let maxValueComm = 0;
      let minValueComm = 1000000;

      let commodityDetailsTemp = commodityDetails;
      let maxValuePerftrkdelay = 0;
      let minValuePerftrkdelay = 100;
      let maxValuePerftrkpti95 = 0;
      let minValuePerftrkpti95 = 100;

      tempData.map((obj) => {
        minValuePerftrkdelay = Math.min(
          minValuePerftrkdelay,
          obj.properties.trkdelay
        );
        maxValuePerftrkdelay = Math.max(
          maxValuePerftrkdelay,
          obj.properties.trkdelay
        );
        minValuePerftrkpti95 = Math.min(
          minValuePerftrkpti95,
          obj.properties.trkpti95
        );
        maxValuePerftrkpti95 = Math.max(
          maxValuePerftrkpti95,
          obj.properties.trkpti95
        );
        commodityDetailsTemp.map((item, index) => {
          let tonsField = commodityDetailsTemp[index].variables.tons;
          commodityDetailsTemp[index].minCommodity.tons = Math.min(
            commodityDetailsTemp[index].minCommodity.tons,
            obj.properties[tonsField]
          );
          commodityDetailsTemp[index].maxCommodity.tons = Math.max(
            commodityDetailsTemp[index].maxCommodity.tons,
            obj.properties[tonsField]
          );

          let valuesField = commodityDetailsTemp[index].variables.values;
          commodityDetailsTemp[index].minCommodity.values = Math.min(
            commodityDetailsTemp[index].minCommodity.values,
            obj.properties[valuesField]
          );
          commodityDetailsTemp[index].maxCommodity.values = Math.max(
            commodityDetailsTemp[index].maxCommodity.values,
            obj.properties[valuesField]
          );
        });
      });

      commodityDetailsTemp.map((item, index) => {
        commodityDetailsTemp[index].minRoadPerf.trkdelay = minValuePerftrkdelay;
        commodityDetailsTemp[index].maxRoadPerf.trkdelay = maxValuePerftrkdelay;
        commodityDetailsTemp[index].minRoadPerf.trkpti95 = minValuePerftrkpti95;
        commodityDetailsTemp[index].maxRoadPerf.trkpti95 = maxValuePerftrkpti95;
      });

      setCommodityDetails(commodityDetailsTemp);
      let commodityInfoTemp = commodityDetailsTemp.filter(
        (obj) => obj.name === commodityGroup
      )[0];

      minValuePerf =
        commodityInfoTemp.minRoadPerf[mapParameters.roadwayPerformance];
      maxValuePerf =
        commodityInfoTemp.maxRoadPerf[mapParameters.roadwayPerformance];
      if (commodityInfoTemp.variables.tons == [mapParameters.commodities]) {
        minValueComm = commodityInfoTemp.minCommodity.tons;
        maxValueComm = commodityInfoTemp.maxCommodity.tons;
      }
      if (commodityInfoTemp.variables.values == [mapParameters.commodities]) {
        minValueComm = commodityInfoTemp.minCommodity.values;
        maxValueComm = commodityInfoTemp.maxCommodity.values;
      }

      setLayerStyle({
        id: "comm-corridor-layer",
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": [
            "interpolate",
            ["linear"],

            ["get", mapParameters.roadwayPerformance],
            minValuePerf,
            ["to-color", mapParameters.colorScheme[0]],
            (minValuePerf + maxValuePerf) * 0.5,
            ["to-color", mapParameters.colorScheme[1]],
            maxValuePerf,
            ["to-color", mapParameters.colorScheme[2]],
          ],
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["get", mapParameters.commodities],
            minValueComm,
            1,
            maxValueComm,
            50000,
          ],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.85,
        },
      });
    });
  }, [statusGuide]);

  useEffect(() => {
    if (geojsonData) {
      let tempData = geojsonData.features.filter(
        (obj) => obj.properties[mapParameters.commodities] > 0
      );

      setMapFeatures({
        type: "FeatureCollection",
        features: tempData,
      });

      let maxValuePerf = 0;
      let minValuePerf = 100;
      let maxValueComm = 0;
      let minValueComm = 1000000;

      let commodityInfoTemp = commodityDetails.filter(
        (obj) => obj.name === commodityGroup
      )[0];

      minValuePerf =
        commodityInfoTemp.minRoadPerf[mapParameters.roadwayPerformance];
      maxValuePerf =
        commodityInfoTemp.maxRoadPerf[mapParameters.roadwayPerformance];
      if (commodityInfoTemp.variables.tons == [mapParameters.commodities]) {
        minValueComm = commodityInfoTemp.minCommodity.tons;
        maxValueComm = commodityInfoTemp.maxCommodity.tons;
      }
      if (commodityInfoTemp.variables.values == [mapParameters.commodities]) {
        minValueComm = commodityInfoTemp.minCommodity.values;
        maxValueComm = commodityInfoTemp.maxCommodity.values;
      }

      let colorParameter = mapParameters.roadwayPerformance;
      let colorMin = minValuePerf;
      let colorMax = maxValuePerf;
      if (mapParameters.barColor === "commodities") {
        colorParameter = mapParameters.commodities;
        colorMin = minValueComm;
        colorMax = maxValueComm;
      }
      let heightParameter = mapParameters.roadwayPerformance;
      let heightMin = minValuePerf;
      let heightMax = maxValuePerf;
      if (mapParameters.barHeight === "commodities") {
        heightParameter = mapParameters.commodities;
        heightMin = minValueComm;
        heightMax = maxValueComm;
      }

      setLayerStyle({
        id: "comm-corridor-layer",
        type: "fill-extrusion",
        layout: {
          visibility: mapParameters.showRoads,
        },
        paint: {
          "fill-extrusion-color": [
            "interpolate",
            ["linear"],
            ["get", colorParameter],
            colorMin,
            ["to-color", mapParameters.colorScheme[0]],
            (colorMin + colorMax) * 0.5,
            ["to-color", mapParameters.colorScheme[1]],
            colorMax,
            ["to-color", mapParameters.colorScheme[2]],
          ],
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["get", heightParameter],
            heightMin,
            1,
            heightMax,
            50000,
          ],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": mapParameters.barOpacity,
        },
      });
      //console.log("maxValuePerf-check", maxValuePerf);
    }
  }, [mapParameters]);

  const handleRoadwayPerformanceChange = (event) => {
    // console.log("event-check", event.target.value);
    // console.log("geojsonData-check", geojsonData);
    // setMapParameters({
    //   roadwayPerformance: event.target.value,
    //   commodities: mapParameters.commodities,
    //   selectedCommoditiesCategory: mapParameters.selectedCommoditiesCategory,
    //   colorScheme: mapParameters.colorScheme,
    //   barHeight: mapParameters.barHeight,
    //   barColor: mapParameters.barColor,
    //   barOpacity: mapParameters.barOpacity,
    //   showRoads: mapParameters.showRoads,
    // });

    setMapParameters({
      ...mapParameters,
      roadwayPerformance: event.target.value,
    });
  };

  const [hoverInfo, setHoverInfo] = useState(null);
  const [clickInfoRailDwellTimes, setClickInfoRailDwellTimes] = useState(null);
  const [clickInfoAirportDelay, setClickInfoAirportDelay] = useState(null);
  const [hoverInfoOtherLayers, setHoverInfoOtherLayers] = useState(null);
  const [clickInfoCountryAirportDelay, setClickInfoCountryAirportDelay] = useState(null);

  const onMove = () => {

    setHoverInfoOtherLayers(null);
    // setMapParameters({ 
    //   ...mapParameters,
    //    showDelayLines: "none" });


  };
  const onHover = useCallback((event) => {
    // console.log("hover-event-check", event);

    const {
      type,
      features,
      point: { x, y },
    } = event;
    // console.log("hover-event-check", event, features);
    if (features[0].source === "my-data") {
      const hoveredFeature = features && features[0];

      // prettier-ignore
      setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
    }
  }, []);
  const onHoverOtherLayers = useCallback((event) => {
    // console.log("hover-event-check", event);

    const {
      type,
      features,
      point: { x, y },
    } = event;
    // console.log("hover-event-check", event, features);
    const hoveredFeature = features && features[0];
    if (features[0].source === "railDwellTime") {
      return setHoverInfoOtherLayers({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        text:
          hoveredFeature &&
          "Rail Dwell Time Layer - " + hoveredFeature.properties.name,
      });
    }
    if (features[0].source === "airportDelay") {
      return setHoverInfoOtherLayers({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        text:
          hoveredFeature &&
          "Airport Delay Layer - " + hoveredFeature.properties.name,
      });
    }
    if (features[0].source === "countrywideAirportDelay") {
      // setMapParameters({ 
      //   ...mapParameters,
      //    showDelayLines: "visible" });
      return setHoverInfoOtherLayers({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        text:
          hoveredFeature &&
          "Country wide Airport Delay Layer - " + hoveredFeature.properties.name,
      });

    }
  }, []);
  const onClick = useCallback((event) => {
    //console.log("hover-event-check", event);
    setHoverInfoOtherLayers(null);
    const {
      type,
      features,
      point: { x, y },
      lngLat: { lng, lat },
    } = event;
    // console.log("click-event-check", event, features);
    // if (features[0].source === "my-data") {
    //   const hoveredFeature = features && features[0];

    //   // prettier-ignore
    //   setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
    // }
    if (features[0].source === "railDwellTime") {
      const hoveredFeature = features && features[0];
      setClickInfoRailDwellTimes(
        hoveredFeature && { feature: hoveredFeature, x, y, lng, lat }
      );
      onHoverOtherLayers(event);
    }
    if (features[0].source === "airportDelay") {
      const hoveredFeature = features && features[0];
      setClickInfoAirportDelay(
        hoveredFeature && { feature: hoveredFeature, x, y, lng, lat }
      );
    }
    if (features[0].source === "countrywideAirportDelay") {
      const hoveredFeature = features && features[0];
      setClickInfoCountryAirportDelay(
        hoveredFeature && { feature: hoveredFeature, x, y, lng, lat }
      );

    }
  }, []);
  // console.log("clickInfoCountrywide-check", clickInfoCountryAirportDelay);

  const [baseMap, setBaseMap] = useState("mapbox/streets-v11");

  const [cargoAirportsData, setCargoAirportsData] = useState(null);
  const [cargoAirportsLayerStyle, setCargoAirportsLayerStyle] = useState({
    id: "cargo-airports-layer",
    // type: "circle",
    type: "symbol",
    layout: {
      visibility: mapParameters.showCargoAirports,
      // visibility: true,
      "icon-image": "pin",
      "icon-size": 1,
    },
  });

  useEffect(() => {
    if (mapParameters.showCargoAirports && cargoAirportsData == null) {
      const fetchCargoAirports = async () => {
        // console.log("fetchEventsH3Summary-api-call");

        try {
          const dataUrl =
            "https://geo.dot.gov/mapping/rest/services/NTAD/Aviation_Facilities/MapServer/0/query?where=OTHER_SERVICES+like+%27%25CARGO%25%27+and+STATE_CODE+%3D+%27TX%27&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson";
          // console.log("dataUrl-check", dataUrl);
          const response = await axios.get(dataUrl);
          if (response.status === 200) {
            // response - object, eg { status: 200, message: 'OK' }
            // console.log("success stuff", response.data);
            // console.log("load-data-check", response);
            setCargoAirportsData(response.data);
            return response;
          }
          return false;
        } catch (err) {
          console.error(err);
          return false;
        }
      };
      fetchCargoAirports();
      // const fetchCargoAirportsData = async () => {
      //   await fetchCargoAirports().then((res) => {
      //     console.log("res-check", res);
      //     setCargoAirportsData(res.data);
      //   });
      // };
      // fetchCargoAirportsData();
    }
    setCargoAirportsLayerStyle({
      id: "cargo-airports-layer",
      // type: "circle",
      type: "symbol",
      layout: {
        visibility: mapParameters.showCargoAirports,
        // visibility: true,
        "icon-image": "pin",
        "icon-size": 1,
      },
    });
  }, [mapParameters.showCargoAirports]);

  const [portsData, setPortsData] = useState(null);
  const [portsLayerStyle, setPortsLayerStyle] = useState({
    id: "ports-layer",
    // type: "circle",
    type: "symbol",
    layout: {
      visibility: mapParameters.showPorts,
      // visibility: true,
      "icon-image": "ports",
      "icon-size": 1,
    },
    paint: {
      "icon-color": "blue",
    },
  });

  useEffect(() => {
    if (mapParameters.showPorts && portsData == null) {
      const fetchPorts = async () => {
        // console.log("fetchEventsH3Summary-api-call");

        try {
          const dataUrl =
            "https://opendata.arcgis.com/datasets/595556c111e14519be70b955e10aa8ff_0.geojson";
          // console.log("dataUrl-check", dataUrl);
          const response = await axios.get(dataUrl);
          if (response.status === 200) {
            setPortsData(response.data);
            return response;
          }
          return false;
        } catch (err) {
          console.error(err);
          return false;
        }
      };
      fetchPorts();
    }
    setPortsLayerStyle({
      id: "ports-layer",
      // type: "circle",
      type: "symbol",
      layout: {
        visibility: mapParameters.showPorts,
        // visibility: true,
        "icon-image": "ports",
        "icon-size": 1,
      },
    });
  }, [mapParameters.showPorts]);

  const [truckParkingData, setTruckParkingData] = useState(null);
  const [truckParkingLayerStyle, setTruckParkingLayerStyle] = useState({
    id: "truckParking-layer",
    // type: "circle",
    type: "symbol",
    layout: {
      visibility: mapParameters.showTruckParking,
      // visibility: true,
      "icon-image": "truckParking",
      "icon-size": 1,
      "icon-allow-overlap": true,
    },
    paint: {
      "icon-color": "blue",
    },
  });

  useEffect(() => {
    if (mapParameters.showTruckParking && truckParkingData == null) {
      const fetchStatewideTruckParkingLocations = async () => {
        let counties =
          "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25," +
          "26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50," +
          "51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75," +
          "76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100," +
          "101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125," +
          "126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150," +
          "151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175," +
          "176,177,178,179,180,181,182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200," +
          "201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225," +
          "226,227,228,229,230,231,232,233,234,235,236,237,238,239,240,241,242,243,244,245,246,247,248,249,250," +
          "251,252,253,254";

        try {
          const dataUrl =
            // `${process.env.REACT_APP_API_TCAT}` +
            `https://tti-txcat-api-dev.azurewebsites.net/api` +
            `/poi/getTruckParkingLocations?counties=${counties}`;
          // console.log("dataUrl-check", dataUrl);
          const response = await axios.get(dataUrl);
          // console.log("response-check", response);
          if (response.status === 200) {
            setTruckParkingData(response.data);
            return response;
          }
          return false;
        } catch (err) {
          console.error(err);
          return false;
        }
      };
      fetchStatewideTruckParkingLocations();
    }
    setTruckParkingLayerStyle({
      id: "truckParking-layer",
      // type: "circle",
      type: "symbol",
      layout: {
        visibility: mapParameters.showTruckParking,
        // visibility: true,
        "icon-image": "truckParking",
        "icon-size": 1,
        "icon-allow-overlap": true,
      },
    });
  }, [mapParameters.showTruckParking]);

  /// rail dwell times
  const [railDwellTimesData, setRailDwellTimesData] = useState(null);
  const [railDwellTimesStyle, setRailDwellTimesStyle] = useState({
    id: "railDwellTimes-layer",
    type: "symbol",
    layout: {
      visibility: mapParameters.showRailDwellTimes,
      // visibility: "visible",
      "icon-image": "railDwellTImes",
      "icon-size": 1.5,
      "icon-allow-overlap": true,
    },
  });
  useEffect(() => {
    // console.log("test-check");
    if (mapParameters.showRailDwellTimes && railDwellTimesData == null) {
      let features = [];
      railDwellTimes.map((obj) => {
        features.push({
          type: "Feature",
          properties: {
            id: obj.id,
            name: obj.name,
            averageTerminalDwellTimeHours: obj.averageTerminalDwellTimeHours,
            data: obj.data
          },
          geometry: {
            type: "Point",
            coordinates: obj.coordinates,
          },
        });
      });
      // console.log("features-check", features);
      setRailDwellTimesData({
        type: "FeatureCollection",
        features: features,
      });
    }
    setRailDwellTimesStyle({
      id: "railDwellTimes-layer",
      // type: "circle",
      // paint: {
      //   "circle-radius": 100,
      //   "circle-color": "#007cbf",
      // },
      type: "symbol",
      layout: {
        visibility: mapParameters.showRailDwellTimes,
        // visibility: "visible",
        "icon-image": "railDwellTImes",
        "icon-size": 1.5,
        "icon-allow-overlap": true,
      },
    });
  }, [mapParameters.showRailDwellTimes]);

  //airport delay
  const [airportDelayData, setAirportDelayData] = useState(null);
  const [airportDelayStyle, setAirportDelayStyle] = useState({
    id: "airportDelay-layer",
    type: "symbol",
    layout: {
      visibility: mapParameters.showAirportDelay,
      // visibility: "visible",
      "icon-image": "airportDelay",
      "icon-size": 1.5,
      "icon-allow-overlap": true,
    },
  });
  useEffect(() => {
    // console.log("test-check");
    if (mapParameters.showAirportDelay && airportDelayData == null) {
      let features = [];
      airportDelay.map((obj) => {
        features.push({
          type: "Feature",
          properties: {
            id: obj.id,
            name: obj.name,
            averageArrrivalsDelayMinutes: obj.averageArrrivalsDelayMinutes,
            averageDeparturesDelayMinutes: obj.averageDeparturesDelayMinutes,
          },
          geometry: {
            type: "Point",
            coordinates: obj.coordinates,
          },
        });
      });
      //console.log("features-check", features);
      setAirportDelayData({
        type: "FeatureCollection",
        features: features,
      });
    }
    setAirportDelayStyle({
      id: "airportDelay-layer",
      type: "symbol",
      layout: {
        visibility: mapParameters.showAirportDelay,
        // visibility: "visible",
        "icon-image": "airportDelay",
        "icon-size": 1.5,
        "icon-allow-overlap": true,
      },
    });
  }, [mapParameters.showAirportDelay]);

  const airportDelayChartData = [
    {
      name: "Norway",
      data: [148, 133, 124],
    },
    {
      name: "Germany",
      data: [102, 98, 65],
    },
  ];

  const airportDelayChartRef = React.useRef(null);
  function setAirportDelayXaxis(id) {
    const monthlyData = airportDelay.filter((itm) => itm.id === id)[0]
      .monthlyData;
    let xAxisCategoriesTemp = [];
    monthlyData.map((itm) => {
      xAxisCategoriesTemp.push(itm.month);
    });
    return xAxisCategoriesTemp;
  }
  function setAirportDelayChartData(id) {
    const monthlyData = airportDelay.filter((itm) => itm.id === id)[0]
      .monthlyData;

    let arrDataTemp = [];
    let destDataTemp = [];
    monthlyData.map((itm) => {
      arrDataTemp.push(itm.averageArrrivalsDelayMinutes);
      destDataTemp.push(itm.averageDeparturesDelayMinutes);
    });

    return [
      {
        name: "Arrivals",
        data: arrDataTemp,
      },
      {
        name: "Departures",
        data: destDataTemp,
      },
    ];
  }

  //////////////////Countrywide airport data
  useEffect(()=>{
    if (mapParameters.showCountrywideAirportDelay === "visible") {
      setMapParameters({
        ...mapParameters,
        showDelayLines: "visible"
      });
  
    }
    else {
      setMapParameters({
        ...mapParameters,
        showDelayLines: "none"
      });
  
    }
  },[mapParameters.showCountrywideAirportDelay])
  

  const [airportDelayLinesData, setAirportDelayLinesDataData] = useState(null);
  const [airportDelayLinesDataStyle, setAirportDelayLinesDataStyle] = useState({
    id: "polylineLayer",
    type: "line",
    //source: "adl-data",
    visibility: mapParameters.showDelayLines,
    layout: {

      // visibility: "visible",
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "rgba(3, 170, 238, 0.5)",
      //"line-width": 1
    }
  });

  useEffect(() => {
    // console.log("test-check");
    let data = [];
    let features = [];
    if (mapParameters.showDelayLines) {

      countrywideAirportDelay.map((obj1) => {

        features.push({
          type: "Feature",
          properties: {
            id: obj1.id,
            name: obj1.name,
            avgArrivalsDelay: obj1.arrivals.averageDelay,
            avgDeparturesDelay: obj1.departures.averageDelay,
          },
          geometry: {
            type: "LineString",
            coordinates: [airportDelay[0].coordinates,
            // [clickInfoCountryAirportDelay.lng, clickInfoCountryAirportDelay.lat],
            [obj1.long, obj1.lat]]
          },
        });
        // data.push(
        //   [clickInfoCountryAirportDelay.lng, clickInfoCountryAirportDelay.lat],
        //   [obj1.long, obj1.lat],)
      });

      setAirportDelayLinesDataStyle({
        id: "polylineLayer",
        type: "line",
        //source: "adl-data",
        visibility: mapParameters.showDelayLines,
        layout: {

          // visibility: "visible",
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color":
            [
              "interpolate", ["linear"], ["get", "avgArrivalsDelay"],
              20, "rgb(122, 193, 67)",
              80, "rgb(237, 28, 36)"
            ],
          "line-width":
            [
              "interpolate", ["linear"], ["get", "avgDeparturesDelay"],
              20, 5,
              30, 10,
              40, 15,
              50, 20,
              60, 25,
              70, 30,
              80, 35
            ],
          "line-opacity": 0.8,
        }
      });

    }

    setAirportDelayLinesDataData({
      type: "FeatureCollection",
      features: features,

    });
  }, [mapParameters.showDelayLines, clickInfoCountryAirportDelay]);

  const [countrywideAirportDelayData, setCountrywideAirportDelayData] = useState(null);
  const [countrywideAirportDelayStyle, setCountrywideAirportDelayStyle] = useState({
    id: "countrywideAirportDelay-layer",
    type: "symbol",
    layout: {
      visibility: mapParameters.showCountrywideAirportDelay,
      // visibility: "visible",
      "icon-image": "countrywideAirportDelay",
      "icon-size": 1.5,
      "icon-allow-overlap": false,
    },
  });

    useEffect(()=>{
      if (mapParameters.showCountrywideAirportDelay && countrywideAirportDelayData === null) {

        let features = [];
        countrywideAirportDelay.map((obj) => {
          features.push({
            type: "Feature",
            properties: {
              id: obj.id,
              name: obj.name,
              avgArrivalsDelay: obj.arrivals.averageDelay,
              avgDeparturesDelay: obj.departures.averageDelay,
            },
            geometry: {
              type: "Point",
              coordinates: [obj.long, obj.lat]
            },
          });
        });
        //console.log("features-check", features);
        setCountrywideAirportDelayData({
          type: "FeatureCollection",
          features: features,
        });
      }
      setCountrywideAirportDelayStyle({
        id: "countrywideAirportDelay-layer",
        type: "symbol",
        layout: {
          visibility: mapParameters.showCountrywideAirportDelay,
          // visibility: "visible",
          "icon-image": "countrywideAirportDelay",
          "icon-size": 1.5,
          "icon-allow-overlap": false,
        },
      });

      if (mapParameters.showCountrywideAirportDelay === "visible") {
        mapRef.current?.flyTo({
          center: [mapViewParameters1.longitude, mapViewParameters1.latitude],
          pitch: mapViewParameters1.pitch,
          zoom: mapViewParameters1.zoom,
          duration: 2000
        });
      }
      if (!(mapParameters.showCountrywideAirportDelay === "visible")) {
        mapRef.current?.flyTo({
          center: [mapViewParameters.longitude, mapViewParameters.latitude],
          pitch: mapViewParameters.pitch,
          zoom: mapViewParameters.zoom,
          duration: 2000
        });
      }
    },[mapParameters.showCountrywideAirportDelay])
    

  const countryAirportChartRef = React.useRef(null);

  //load icon-images
  const pinImage = new Image(15, 15);
  const portsImage = new Image(15, 15);
  const truckParkingImage = new Image(15, 15);
  const trainIconImage = new Image(25, 25);
  const airportIconImage = new Image(25, 25);
  const countrywideIcon = new Image(25, 25);

  if (mapRef.current) {
    mapRef.current.on("styleimagemissing", (e) => {
      const id = e.id; // id of the missing image
      console.log("id" + id);

      if (mapParameters.showCargoAirports === "visible" && id === "pin") {
        pinImage.onload = () => {
          if (!mapRef.current.hasImage("pin")) {
            mapRef.current.loadImage(mapRef.current.addImage("pin", pinImage));
          }
        };
        pinImage.src = cargoAirportsIconSvg;
      }

      if (mapParameters.showPorts === "visible" && id === "ports") {
        portsImage.onload = () => {
          if (!mapRef.current.hasImage("ports")) {
            mapRef.current.loadImage(
              mapRef.current.addImage("ports", portsImage)
            );
          }
        };
        portsImage.src = portsIconSvg;
      }

      if (
        mapParameters.showTruckParking === "visible" &&
        id === "truckParking"
      ) {
        truckParkingImage.onload = () => {
          if (!mapRef.current.hasImage("truckParking")) {
            mapRef.current.loadImage(
              mapRef.current.addImage("truckParking", truckParkingImage)
            );
          }
        };
        truckParkingImage.src = truckParkingIconSvg;
      }

      if (
        mapParameters.showRailDwellTimes === "visible" &&
        id === "railDwellTImes"
      ) {
        trainIconImage.onload = () => {
          if (!mapRef.current.hasImage("railDwellTImes")) {
            mapRef.current.loadImage(
              mapRef.current.addImage("railDwellTImes", trainIconImage)
            );
          }
        };
        trainIconImage.src = trainIconSvg;
      }

      // airportIconSvg
      if (
        mapParameters.showAirportDelay === "visible" &&
        id === "airportDelay"
      ) {
        airportIconImage.onload = () => {
          if (!mapRef.current.hasImage("airportDelay")) {
            mapRef.current.loadImage(
              mapRef.current.addImage("airportDelay", airportIconImage)
            );
          }
        };
        airportIconImage.src = airportIconSvg;
      }

      //countrywideAirportDelayIcon
      if (
        mapParameters.showCountrywideAirportDelay === "visible" &&
        id === "countrywideAirportDelay"
      ) {
        countrywideIcon.onload = () => {
          if (!mapRef.current.hasImage("countrywideAirportDelay")) {
            mapRef.current.loadImage(
              mapRef.current.addImage("countrywideAirportDelay", countrywideIcon)
            );
          }
          mapRef.current.moveLayer("polylineLayer", "countrywideAirportDelay-layer");

        };
        countrywideIcon.src = countryIcon;
      }
    });
    if (mapParameters.showCountrywideAirportDelay === 'visible') {
      mapRef.current.hasImage("countrywideAirportDelay") &&
        mapRef.current.moveLayer("polylineLayer", "countrywideAirportDelay-layer");
    }
  }

  /////////////////////////
  const railDwellTimeChartRef = React.useRef(null);
  const railXAxisCategories = ['1/6/2023', '1/13/2023', '1/20/2023', '1/27/2023', '2/3/2023', '2/10/2023', '2/17/2023', '2/24/2023', '3/3/2023', '3/10/2023', '3/17/2023', '3/24/2023', '3/31/2023'];


  function setRailYChartData(id) {
    // console.log("railYaxis before", id);
    const weeklyData = railDwellTimes.filter((itm) => itm.id === id)[0]
      .data;
    const seriesName = railDwellTimes.filter((itm) => itm.id === id)[0]
      .name;


    return [
      {
        name: seriesName,
        data: weeklyData
      }
    ];
  }


  const [ais2021LayerStyle, setAis2021LayerStyle] = useState({
    id: "ais-2021-data",
    // source:
    //   "https://coast.noaa.gov/arcgis/rest/services/MarineCadastre/AISVesselTransitCounts2021/MapServer",
    // "source-layer": "AISVesselTransitCounts2021",
    type: "raster",
    // paint: {
    //   "fill-color": "#00ffff",
    // },
    visibility: mapParameters.showAisData2021,
  });

  return (
    <div>
      <Grid container spacing={0} className="commodity-corridors">
        <Grid item xs={8}>
          <Map
            ref={mapRef} // need a ref to reference it later if you don't have it already
            initialViewState={{
              ...mapViewParameters
            }}
            style={{
              // width: "56vw",
              // height: "93.5vh",
              width: "65vw",
              height: "87vh",
            }}
            // mapStyle="mapbox://styles/mapbox/streets-v11"
            mapStyle={"mapbox://styles/" + mapParameters.baseMap}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
            onMouseMove={onHover}
            onMouseEnter={onHoverOtherLayers}
            onMouseLeave={onMove}
            // onMouseLeave={() => setHoverInfoOtherLayers(null)}
            onClick={onClick}
            interactiveLayerIds={[
              "comm-corridor-layer",
              "cargo-airports-layer",
              "railDwellTimes-layer",
              "airportDelay-layer",
              "countrywideAirportDelay-layer",
            ]}
            attributionControl={false}
          >
            {hoverInfoOtherLayers && (
              <Popup
                longitude={hoverInfoOtherLayers.longitude}
                latitude={hoverInfoOtherLayers.latitude}
                // offset={[0, -10]}
                closeButton={false}
                className="county-info"
              >
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  {hoverInfoOtherLayers.text}
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  (Click for more information)
                </Typography>
              </Popup>
            )}
            {clickInfoRailDwellTimes && (
              <Popup
                longitude={clickInfoRailDwellTimes.lng}
                latitude={clickInfoRailDwellTimes.lat}
                // anchor="bottom"
                onClose={() => setClickInfoRailDwellTimes(null)}
                closeButton={false}
                style={{ minWidth: "300px", marginTop: 0, paddingTop: 0 }}
              >
                <Card
                  sx={{
                    m: 0,
                    p: 0,
                    border: "none",
                    boxShadow: "none",

                  }}
                >
                  <CardContent sx={{ m: "0px", p: "0px" }}>
                    <Box sx={{ textAlign: "center", borderBottom: 1 }}>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontSize: 14 }}
                      // color="text.secondary"
                      // gutterBottom
                      >
                        Rail Dwell Times Layer
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <strong>Name: </strong>
                        {clickInfoRailDwellTimes.feature.properties.name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <strong>Average Dwell Time:</strong>{" "}
                        {
                          clickInfoRailDwellTimes.feature.properties
                            .averageTerminalDwellTimeHours
                        }{" "}
                        hours
                      </Typography>
                    </Box>
                    <Box sx={{ lineHeight: 0.025, m: 1, fontSize: 10 }}>
                      <Typography variant="caption">
                        Average terminal dwell time excluding cars on
                        run-through trains. Measured in hours in terms of
                        railcars processed.
                      </Typography>
                    </Box>


                    <Box

                    >
                      <GroupedBarChart
                        chartHeight="250px"
                        titleText="Delay by Month"
                        series={setRailYChartData(
                          clickInfoRailDwellTimes.feature.properties.id
                        )}
                        chartRef={railDwellTimeChartRef}
                        xAxisCategories={railXAxisCategories}
                        yAxisSettings={{
                          allowDecimals: false,
                          min: 15,
                          tickInterval: 5,
                          //endOnTick adds extra space to the top for the chart to end on a tick
                          //default is true
                          // endOnTick: false,
                          title: {
                            text: "Terminal Dwell Time(hours)",
                          },
                        }}
                        tooltipXlabel="Terminal Dwell Time(hours)"
                        tooltipYlabel="Week Ending On:"
                        plotOptions={{
                          // column: {
                          //   stacking: "normal",
                          // },
                          series: {
                            type: 'line'
                            // pointWidth: 25,
                            // groupPadding: 1,
                            // pointPadding: 0,
                          },
                        }}
                        chartSpacing={[5, 5, 5, 5]}
                        chartType={"line"}
                      />
                    </Box>


                  </CardContent>
                  <CardActions sx={{ m: "0px", p: "0px" }}>
                    <Button
                      size="small"
                      onClick={() => setClickInfoRailDwellTimes(null)}
                    >
                      Close
                    </Button>
                  </CardActions>
                </Card>
              </Popup>
            )}
            {clickInfoAirportDelay && (
              <Popup
                longitude={clickInfoAirportDelay.lng}
                latitude={clickInfoAirportDelay.lat}
                // anchor="bottom"
                onClose={() => setClickInfoAirportDelay(null)}
                closeButton={false}
                style={{ minWidth: "300px", marginTop: 0, paddingTop: 0 }}
              >
                <Card
                  sx={{
                    m: 0,
                    p: 0,
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <CardContent sx={{ m: "0px", p: "0px" }}>
                    <Box sx={{ textAlign: "center", borderBottom: 1 }}>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontSize: 14 }}
                      // color="text.secondary"
                      // gutterBottom
                      >
                        Airport Delay Layer
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <strong>Name: </strong>
                        {clickInfoAirportDelay.feature.properties.name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <strong>Average Arrivals Delay:</strong>{" "}
                        {
                          clickInfoAirportDelay.feature.properties
                            .averageArrrivalsDelayMinutes
                        }{" "}
                        minutes
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <strong>Average Departures Delay:</strong>{" "}
                        {
                          clickInfoAirportDelay.feature.properties
                            .averageDeparturesDelayMinutes
                        }{" "}
                        minutes
                      </Typography>
                    </Box>
                    {/* <Box sx={{ lineHeight: 0.025, m: 1, fontSize: 10 }}>
                      <Typography variant="caption">
                        Average terminal dwell time excluding cars on
                        run-through trains. Measured in hours in terms of
                        railcars processed.
                      </Typography>
                    </Box> */}
                    <Box
                      sx={{
                        transition: (theme) => {
                          setTimeout(() => {
                            airportDelayChartRef.current &&
                              airportDelayChartRef.current.chart.reflow();
                          }, theme.transitions.duration.leavingScreen);

                          return theme.transitions.create("all", {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                          });
                        },
                        mt: 2,
                      }}
                    >
                      <GroupedBarChart
                        chartHeight="250px"
                        titleText="Delay by Month"
                        series={setAirportDelayChartData(
                          clickInfoAirportDelay.feature.properties.id
                        )}
                        chartRef={airportDelayChartRef}
                        // xAxisCategories={xAxisCategoriesAirportDelay}
                        xAxisCategories={setAirportDelayXaxis(
                          clickInfoAirportDelay.feature.properties.id
                        )}
                        yAxisSettings={{
                          allowDecimals: false,
                          min: 0,
                          tickInterval: 10,
                          //endOnTick adds extra space to the top for the chart to end on a tick
                          //default is true
                          // endOnTick: false,
                          title: {
                            text: "Delay (minutes)",
                          },
                        }}
                        tooltipXlabel="Delay"
                        tooltipYlabel="minutes"
                        plotOptions={{
                          // column: {
                          //   stacking: "normal",
                          // },
                          series: {
                            pointWidth: 25,
                            // groupPadding: 1,
                            // pointPadding: 0,
                          },
                        }}
                        chartSpacing={[5, 10, 5, 5]}
                        chartType={"column"}
                      />
                    </Box>
                  </CardContent>
                  <CardActions sx={{ m: "0px", p: "0px" }}>
                    <Button
                      size="small"
                      onClick={() => setClickInfoAirportDelay(null)}
                    >
                      Close
                    </Button>
                  </CardActions>
                </Card>
              </Popup>
            )}
            {clickInfoCountryAirportDelay && (
              <Popup
                longitude={clickInfoCountryAirportDelay.lng }
                latitude={clickInfoCountryAirportDelay.lat }
                onClose={() => setClickInfoCountryAirportDelay(null)}
                closeButton={false}
                style={{ minWidth: "300px", m: 0, p: 0 }}
              >
                <Card
                  sx={{
                    border: "none",
                    boxShadow: "none !important",
                  }}
                >
                  <CardContent sx={{m:0,p:0}}>
                    <Box sx={{ textAlign: "center", borderBottom: 1 }}>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontSize: 14 }}
                      // color="text.secondary"
                      // gutterBottom
                      >
                        Airport Delay Layer
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <strong>Name: </strong>
                        {clickInfoCountryAirportDelay.feature.properties.name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <strong>Average Arrivals Delay:</strong>{" "}
                        {
                          clickInfoCountryAirportDelay.feature.properties
                            .avgArrivalsDelay
                        }{" "}minutes {"(line width)"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <strong>Average Departures Delay:</strong>{" "}
                        {
                          clickInfoCountryAirportDelay.feature.properties
                            .avgDeparturesDelay
                        }{" "}minutes {"(line color)"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        transition: (theme) => {
                          setTimeout(() => {
                            countryAirportChartRef.current &&
                              countryAirportChartRef.current.chart.reflow();
                          }, theme.transitions.duration.leavingScreen);

                          return theme.transitions.create("all", {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                          });
                        },
                      }}
                    >
                      <PieChart
                        arrivals={clickInfoCountryAirportDelay.feature.properties
                          .avgArrivalsDelay}
                        departures={clickInfoCountryAirportDelay.feature.properties
                          .avgDeparturesDelay}
                        chartRef={countryAirportChartRef} />
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => setClickInfoCountryAirportDelay(null)}
                    >
                      Close
                    </Button>
                  </CardActions>
                </Card>
              </Popup>
            )}
            <Source id="my-data" type="geojson" data={mapFeatures}>
              <Layer {...layerStyle} />
            </Source>
            {mapParameters.showAisData2021 === "visible" && (
              <Source
                id="ais-2021-data"
                type="raster"
                tiles={[
                  "https://coast.noaa.gov/arcgis/rest/services/MarineCadastre/AISVesselTransitCounts2021/MapServer/tile/{z}/{y}/{x}",
                ]}
                tileSize={128}
              >
                <Layer {...ais2021LayerStyle} />
              </Source>
            )}
            {/* <Source
              id="ais-2021-data"
              type="raster"
              tiles={[
                "https://coast.noaa.gov/arcgis/rest/services/MarineCadastre/AISVesselTransitCounts2021/MapServer/export?dpi=10&transparent=true&format=png32&layers=show%3A0&bbox={bbox-epsg-3857}&bboxSR=EPSG:3857&imageSR=EPSG:3857&size=25,25&f=image",
              ]}
              tileSize={25}
            >
              <Layer {...ais2021LayerStyle} />
            </Source> */}

            <Source id="cargo-airports" type="geojson" data={cargoAirportsData}>
              <Layer {...cargoAirportsLayerStyle} />
            </Source>
            <Source id="ports" type="geojson" data={portsData}>
              <Layer {...portsLayerStyle} />
            </Source>
            <Source id="truckParking" type="geojson" data={truckParkingData}>
              <Layer {...truckParkingLayerStyle} />
            </Source>
            <Source id="railDwellTime" type="geojson" data={railDwellTimesData}>
              <Layer {...railDwellTimesStyle} />
            </Source>
            <Source id="airportDelay" type="geojson" data={airportDelayData}>
              <Layer {...airportDelayStyle} />
            </Source>
            <Source id="countrywideAirportDelay" type="geojson" data={countrywideAirportDelayData}>
              <Layer {...countrywideAirportDelayStyle} />
              {/* <Layer {...airportDelayLinesDataStyle}  /> */}
            </Source>
            {mapParameters.showDelayLines === "visible" && (
              <Source type="geojson" data={airportDelayLinesData}>
                <Layer {...airportDelayLinesDataStyle}

                />
              </Source>)}

            {!mapFeatures && (
              <Box
                sx={{
                  display: "flex",
                  mt: "25vh",
                  ml: "25vw",
                }}
              >
                <CircularProgress size={75} thickness={6} />
              </Box>
            )}
            {mapFeatures && 
            mapParameters.showCountrywideAirportDelay!=='visible'
                && (
              <div id="commodity-legend">
                <h6>Map settings</h6>
                <div>
                  <span>Bar color: {mapParameters.barColor}</span>
                  <br />
                  <span>Bar height: {mapParameters.barHeight}</span>
                  <div>
                    <span
                      className="bar-color"
                      style={{ backgroundColor: mapParameters.colorScheme[0] }}
                    ></span>
                    Low
                  </div>
                  <div>
                    <span
                      className="bar-color"
                      style={{ backgroundColor: mapParameters.colorScheme[1] }}
                    ></span>
                    Medium
                  </div>
                  <div>
                    <span
                      className="bar-color"
                      style={{ backgroundColor: mapParameters.colorScheme[2] }}
                    ></span>
                    High
                  </div>
                </div>
              </div>
            )}
            <NavigationControl className="tooltip" />
            <MapSettings
              mapParameters={mapParameters}
              setMapParameters={setMapParameters}
            />
            {mapFeatures && (
              <LayerControls
                // baseMap={baseMap}
                // setBaseMap={setBaseMap}
                mapParameters={mapParameters}
                setMapParameters={setMapParameters}
              />
            )}
          </Map>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <Grid item container direction="column">
              <Grid item xs={12}>
                <Typography
                  component="div"
                  sx={{
                    width: "100%",
                    mb: 0,
                    pb: 0,
                    mt: 1,
                    pt: 0,
                  }}
                >
                  The map to the left provides a visual representation of both
                  commodities and roadway performance. The commodity information
                  (either tons or dollar value) is shown by color, with red
                  being the maximum value, yellow being the median value, and
                  green being the lower values. The roadway performance (either
                  annual truck delay or reliability using the planning time
                  index, PTI 95) is shown using the height of the bars, with
                  taller bars indiciating higher values and shorter bars
                  indicating lower values.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 0, pt: 0, mt: 0 }}>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    mb: 0,
                    pb: 0,
                    mt: 0,
                    pt: 0,
                  }}
                >
                  <ListItem
                    key={"comm-corridors-1"}
                    sx={{
                      width: "100%",
                      mb: 0,
                      pb: 0,
                    }}
                  >
                    <ListItemText
                      sx={{
                        width: "100%",
                        mb: 0,
                        pb: 0,
                      }}
                      id={"comm-corridors-1-text"}
                      primary={
                        <Box>
                          <Typography component="div" variant="subtitle1">
                            <Box fontWeight="fontWeightBold" display="inline">
                              1. Choose the commodity group.
                            </Box>
                          </Typography>
                          <FormControl
                            variant="standard"
                            fullWidth
                            sx={{
                              mt: 1,
                              width: "100%",
                              maxWidth: 250,
                              mb: 0,
                              pb: 0,
                            }}
                          >
                            <Select
                              labelId="commodity-group-label"
                              id="commodity-group-label"
                              value={commodityGroup}
                              onChange={handleCommodityGroupChange}
                            >
                              {commodities.map((item) => (
                                <MenuItem key={item.name} value={item.name}>
                                  <Tooltip
                                    title={
                                      <h1
                                        style={{
                                          fontSize: 15,
                                        }}
                                      >
                                        {
                                          commodities.filter(
                                            (obj) => obj.name === item.name
                                          )[0].tooltip
                                        }
                                      </h1>
                                    }
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: "common.black",
                                          "& .MuiTooltip-arrow": {
                                            color: "common.black",
                                          },
                                          maxWidth: 300,
                                          marginLeft: "75px !important",
                                        },
                                      },
                                    }}
                                    placement="right-start"
                                  >
                                    <div>{item.name}</div>
                                  </Tooltip>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem
                    key={"comm-corridors-2"}
                    sx={{
                      width: "100%",
                      mb: 0,
                      pb: 0,
                    }}
                  >
                    <ListItemText
                      sx={{
                        width: "100%",
                        mb: 0,
                        pb: 0,
                      }}
                      id={"comm-corridors-2-text"}
                      primary={
                        <Box>
                          <Typography component="div" variant="subtitle1">
                            <Tooltip
                            disabled={mapParameters.barColor!=='commodities' && mapParameters.barHeight!=='commodities'}

                              title={
                                <h1
                                  style={{
                                    fontSize: 15,
                                  }}
                                >
                                  Commodity indicated on the map by either Tonnage/Value
                                </h1>
                              }
                              placement="right-start"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "common.black",
                                    "& .MuiTooltip-arrow": {
                                      color: "common.black",
                                    },
                                    maxWidth: 300,
                                  },
                                },
                              }}
                            >
                              <Box fontWeight="fontWeightBold" display="inline">
                                2. Choose the commodity variable.
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: 15,
                                    mb: 1,
                                  }}
                                />
                              </Box>
                            </Tooltip>
                          </Typography>
                          <FormControl
                            sx={{
                              width: "100%",
                              mb: 0,
                              pb: 0,
                            }}
                          >
                            <RadioGroup
                              row
                              aria-labelledby="commodities-radio-buttons-group-label"
                              name="commodities-radio-buttons-group"
                              defaultValue="Route_tons"
                              onChange={handleCommodityChange}
                            >
                              <FormControlLabel
                                value="Route_tons"
                                control={<Radio />}
                                label="Tonnage"
                              />
                              <FormControlLabel
                                value="Route_valu"
                                control={<Radio  />}
                                label="Value"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem
                    key={"comm-corridors-3"}
                    sx={{
                      width: "100%",
                      mb: 0,
                      pb: 0,
                    }}
                  >
                    <ListItemText
                      sx={{
                        width: "100%",
                        mb: 0,
                        pb: 0,
                      }}
                      id={"comm-corridors-3-text"}
                      primary={
                        <Box>
                          <Typography component="div" variant="subtitle1">
                            <Tooltip
                              title={
                                <h1
                                  style={{
                                    fontSize: 15,
                                    maxWidth: 225,
                                  }}
                                >
                                  Roadway performance indicated on the map by either Truck Delay/Reliability
                                </h1>
                              }
                              placement="right"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "common.black",
                                    "& .MuiTooltip-arrow": {
                                      color: "common.black",
                                    },
                                    maxWidth: 300,
                                  },
                                },
                              }}
                            >
                              <Box fontWeight="fontWeightBold" display="inline">
                                3. Choose the roadway performance variable.
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: 15,
                                    mb: 1,
                                  }}
                                />
                              </Box>
                            </Tooltip>
                          </Typography>
                          <FormControl
                            sx={{
                              width: "100%",
                              mb: 0,
                              pb: 0,
                            }}
                          >
                            <RadioGroup
                              row
                              aria-labelledby="roadway-performance-radio-buttons-group-label"
                              name="roadway-performance-radio-buttons-group"
                              defaultValue="trkdelay"
                              onChange={handleRoadwayPerformanceChange}
                            >
                              <FormControlLabel
                                value="trkdelay"
                                control={<Radio />}
                                label="Truck Delay"
                              />
                              <FormControlLabel
                                value="trkpti95"
                                control={<Radio />}
                                label="Reliability (PTI-95)"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sx={{ mt: 0 }}>
                <Typography component="div" variant="h6" sx={{ fontSize: 15 }}>
                  <Box fontWeight="fontWeightBold" display="inline">
                    Route Information
                  </Box>{" "}
                </Typography>
                {hoverInfo && (
                  <div>
                    <Typography component="div" sx={{ fontSize: 15 }}>
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Route ID:
                      </Box>{" "}
                      {hoverInfo.feature.properties.RIA_RTE_ID}
                    </Typography>
                    <Typography component="div" sx={{ fontSize: 15 }}>
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Commodity Tons (annual):
                      </Box>{" "}
                      {Math.round(
                        hoverInfo.feature.properties.Route_tons,
                        0
                      ).toLocaleString()}
                    </Typography>
                    <Typography component="div" sx={{ fontSize: 15 }}>
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Commodity Value (annual): $
                      </Box>{" "}
                      {Math.round(
                        hoverInfo.feature.properties.Route_valu,
                        0
                      ).toLocaleString()}
                    </Typography>
                    <Typography component="div" sx={{ fontSize: 15 }}>
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Truck Delay:
                      </Box>{" "}
                      {Math.round(
                        hoverInfo.feature.properties.trkdelay,
                        0
                      ).toLocaleString()}{" "}
                      annual person-hours of delay
                    </Typography>
                    <Typography component="div" sx={{ fontSize: 15 }}>
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Truck Planning Time Index (PTI95):
                      </Box>{" "}
                      {hoverInfo.feature.properties.trkpti95.toFixed(2)}
                    </Typography>
                    <Typography component="div" sx={{ fontSize: 15 }}>
                      {" "}
                      (Commodity annual tons and values are from 2015
                      Transearch)
                    </Typography>
                    <Typography component="div" sx={{ fontSize: 15 }}>
                      {" "}
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default CommodityCorridors;
