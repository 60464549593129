import Table from 'react-bootstrap/Table'

const HoustonTable = () => {

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th colSpan={3}>Texas 100 Most Congested Roadways List: Locations</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3}>#10: I-10 (IH 45 to US-59), 1.58 miles		</td>
                    </tr>
                    <tr>
                        <td>709K annual person-hours delay per mile</td>
                        <td>1.1M annual person-hours delay</td>
                        <td>$25.6M annual congestion cost ($16.2M/mile)    </td>

                    </tr>
                    <tr>
                        <td colSpan={3}>#63: I-10 (US 59 to I-610), 4.79 miles		</td>
                    </tr>
                    <tr>
                        <td>214K annual person-hours delay per mile</td>
                        <td >1.0M annual person-hours delay</td>
                        <td>$23.4M annual congestion cost ($4.9M/mile)</td>
                    </tr>

                </tbody>
                <thead>
                    <tr>
                        <th colSpan={3}>Texas 50 Most Congested Freight Bottlenecks: Locations</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3}>#4: I-10 (IH 45 to US-59), 1.58 miles		</td>
                    </tr>
                    <tr>
                        <td>55K annual person-hours delay per mile</td>
                        <td>87K annual person-hours delay</td>
                        <td>$5.1M annual congestion cost ($3.2M/mile)    </td>

                    </tr>
                    <tr>
                        <td colSpan={3}>#40: I-10 (US 59 to I-610), 4.79 miles		</td>
                    </tr>
                    <tr>
                        <td>18K annual person-hours delay per mile</td>
                        <td >86K annual person-hours delay</td>
                        <td>$4.9M annual congestion cost ($1.0M/mile)</td>
                    </tr>

                </tbody>
            </Table>
            <h6>Table 1. Problematic Segments Identified in the Texas 100 Most Congested Roadways List and Texas 50 Most Congested Freight Bottlenecks</h6>
        </div>
    );
}

export default HoustonTable;
