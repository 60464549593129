import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);

export default function DonutMultiLayerChart(props) {
  const {
    chartHeight,
    titleText,
    subtitleText,
    pieInnerSize,
    pieDepth,
    chartData,
  } = props;

  let i = 0;
  let browserData = [];
  let versionsData = [];
  // Build the data arrays
  for (i = 0; i < chartData.length; i += 1) {
    // add browser data
    browserData.push({
      //   name: categories[i],
      name: chartData[i].name,
      y: chartData[i].y,
      color: chartData[i].color,
      sliced: false,
    });

    // add version data
    if (chartData[i].drilldown) {
      let j = 0;
      for (j = 0; j < chartData[i].drilldown.data.length; j += 1) {
        versionsData.push({
          name: chartData[i].drilldown.categories[j],
          y: chartData[i].drilldown.data[j],
          color: chartData[i].drilldown.color[j],
          opacity: chartData[i].drilldown.opacity[j],
          category: chartData[i].name,
        });
      }
    }
  }

  const options = {
    chart: {
      type: "pie",
      height: chartHeight,
    },
    legend: {
      align: "center",
      layout: "vertical",
      verticalAlign: "center",
      x: chartHeight / 2 + 15,
      y: chartHeight / 2,
      labelFormatter: function() {
        return this.name + " - " + this.y + "%";
      },
    },
    title: {
      text: titleText,
      align: "center",
    },
    subtitle: {
      text: subtitleText,
      align: "center",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ["50%", "50%"],
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2">{series.name}</th></tr>',
      pointFormat:
        '<tr><td style="color: {point.color}"><span class="donut-chart-tooltip-dot" style="background-color: {point.color};"></span>{point.name} </td>' +
        '<td style="text-align: left"><b>{point.y} </b></td></tr>',
      footerFormat: "</table>",
      valueDecimals: 1,
      valueSuffix: "%",
    },
    series: [
      {
        name: "Node/Transportation",
        data: browserData,
        size: "45%",
        innerSize: "60%",
        slicedOffset: 0,
        dataLabels: {
          enabled: false,
          connectorWidth: 2,
          connectorColor: "black",
          formatter: function() {
            // display only if larger than 1
            return "<b>" + this.point.name + ":</b> " + this.y + "%";
          },
        },
        showInLegend: true,
      },
      {
        name: "Transportation Mode",
        data: versionsData,
        size: "75%",
        innerSize: "60%",
        slicedOffset: 0,
        sliced: true,
        dataLabels: {
          formatter: function() {
            // display only if larger than 1
            return "<b>" + this.point.name + ":</b> " + this.y + "%";
          },
        },
        id: "versions",
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
        },
      ],
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
