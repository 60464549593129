import Table from 'react-bootstrap/Table'

const i35_Table = () => {

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th colSpan={3}>Texas 100 Most Congested Roadways List: Locations</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3}>#16: I-35W (SH 183 to I-30), 3.37 miles		</td>
                    </tr>
                    <tr>
                        <td>545K annual person-hours delay per mile</td>
                        <td>1.8M annual person-hours delay</td>
                        <td>$41.9M annual congestion cost ($12.4M/mile)</td>

                    </tr>
                    <tr>
                        <td colSpan={3}>#42: I-35W (Alliance Gateway/SH 170 to US 287), 5.09 miles		</td>
                    </tr>
                    <tr>
                        <td>280K annual person-hours delay per mile</td>
                        <td >1.4M annual person-hours delay</td>
                        <td>$31.9M annual congestion cost ($6.3M/mile)</td>
                    </tr>

                </tbody>
                <thead>
                    <tr>
                        <th colSpan={3}>Texas 100 Most Congested Freight Bottlenecks: Locations		</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3}>#8: I-35W (SH 183 to I-30), 3.37 miles		</td>
                    </tr>
                    <tr>
                        <td>43K annual person-hours delay per mile</td>
                        <td>147K annual person-hours delay</td>
                        <td>$8.6M annual congestion cost ($2.6M/mile) </td>

                    </tr>
                    <tr>
                        <td colSpan={3}>#30: I-35W (Alliance Gateway/SH 170 to US 287), 5.09 miles		</td>
                    </tr>
                    <tr>
                        <td>21K annual person-hours delay per mile</td>
                        <td >106K annual person-hours delay</td>
                        <td>$6.1M annual congestion cost ($1.2M/mile)</td>
                    </tr>
                </tbody>
            </Table>
            <h6>Table 1. Problematic Segments Identified in the Texas 100 Most Congested Roadways List and Texas 50 Most Congested Freight Bottlenecks</h6>
        </div>
    );
}

export default i35_Table;
