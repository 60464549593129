import Box from "@mui/material/Box";
import Resiliency from "../freightResiliency/Resiliency.js"

const FrftResiliency = () => {
  return (
    <Box sx={{ m: 10 }}>
      <Resiliency />
    </Box>
  );
};
export default FrftResiliency;
