import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);

export default function GroupedBarChart(props) {
  const {
    chartHeight,
    chartSpacing,
    titleText,
    subtitleText,
    chartData,
    series,
    chartRef,
    xAxisCategories,
    yAxisSettings,
    tooltipXlabel,
    tooltipYlabel,
    plotOptions,
    chartType
  } = props;

  const options = {
    chart: {
      type: chartType,
      height: chartHeight,
      //   width: "100px",
      //   spacing: [5, 10, 5, 5],
      spacing: !chartSpacing ? [5, 10, 5, 5] : chartSpacing,
    },

    title: titleText,

    xAxis: {
      categories: xAxisCategories,
    },

    yAxis: yAxisSettings,

    //Commented out tooltip is for stacked bar chart.
    // tooltip: {
    //   formatter: function() {
    //     return (
    //       "<b>" +
    //       this.x +
    //       "</b><br/>" +
    //       this.series.name +
    //       ": " +
    //       this.y +
    //       "<br/>" +
    //       "Total: " +
    //       this.point.stackTotal
    //     );
    //   },
    // },
    tooltip: {
      formatter: function() {
        return (
          "<b>" +
          this.x +
          " " +
          tooltipXlabel +
          "</b><br/>" +
          this.series.name +
          ": " +
          this.y +
          " " +
          tooltipYlabel
        );
      },
    },

    // plotOptions: {
    //   // column: {
    //   //   stacking: "normal",
    //   // },
    //   series: {
    //     pointWidth: 20,
    //     groupPadding: 0,
    //     pointPadding: 0,
    //   },
    // },
    plotOptions: plotOptions,

    series: series,

    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
}
