import Highcharts from "highcharts/highstock";

export const locations = [
  {
    id: 1,
    name: "DFW",
    label: "Alliance Airport-Fort Worth",
    lookup: "DFW",
    location: [-97.310615, 32.959099],
  },
  {
    id: 2,
    name: "El Paso",
    label: "El Paso",
    lookup: "El Paso",
    location: [-106.432765, 31.777572],
  },
  // {
  //   id: 3,
  //   name: "I-35",
  //   label: "Alliance Airport-I-35",
  //   lookup: "I-35",
  //   location: [-97.31929413082771, 32.756559830698215],
  // },
  {
    id: 4,
    name: "Houston",
    label: "Houston",
    lookup: "Houston",
    location: [-95.284145, 29.778016],
  },
];

export const colorSchemes = [
  { name: "Spectral", colors: ["#2b83ba", "#ffffbf", "#d7191c"] },
  { name: "Green-Yellow-Red", colors: ["#1a9641", "#fffebf", "#d7191c"] },
  { name: "Reds", colors: ["#fff5f0", "#fb6a4a", "#67000d"] },
  { name: "Blues", colors: ["#f7fbff", "#6baed6", "#08306b"] },
  { name: "Greens", colors: ["#f7fcf5", "#74c476", "#00441b"] },
  { name: "Greys", colors: ["#fafafa", "#878787", "#050505"] },
];

export const commodities = [
  {
    id: 1,
    name: "All Commodities",
    disabled: false,
    variables: { tons: "Route_tons", values: "Route_valu" },
    tooltip: "Consists of 2015 Transearch: All Commodities",
  },
  {
    id: 2,
    name: "Poultry",
    disabled: true,
    variables: { tons: "PoultryTon", values: "PoultValue" },
    tooltip:
      "Consists of 2015 Transearch: poultry or poultry products,processed poultry or eggs",
  },
  {
    id: 3,
    name: "Cotton/Grains",
    disabled: true,
    variables: { tons: "CotGraiTon", values: "CotGraValu" },
    tooltip:
      "Consists of 2015 Transearch: cotton broad-woven fabrics,cotton broad-woven fabrics,cotton broad-woven fabrics,cotton,raw,cottonseed oil or by-prod,flour or other grain mill products,grain,grain mill products",
  },
  {
    id: 4,
    name: "Produce",
    disabled: true,
    variables: { tons: "ProduceTon", values: "ProduValue" },
    tooltip:
      "Consists of 2015 Transearch: leafy fresh vegetables,fresh vegetables,citrus fruits,deciduous fruits,fresh fruits or tree nuts,misc fresh fruits or tree nuts,tropical fruits",
  },
  {
    id: 5,
    name: "Energy",
    disabled: true,
    variables: { tons: "EnergyTon", values: "EnergValue" },
    tooltip:
      "Consists of 2015 Transearch: oil field machinery or equipment,natural gas,natural gasoline,natural gasoline,petroleum or coal products,crude petroleum,liquefied gases, coal or petroleum",
  },
  {
    id: 6,
    name: "Electronics",
    disabled: true,
    variables: { tons: "ElectroTon", values: "ElectValue" },
    tooltip:
      "Consists of 2015 Transearch: electric housewares or fans,electric lamps,electric lighting or wire equipment,electric measuring instrmts,electric trans or distributors,electrical equipment,electrical equipment, nec,electrical transformers,electrometallurgical products,electronic components,electronic data proc equipment,electronic tubes,industrial electrical equipment,misc electrical industrial equipment,misc electrical machinery,misc electronic components",
  },
  {
    id: 7,
    name: "Automobiles",
    disabled: true,
    variables: { tons: "AutoTon", values: "AutoValue" },
    tooltip:
      "Consists of 2015 Transearch: motor bus or truck bodies,motor vehic or equipment,motor vehicle parts or accessories,motor vehicles,passenger motor car bodies",
  },
];

export const contourFilenames = [
  {
    statusGuide: "DFW",
    am12: "Alliance Airport - Intersection of I-35W and SH-170-12 am.geojson",
    am7: "Alliance Airport - Intersection of I-35W and SH-170-7 am.geojson",
    pm5: "Alliance Airport - Intersection of I-35W and SH-170-5 pm.geojson",
  },
  {
    statusGuide: "El Paso",
    am12:
      "El Paso - I-10 at Reynolds St which (just on east side of US54)-12 am.geojson",
    am7:
      "El Paso - I-10 at Reynolds St which (just on east side of US54)-7 am.geojson",
    pm5:
      "El Paso - I-10 at Reynolds St which (just on east side of US54)-5 pm.geojson",
  },
  {
    statusGuide: "Houston",
    am12:
      "Houston - I-10 at McCarty Rd (just west of I-610 East)-12 am.geojson",
    am7: "Houston - I-10 at McCarty Rd (just west of I-610 East)-7 am.geojson",
    pm5: "Houston - I-10 at McCarty Rd (just west of I-610 East)-5 pm.geojson",
  },
  {
    statusGuide: "I-35",
    am12: "Fort Worth - I-35W at SH-280-12 am.geojson",
    am7: "Fort Worth - I-35W at SH-280-7 am.geojson",
    pm5: "Fort Worth - I-35W at SH-280-5 pm.geojson",
  },
];

export const supplyChainFluidityOptions = [
  {
    caseStudy: "Motor Vehicle",
    options: [
      {
        option:
          "Trucks Using I-10 to El Paso, Texas, that End in Austin, Texas",
        //  webMap: "https://arcg.is/1uianr",
        webMap:
          "https://tti.maps.arcgis.com/apps/instant/minimalist/index.html?appid=99ff33bfeca943daaef43acae5619f02&center=-106.2385,32.2971&level=4",
        summaryData: [
          {
            id: 1,
            activity: "Shanghai, China to Port of Long Beach, CA",
            travelDwellTimeHrs: 468.89,
            freeFlowHrs: 436.17,
            scfi: 0.93,
            type: "transportation",
            coordinates: "-118.216458,33.754185",
          },
          {
            id: 2,
            activity: "Port Dwell Time",
            travelDwellTimeHrs: 115.17,
            freeFlowHrs: 36,
            scfi: 0.313,
            type: "node",
          },
          {
            id: 3,
            activity: "Truck Transportation to El Paso, TX via I-10",
            travelDwellTimeHrs: 14.67,
            freeFlowHrs: 12.58,
            scfi: 0.858,
            type: "transportation",
          },
          {
            id: 4,
            activity: "Warehousing",
            travelDwellTimeHrs: 1592.74,
            freeFlowHrs: 1412.64,
            scfi: 0.887,
            type: "node",
          },
          {
            id: 5,
            activity: "Truck Transportation to Ciudad Juárez, Mexico",
            travelDwellTimeHrs: 1.3,
            freeFlowHrs: 0.68,
            scfi: 0.524,
            type: "transportation",
          },
          {
            id: 6,
            activity: "Motor Vehicle Parts Manufacturing",
            travelDwellTimeHrs: 2329.27,
            freeFlowHrs: 2023.92,
            scfi: 0.869,
            type: "node",
          },
          {
            id: 7,
            activity: "Truck Transportation to El Paso, TX",
            travelDwellTimeHrs: 1.97,
            freeFlowHrs: 1.14,
            scfi: 0.579,
            type: "transportation",
          },
          {
            id: 8,
            activity: "Warehousing",
            travelDwellTimeHrs: 736.54,
            freeFlowHrs: 611.28,
            scfi: 0.83,
            type: "node",
          },
          {
            id: 9,
            activity: "Truck Transportation to San Antonio, TX via I-10",
            travelDwellTimeHrs: 10.86,
            freeFlowHrs: 8.55,
            scfi: 0.788,
            type: "transportation",
          },
          {
            id: 10,
            activity: "Motor Vehicle Manufacturing",
            travelDwellTimeHrs: 1878.29,
            freeFlowHrs: 1627.92,
            scfi: 0.867,
            type: "node",
          },
          {
            id: 11,
            activity: "Truck Transportation to Austin, TX via I-35",
            travelDwellTimeHrs: 2.33,
            freeFlowHrs: 1.66,
            scfi: 0.714,
            type: "transportation",
          },
          {
            id: 12,
            activity: "Motor Vehicle Retail",
            travelDwellTimeHrs: 1411.58,
            freeFlowHrs: 1092.24,
            scfi: 0.774,
            type: "node",
          },
          {
            id: 13,
            activity: "Total",
            travelDwellTimeHrs: 8563.6,
            freeFlowHrs: 7264.8,
            scfi: 0.848,
            type: "total",
          },
        ],
        scfiContributionsPercent: [
          {
            node: 94.2,
            transportaion: { maritime: 5.5, rail: 0, truck: 0.3 },
          },
        ],
      },
      {
        option:
          "Trucks Using I-10 to El Paso, Texas, that End in Longo, California, via Intermodal",
        //  webMap: "https://arcg.is/111fPT0",
        webMap:
          "https://tti.maps.arcgis.com/apps/instant/minimalist/index.html?appid=ca8a93519e2a419194868f312ae8d093&center=-106.2385,32.2971&level=4",
        summaryData: [
          {
            id: 1,
            activity: "Shanghai, China to Port of Long Beach, CA",
            travelDwellTimeHrs: 468.89,
            freeFlowHrs: 436.17,
            scfi: 0.93,
            type: "transportation",
          },
          {
            id: 2,
            activity: "Port Dwell Time",
            travelDwellTimeHrs: 115.17,
            freeFlowHrs: 36,
            scfi: 0.313,
            type: "node",
          },
          {
            id: 3,
            activity: "Truck Transportation to El Paso, TX via I-10",
            travelDwellTimeHrs: 14.67,
            freeFlowHrs: 12.58,
            scfi: 0.858,
            type: "transportation",
          },
          {
            id: 4,
            activity: "Warehousing",
            travelDwellTimeHrs: 1592.74,
            freeFlowHrs: 1412.64,
            scfi: 0.887,
            type: "node",
          },
          {
            id: 5,
            activity: "Truck Transportation to Ciudad Juárez, Mexico",
            travelDwellTimeHrs: 1.3,
            freeFlowHrs: 0.68,
            scfi: 0.524,
            type: "transportation",
          },
          {
            id: 6,
            activity: "Motor Vehicle Parts Manufacturing",
            travelDwellTimeHrs: 2329.27,
            freeFlowHrs: 2023.92,
            scfi: 0.869,
            type: "node",
          },
          {
            id: 7,
            activity: "Truck Transportation to El Paso, TX",
            travelDwellTimeHrs: 1.97,
            freeFlowHrs: 1.14,
            scfi: 0.579,
            type: "transportation",
          },
          {
            id: 8,
            activity: "Warehousing",
            travelDwellTimeHrs: 736.54,
            freeFlowHrs: 611.28,
            scfi: 0.83,
            type: "node",
          },
          {
            id: 9,
            activity: "Truck Transportation to San Antonio, TX via I-10",
            travelDwellTimeHrs: 10.86,
            freeFlowHrs: 8.55,
            scfi: 0.788,
            type: "transportation",
          },
          {
            id: 10,
            activity: "Motor Vehicle Manufacturing",
            travelDwellTimeHrs: 1878.29,
            freeFlowHrs: 1627.92,
            scfi: 0.867,
            type: "node",
          },
          {
            id: 11,
            activity:
              "San Antonio, Texas to Longo, California (via Rail + Truck)",
            travelDwellTimeHrs: 74.88,
            freeFlowHrs: 63.47,
            scfi: 0.848,
            type: "transportation",
          },
          {
            id: 12,
            activity: "Motor Vehicle Retail",
            travelDwellTimeHrs: 1411.58,
            freeFlowHrs: 1092.24,
            scfi: 0.774,
            type: "node",
          },
          {
            id: 13,
            activity: "Total",
            travelDwellTimeHrs: 8636.2,
            freeFlowHrs: 7326.6,
            scfi: 0.848,
            type: "total",
          },
        ],
        scfiContributionsPercent: [
          {
            node: 93.4,
            transportaion: { maritime: 5.4, rail: 0.9, truck: 0.3 },
          },
        ],
      },
      {
        option: "Trucks Using I-8 to El Paso, Texas, that End in Austin, Texas",
        //  webMap: "https://arcg.is/0XCXuT",
        webMap:
          "https://tti.maps.arcgis.com/apps/instant/minimalist/index.html?appid=84f9414dc848430097d23f8295461a6a&center=-106.2385,32.2971&level=4",
        summaryData: [
          {
            id: 1,
            activity: "Shanghai, China to Port of Long Beach, CA",
            travelDwellTimeHrs: 468.89,
            freeFlowHrs: 436.17,
            scfi: 0.93,
            type: "transportation",
          },
          {
            id: 2,
            activity: "Port Dwell Time",
            travelDwellTimeHrs: 115.17,
            freeFlowHrs: 36,
            scfi: 0.313,
            type: "node",
          },
          {
            id: 3,
            activity: "Truck Transportation to El Paso, TX via I-8",
            travelDwellTimeHrs: 14.94,
            freeFlowHrs: 12.97,
            scfi: 0.868,
            type: "transportation",
          },
          {
            id: 4,
            activity: "Warehousing",
            travelDwellTimeHrs: 1592.74,
            freeFlowHrs: 1412.64,
            scfi: 0.887,
            type: "node",
          },
          {
            id: 5,
            activity: "Truck Transportation to Ciudad Juárez, Mexico",
            travelDwellTimeHrs: 1.3,
            freeFlowHrs: 0.68,
            scfi: 0.524,
            type: "transportation",
          },
          {
            id: 6,
            activity: "Motor Vehicle Parts Manufacturing",
            travelDwellTimeHrs: 2329.27,
            freeFlowHrs: 2023.92,
            scfi: 0.869,
            type: "node",
          },
          {
            id: 7,
            activity: "Truck Transportation to El Paso, TX",
            travelDwellTimeHrs: 1.97,
            freeFlowHrs: 1.14,
            scfi: 0.579,
            type: "transportation",
          },
          {
            id: 8,
            activity: "Warehousing",
            travelDwellTimeHrs: 736.54,
            freeFlowHrs: 611.28,
            scfi: 0.83,
            type: "node",
          },
          {
            id: 9,
            activity: "Truck Transportation to San Antonio, TX via I-10",
            travelDwellTimeHrs: 10.86,
            freeFlowHrs: 8.55,
            scfi: 0.788,
            type: "transportation",
          },
          {
            id: 10,
            activity: "Motor Vehicle Manufacturing",
            travelDwellTimeHrs: 1878.29,
            freeFlowHrs: 1627.92,
            scfi: 0.867,
            type: "node",
          },
          {
            id: 11,
            activity: "Truck Transportation to Austin, TX via I-35",
            travelDwellTimeHrs: 2.33,
            freeFlowHrs: 1.66,
            scfi: 0.714,
            type: "transportation",
          },
          {
            id: 12,
            activity: "Motor Vehicle Retail",
            travelDwellTimeHrs: 1411.58,
            freeFlowHrs: 1092.24,
            scfi: 0.774,
            type: "node",
          },
          {
            id: 13,
            activity: "Total",
            travelDwellTimeHrs: 8563.9,
            freeFlowHrs: 7265.2,
            scfi: 0.848,
            type: "total",
          },
        ],
        scfiContributionsPercent: [
          {
            node: 94.1,
            transportaion: { maritime: 5.5, rail: 0, truck: 0.4 },
          },
        ],
      },
      {
        option:
          "Trucks Using I-8 to El Paso, Texas that End in Longo, California",
        //  webMap: "https://arcg.is/0vGmzn",
        webMap:
          "https://tti.maps.arcgis.com/apps/instant/minimalist/index.html?appid=3ec488caf22e4071bb025ca977db9bb8&center=-106.2385,32.2971&level=4",
        summaryData: [
          {
            id: 1,
            activity: "Shanghai, China to Port of Long Beach, CA",
            travelDwellTimeHrs: 468.89,
            freeFlowHrs: 436.17,
            scfi: 0.93,
            type: "transportation",
          },
          {
            id: 2,
            activity: "Port Dwell Time",
            travelDwellTimeHrs: 115.17,
            freeFlowHrs: 36,
            scfi: 0.313,
            type: "node",
          },
          {
            id: 3,
            activity: "Truck Transportation to El Paso, TX via I-8",
            travelDwellTimeHrs: 14.94,
            freeFlowHrs: 12.97,
            scfi: 0.868,
            type: "transportation",
          },
          {
            id: 4,
            activity: "Warehousing",
            travelDwellTimeHrs: 1592.74,
            freeFlowHrs: 1412.64,
            scfi: 0.887,
            type: "node",
          },
          {
            id: 5,
            activity: "Truck Transportation to Ciudad Juárez, Mexico",
            travelDwellTimeHrs: 1.3,
            freeFlowHrs: 0.68,
            scfi: 0.524,
            type: "transportation",
          },
          {
            id: 6,
            activity: "Motor Vehicle Parts Manufacturing",
            travelDwellTimeHrs: 2329.27,
            freeFlowHrs: 2023.92,
            scfi: 0.869,
            type: "node",
          },
          {
            id: 7,
            activity: "Truck Transportation to El Paso, TX",
            travelDwellTimeHrs: 1.97,
            freeFlowHrs: 1.14,
            scfi: 0.579,
            type: "transportation",
          },
          {
            id: 8,
            activity: "Warehousing",
            travelDwellTimeHrs: 736.54,
            freeFlowHrs: 611.28,
            scfi: 0.83,
            type: "node",
          },
          {
            id: 9,
            activity: "Truck Transportation to San Antonio, TX via I-10",
            travelDwellTimeHrs: 10.86,
            freeFlowHrs: 8.55,
            scfi: 0.788,
            type: "transportation",
          },
          {
            id: 10,
            activity: "Motor Vehicle Manufacturing",
            travelDwellTimeHrs: 1878.29,
            freeFlowHrs: 1627.92,
            scfi: 0.867,
            type: "node",
          },
          {
            id: 11,
            activity:
              "San Antonio, Texas to Longo, California (via Rail + Truck)",
            travelDwellTimeHrs: 74.88,
            freeFlowHrs: 63.47,
            scfi: 0.848,
            type: "transportation",
          },
          {
            id: 12,
            activity: "Motor Vehicle Retail",
            travelDwellTimeHrs: 1411.58,
            freeFlowHrs: 1092.24,
            scfi: 0.774,
            type: "node",
          },
          {
            id: 13,
            activity: "Total",
            travelDwellTimeHrs: 8636.4,
            freeFlowHrs: 7327.0,
            scfi: 0.848,
            type: "total",
          },
        ],
        scfiContributionsPercent: [
          {
            node: 93.3,
            transportaion: { maritime: 5.4, rail: 0.9, truck: 0.4 },
          },
        ],
      },
      {
        option:
          "Intermodal Transportation to El Paso, Texas that End in Austin, Texas",
        //  webMap: "https://arcg.is/1KeOmu1",
        webMap:
          "https://tti.maps.arcgis.com/apps/instant/minimalist/index.html?appid=35f2b4c310bd491d93d329337867dade&center=-106.2385,32.2971&level=4",
        summaryData: [
          {
            id: 1,
            activity: "Shanghai, China to Port of Long Beach, CA",
            travelDwellTimeHrs: 468.89,
            freeFlowHrs: 436.17,
            scfi: 0.93,
            type: "transportation",
          },
          {
            id: 2,
            activity: "Port Dwell Time",
            travelDwellTimeHrs: 115.17,
            freeFlowHrs: 36,
            scfi: 0.313,
            type: "node",
          },
          {
            id: 3,
            activity: "Truck Transportation to El Paso, TX via Rail + Truck",
            travelDwellTimeHrs: 44.85,
            freeFlowHrs: 37.66,
            scfi: 0.84,
            type: "transportation",
          },
          {
            id: 4,
            activity: "Warehousing",
            travelDwellTimeHrs: 1592.74,
            freeFlowHrs: 1412.64,
            scfi: 0.887,
            type: "node",
          },
          {
            id: 5,
            activity: "Truck Transportation to Ciudad Juárez, Mexico",
            travelDwellTimeHrs: 1.3,
            freeFlowHrs: 0.68,
            scfi: 0.524,
            type: "transportation",
          },
          {
            id: 6,
            activity: "Motor Vehicle Parts Manufacturing",
            travelDwellTimeHrs: 2329.27,
            freeFlowHrs: 2023.92,
            scfi: 0.869,
            type: "node",
          },
          {
            id: 7,
            activity: "Truck Transportation to El Paso, TX",
            travelDwellTimeHrs: 1.97,
            freeFlowHrs: 1.14,
            scfi: 0.579,
            type: "transportation",
          },
          {
            id: 8,
            activity: "Warehousing",
            travelDwellTimeHrs: 736.54,
            freeFlowHrs: 611.28,
            scfi: 0.83,
            type: "node",
          },
          {
            id: 9,
            activity: "Truck Transportation to San Antonio, TX via I-10",
            travelDwellTimeHrs: 10.86,
            freeFlowHrs: 8.55,
            scfi: 0.788,
            type: "transportation",
          },
          {
            id: 10,
            activity: "Motor Vehicle Manufacturing",
            travelDwellTimeHrs: 1878.29,
            freeFlowHrs: 1627.92,
            scfi: 0.867,
            type: "node",
          },
          {
            id: 11,
            activity: "Truck Transportation to Austin, TX via I-35",
            travelDwellTimeHrs: 2.33,
            freeFlowHrs: 1.66,
            scfi: 0.714,
            type: "transportation",
          },
          {
            id: 12,
            activity: "Motor Vehicle Retail",
            travelDwellTimeHrs: 1411.58,
            freeFlowHrs: 1092.24,
            scfi: 0.774,
            type: "node",
          },
          {
            id: 13,
            activity: "Total",
            travelDwellTimeHrs: 8593.8,
            freeFlowHrs: 7289.9,
            scfi: 0.848,
            type: "total",
          },
        ],
        scfiContributionsPercent: [
          {
            node: 93.8,
            transportaion: { maritime: 5.5, rail: 0.5, truck: 0.2 },
          },
        ],
      },
      {
        option:
          "Intermodal Transportation to El Paso, Texas that End in Longo, California",
        //  webMap: "https://arcg.is/1eiCHP",
        webMap:
          "https://tti.maps.arcgis.com/apps/instant/minimalist/index.html?appid=a6aa20c71d96427d92be968bcc548a7a&center=-106.2385,32.2971&level=4",
        summaryData: [
          {
            id: 1,
            activity: "Shanghai, China to Port of Long Beach, CA",
            travelDwellTimeHrs: 468.89,
            freeFlowHrs: 436.17,
            scfi: 0.93,
            type: "transportation",
          },
          {
            id: 2,
            activity: "Port Dwell Time",
            travelDwellTimeHrs: 115.17,
            freeFlowHrs: 36,
            scfi: 0.313,
            type: "node",
          },
          {
            id: 3,
            activity: "Truck Transportation to El Paso, TX via Rail + Truck",
            travelDwellTimeHrs: 44.85,
            freeFlowHrs: 37.66,
            scfi: 0.84,
            type: "transportation",
          },
          {
            id: 4,
            activity: "Warehousing",
            travelDwellTimeHrs: 1592.74,
            freeFlowHrs: 1412.64,
            scfi: 0.887,
            type: "node",
          },
          {
            id: 5,
            activity: "Truck Transportation to Ciudad Juárez, Mexico",
            travelDwellTimeHrs: 1.3,
            freeFlowHrs: 0.68,
            scfi: 0.524,
            type: "transportation",
          },
          {
            id: 6,
            activity: "Motor Vehicle Parts Manufacturing",
            travelDwellTimeHrs: 2329.27,
            freeFlowHrs: 2023.92,
            scfi: 0.869,
            type: "node",
          },
          {
            id: 7,
            activity: "Truck Transportation to El Paso, TX",
            travelDwellTimeHrs: 1.97,
            freeFlowHrs: 1.14,
            scfi: 0.579,
            type: "transportation",
          },
          {
            id: 8,
            activity: "Warehousing",
            travelDwellTimeHrs: 736.54,
            freeFlowHrs: 611.28,
            scfi: 0.83,
            type: "node",
          },
          {
            id: 9,
            activity: "Truck Transportation to San Antonio, TX via I-10",
            travelDwellTimeHrs: 10.86,
            freeFlowHrs: 8.55,
            scfi: 0.788,
            type: "transportation",
          },
          {
            id: 10,
            activity: "Motor Vehicle Manufacturing",
            travelDwellTimeHrs: 1878.29,
            freeFlowHrs: 1627.92,
            scfi: 0.867,
            type: "node",
          },
          {
            id: 11,
            activity:
              "San Antonio, Texas to Longo, California (via Rail + Truck)",
            travelDwellTimeHrs: 74.88,
            freeFlowHrs: 63.47,
            scfi: 0.848,
            type: "transportation",
          },
          {
            id: 12,
            activity: "Motor Vehicle Retail",
            travelDwellTimeHrs: 1411.58,
            freeFlowHrs: 1092.24,
            scfi: 0.774,
            type: "node",
          },
          {
            id: 13,
            activity: "Total",
            travelDwellTimeHrs: 8666.3,
            freeFlowHrs: 7351.7,
            scfi: 0.848,
            type: "total",
          },
        ],
      },
    ],
    scfiContributionsPercent: [
      {
        node: 93.0,
        transportaion: { maritime: 5.4, rail: 1.4, truck: 0.2 },
      },
    ],
  },
  {
    caseStudy: "Computers",
    options: [
      {
        option: "Trucks Using US-290 to Houston, Texas",
        webMap: "https://arcg.is/Xanrr0",
        summaryData: [
          {
            id: 1,
            activity: "Hsinchu, Taiwan to Port of Long Beach, CA",
            travelDwellTimeHrs: 474.36,
            freeFlowHrs: 431.96,
            scfi: 0.911,
            type: "transportation",
          },
          {
            id: 2,
            activity: "Port Dwell Time",
            travelDwellTimeHrs: 115.17,
            freeFlowHrs: 36,
            scfi: 0.313,
            type: "node",
          },
          {
            id: 3,
            activity: "Truck Transportation to Austin, TX via I-10",
            travelDwellTimeHrs: 24.27,
            freeFlowHrs: 21.19,
            scfi: 0.873,
            type: "transportation",
          },
          {
            id: 4,
            activity: "Computer Manufacturing",
            travelDwellTimeHrs: 3322.9,
            freeFlowHrs: 3162.24,
            scfi: 0.952,
            type: "node",
          },
          {
            id: 5,
            activity: "Truck Transportation to Houston, TX via US-290",
            travelDwellTimeHrs: 2.92,
            freeFlowHrs: 2.66,
            scfi: 0.911,
            type: "transportation",
          },
          {
            id: 6,
            activity: "Computer Retail",
            travelDwellTimeHrs: 641.06,
            freeFlowHrs: 520.56,
            scfi: 0.812,
            type: "node",
          },
          {
            id: 7,
            activity: "Total",
            travelDwellTimeHrs: 4580.7,
            freeFlowHrs: 4174.6,
            scfi: 0.911,
            type: "total",
          },
        ],
        scfiContributionsPercent: [
          {
            node: 89.1,
            transportaion: { maritime: 10.4, rail: 0, truck: 0.5 },
          },
        ],
      },
      {
        option: "Trucks Using Hwy 71/I-10 to Houston, Texas",
        webMap: "https://arcg.is/0y5zCi",
        summaryData: [
          {
            id: 1,
            activity: "Hsinchu, Taiwan to Port of Long Beach, CA",
            travelDwellTimeHrs: 474.36,
            freeFlowHrs: 431.96,
            scfi: 0.911,
            type: "transportation",
          },
          {
            id: 2,
            activity: "Port Dwell Time",
            travelDwellTimeHrs: 115.17,
            freeFlowHrs: 36,
            scfi: 0.313,
            type: "node",
          },
          {
            id: 3,
            activity: "Truck Transportation to Austin, TX via I-10",
            travelDwellTimeHrs: 24.27,
            freeFlowHrs: 21.19,
            scfi: 0.873,
            type: "transportation",
          },
          {
            id: 4,
            activity: "Computer Manufacturing",
            travelDwellTimeHrs: 3322.9,
            freeFlowHrs: 3162.24,
            scfi: 0.952,
            type: "node",
          },
          {
            id: 5,
            activity: "Truck Transportation to Houston, TX via Hwy 71/I-10",
            travelDwellTimeHrs: 3.1,
            freeFlowHrs: 2.8,
            scfi: 0.902,
            type: "transportation",
          },
          {
            id: 6,
            activity: "Computer Retail",
            travelDwellTimeHrs: 641.06,
            freeFlowHrs: 520.56,
            scfi: 0.812,
            type: "node",
          },
          {
            id: 7,
            activity: "Total",
            travelDwellTimeHrs: 4580.9,
            freeFlowHrs: 4174.8,
            scfi: 0.911,
            type: "total",
          },
        ],
        scfiContributionsPercent: [
          {
            node: 89.0,
            transportaion: { maritime: 10.4, rail: 0, truck: 0.6 },
          },
        ],
      },
      {
        option: "Trucks Using I-10 to Elk Grove, California",
        webMap: "https://arcg.is/0fjPG0",
        summaryData: [
          {
            id: 1,
            activity: "Hsinchu, Taiwan to Port of Long Beach, CA",
            travelDwellTimeHrs: 474.36,
            freeFlowHrs: 431.96,
            scfi: 0.911,
            type: "transportation",
          },
          {
            id: 2,
            activity: "Port Dwell Time",
            travelDwellTimeHrs: 115.17,
            freeFlowHrs: 36,
            scfi: 0.313,
            type: "node",
          },
          {
            id: 3,
            activity: "Truck Transportation to Austin, TX via I-10",
            travelDwellTimeHrs: 24.27,
            freeFlowHrs: 21.19,
            scfi: 0.873,
            type: "transportation",
          },
          {
            id: 4,
            activity: "Computer Manufacturing",
            travelDwellTimeHrs: 3322.9,
            freeFlowHrs: 3162.24,
            scfi: 0.952,
            type: "node",
          },
          {
            id: 5,
            activity: "Truck Transportation to Elk Grove, CA via I-10",
            travelDwellTimeHrs: 27.91,
            freeFlowHrs: 26.14,
            scfi: 0.937,
            type: "transportation",
          },
          {
            id: 6,
            activity: "Computer Retail",
            travelDwellTimeHrs: 372.89,
            freeFlowHrs: 309.6,
            scfi: 0.83,
            type: "node",
          },
          {
            id: 7,
            activity: "Total",
            travelDwellTimeHrs: 4337.5,
            freeFlowHrs: 3987.1,
            scfi: 0.919,
            type: "total",
          },
        ],
        scfiContributionsPercent: [
          {
            node: 87.9,
            transportaion: { maritime: 10.9, rail: 0, truck: 1.2 },
          },
        ],
      },
      {
        option: "Trucks Using I-40 to Elk Grove, California",
        webMap: "https://arcg.is/10v0eS0",
        summaryData: [
          {
            id: 1,
            activity: "Hsinchu, Taiwan to Port of Long Beach, CA",
            travelDwellTimeHrs: 474.36,
            freeFlowHrs: 431.96,
            scfi: 0.911,
            type: "transportation",
          },
          {
            id: 2,
            activity: "Port Dwell Time",
            travelDwellTimeHrs: 115.17,
            freeFlowHrs: 36,
            scfi: 0.313,
            type: "node",
          },
          {
            id: 3,
            activity: "Truck Transportation to Austin, TX via I-10",
            travelDwellTimeHrs: 24.27,
            freeFlowHrs: 21.19,
            scfi: 0.873,
            type: "transportation",
          },
          {
            id: 4,
            activity: "Computer Manufacturing",
            travelDwellTimeHrs: 3322.9,
            freeFlowHrs: 3162.24,
            scfi: 0.952,
            type: "node",
          },
          {
            id: 5,
            activity: "Truck Transportation to Elk Grove, CA via I-40",
            travelDwellTimeHrs: 27.68,
            freeFlowHrs: 26.05,
            scfi: 0.941,
            type: "transportation",
          },
          {
            id: 6,
            activity: "Computer Retail",
            travelDwellTimeHrs: 372.89,
            freeFlowHrs: 309.6,
            scfi: 0.83,
            type: "node",
          },
          {
            id: 7,
            activity: "Total",
            travelDwellTimeHrs: 4337.3,
            freeFlowHrs: 3987.0,
            scfi: 0.919,
            type: "total",
          },
        ],
        scfiContributionsPercent: [
          {
            node: 87.9,
            transportaion: { maritime: 10.9, rail: 0, truck: 1.2 },
          },
        ],
      },
    ],
  },
  {
  caseStudy: "Food Manufacturing",
  options: [
    {
      option: "Rail from Laredo to San Antonio, Tx",
      webMap: "TBD",
      summaryData: [
        {
          id: 1,
          activity: "Guanajuato, Mexico - Harvest Corn",
          travelDwellTimeHrs: 763.68,
          freeFlowHrs: 721.20,
          scfi: 0.944,
          type: "node",
        },
        {
          id: 2,
          activity: "Truck Transportation from Guanajuato, Mexico to Monterrey, Nuevo Leon",
          travelDwellTimeHrs: 11.31,
          freeFlowHrs: 8.94,
          scfi: 0.789,
          type: "transportation",
        },
        {
          id: 3,
          activity: "Monterrey, Nuevo Leon - Processing/Packaging (corn to flour)",
          travelDwellTimeHrs: 289.02,
          freeFlowHrs: 108.48,
          scfi: 0.375,
          type: "node",
        },
        {
          id: 4,
          activity: "Truck Transportation from Monterrey, Nuevo Leon to Laredo, Tx",
          travelDwellTimeHrs: 5.74,
          freeFlowHrs: 3.60,
          scfi: 0.627,
          type: "transportation",
        },
        {
          id: 5,
          activity: "Intermodal Node Laredo, Tx - Warehousing/Distribution Center",
          travelDwellTimeHrs: 288.48,
          freeFlowHrs: 286.08,
          scfi: 0.990,
          type: "node",
        },
        {
          id: 6,
          activity: "Rail Transportation from Laredo, Tx to San Antonio",
          travelDwellTimeHrs: 28.86,
          freeFlowHrs: 25.00,
          scfi: 0.866,
          type: "transportation",
        },
        {
          id: 7,
          activity: "San Antonio, Tx - Warehousing/Distribution Center",
          travelDwellTimeHrs: 378.91,
          freeFlowHrs: 373.04,
          scfi: 0.985,
          type: "node",
        },
        {
          id: 8,
          activity: "Truck transportation from warehousing/distribution center in San Antonio, Tx to aelect convience store",
          travelDwellTimeHrs: 1.61,
          freeFlowHrs: 0.19,
          scfi: 0.120,
          type: "transportation",
        },
        {
          id: 9,
          activity: "Total",
          travelDwellTimeHrs: 1741.43,
          freeFlowHrs: 1504.03,
          scfi: 0.864,
          type: "total",
        },
      ],
      scfiContributionsPercent: [
        {
          node: 97.3,
          transportaion: { maritime: 0.0, rail: 1.6, truck: 1.1 },
        },
      ],
    },
    {
      option: "Truck from Laredo to San Antonio, Tx",
      webMap: "TBD",
      summaryData: [
        {
          id: 1,
          activity: "Guanajuato, Mexico - Harvest Corn",
          travelDwellTimeHrs: 763.68,
          freeFlowHrs: 721.20,
          scfi: 0.944,
          type: "node",
        },
        {
          id: 2,
          activity: "Truck Transportation from Guanajuato, Mexico to Monterrey, Nuevo Leon",
          travelDwellTimeHrs: 11.31,
          freeFlowHrs: 8.94,
          scfi: 0.789,
          type: "transportation",
        },
        {
          id: 3,
          activity: "Monterrey, Nuevo Leon - Processing/Packaging (corn to flour)",
          travelDwellTimeHrs: 289.02,
          freeFlowHrs: 108.48,
          scfi: 0.375,
          type: "node",
        },
        {
          id: 4,
          activity: "Truck Transportation from Monterrey, Nuevo Leon to Laredo, Tx",
          travelDwellTimeHrs: 5.74,
          freeFlowHrs: 3.60,
          scfi: 0.627,
          type: "transportation",
        },
        {
          id: 5,
          activity: "Intermodal Node Laredo, Tx - Warehousing/Distribution Center",
          travelDwellTimeHrs: 288.48,
          freeFlowHrs: 286.08,
          scfi: 0.990,
          type: "node",
        },
        {
          id: 6,
          activity: "Truck Transportation from Laredo to San Antonio, Tx",
          travelDwellTimeHrs: 2.67,
          freeFlowHrs: 2.49,
          scfi: 0.933,
          type: "transportation",
        },
        {
          id: 7,
          activity: "San Antonio, Tx - warehousing/distribution center",
          travelDwellTimeHrs: 378.91,
          freeFlowHrs: 373.04,
          scfi: 0.985,
          type: "node",
        },
        {
          id: 8,
          activity: "Truck transportation from warehousing/distribution center in San Antonio, Tx to aelect convience store",
          travelDwellTimeHrs: 1.61,
          freeFlowHrs: 0.19,
          scfi: 0.120,
          type: "transportation",
        },
        {
          id: 9,
          activity: "Total",
          travelDwellTimeHrs: 1767.61,
          freeFlowHrs: 1526.53,
          scfi: 0.864,
          type: "total",
        },
      ],
      scfiContributionsPercent: [
        {
          node: 98.8,
          transportaion: { maritime: 0.0, rail: 0.0, truck: 1.2 },
        },
      ],
    },
  ],
},
{
  caseStudy: "Mineral products (non-metallic)",
  options: [
    {
      option: "Truck from Tucson, Arizona to Dallas-Fort Worth (DFW), Tx",
      webMap: "TBD",
      summaryData: [
        {
          id: 1,
          activity: "Hermosillo, Sonora, Mexico - raw material extraction (Limestone)",
          travelDwellTimeHrs: 49.42,
          freeFlowHrs: 45.13,
          scfi: 0.913,
          type: "node",
        },
        {
          id: 2,
          activity: "Hermosillo, Sonora, Mexico - processing plant (limestone to cement)",
          travelDwellTimeHrs: 58.76,
          freeFlowHrs: 12.96,
          scfi: 0.221,
          type: "node",
        },
        {
          id: 3,
          activity: "Truck Transportation from Sonora, Mexico to Mariposa Nogales Border Crossing",
          travelDwellTimeHrs: 6.20,
          freeFlowHrs: 3.80,
          scfi: 0.614,
          type: "transportation",
        },
        {
          id: 4,
          activity: "Truck Transportation from Mariposa Nogakes Truck Border to Tucson, Arizona",
          travelDwellTimeHrs: 1.11,
          freeFlowHrs: 0.93,
          scfi: 0.836,
          type: "transportation",
        },
        {
          id: 5,
          activity: "Tucson, Arizona - Warehousing",
          travelDwellTimeHrs: 587.47,
          freeFlowHrs: 555.29,
          scfi: 0.945,
          type: "node",
        },
        {
          id: 6,
          activity: "Truck Transportation from Tucson, Arizona to DFW",
          travelDwellTimeHrs: 13.93,
          freeFlowHrs: 12.68,
          scfi: 0.911,
          type: "transportation",
        },
        {
          id: 7,
          activity: "Truck Transportation from DFW to Fort Worth select company store",
          travelDwellTimeHrs: 0.18,
          freeFlowHrs: 0.16,
          scfi: 0.894,
          type: "transportation",
        },
        {
          id: 8,
          activity: "Total",
          travelDwellTimeHrs: 717.07,
          freeFlowHrs: 630.95,
          scfi: 0.880,
          type: "total",
        },
      ],
      scfiContributionsPercent: [
        {
          node: 93.3,
          transportaion: { maritime: 0.0, rail: 4.7, truck: 2.0 },
        },
      ],
    },
    {
      option: "Rail from Tucson, Arizona to Dallas-Fort Worth (DFW), Tx",
      webMap: "TBD",
      summaryData: [
        {
          id: 1,
          activity: "Hermosillo, Sonora, Mexico - raw material extraction (Limestone)",
          travelDwellTimeHrs: 49.42,
          freeFlowHrs: 45.13,
          scfi: 0.913,
          type: "node",
        },
        {
          id: 2,
          activity: "Hermosillo, Sonora, Mexico - processing plant (limestone to cement)",
          travelDwellTimeHrs: 58.76,
          freeFlowHrs: 12.96,
          scfi: 0.221,
          type: "node",
        },
        {
          id: 3,
          activity: "Truck Transportation from Sonora, Mexico to Mariposa Nogales Border Crossing",
          travelDwellTimeHrs: 6.20,
          freeFlowHrs: 3.80,
          scfi: 0.614,
          type: "transportation",
        },
        {
          id: 4,
          activity: "Truck Transportation from Mariposa Nogakes Truck Border to Tucson, Arizona",
          travelDwellTimeHrs: 1.11,
          freeFlowHrs: 0.93,
          scfi: 0.836,
          type: "transportation",
        },
        {
          id: 5,
          activity: "Tucson, Arizona - Warehousing",
          travelDwellTimeHrs: 587.47,
          freeFlowHrs: 555.29,
          scfi: 0.945,
          type: "node",
        },
        {
          id: 6,
          activity: "Rail Transportation from Tucson, Arizona to DFW",
          travelDwellTimeHrs: 77.50,
          freeFlowHrs: 53.30,
          scfi: 0.688,
          type: "transportation",
        },
        {
          id: 7,
          activity: "DFW - rail station dwelling",
          travelDwellTimeHrs: 23.40,
          freeFlowHrs: 19.30,
          scfi: 0.825,
          type: "node",
        },
        {
          id: 8,
          activity: "Total",
          travelDwellTimeHrs: 803.86,
          freeFlowHrs: 690.71,
          scfi: 0.859,
          type: "total",
        },
      ],
    scfiContributionsPercent: [
        {
          node: 96.7,
          transportaion: { maritime: 0, rail: 0, truck: 3.3 },
        },
      ],
    },
       ],
},
{
  caseStudy: "Chemicals",
  options: [
    {
      option: "Truck/rail from Canada to Woodlands, Tx",
      webMap: "TBD",
      summaryData: [
        {
          id: 1,
          activity: "Truck Transportation - Canada",
          travelDwellTimeHrs: 0.6,
          freeFlowHrs: 0.6,
          scfi: 1.00,
          type: "transportation",
        },
        {
          id: 2,
          activity: "Canada intermodal terminal",
          travelDwellTimeHrs: 20.48,
          freeFlowHrs: 15.70,
          scfi: 0.767,
          type: "node",
        },
        {
          id: 3,
          activity: "Rail Transportation from Canada to Houston, Tx",
          travelDwellTimeHrs: 135.26,
          freeFlowHrs: 122.00,
          scfi: 0.902,
          type: "transportation",
        },
        {
          id: 4,
          activity: "Houston, Tx intermodal terminal",
          travelDwellTimeHrs: 549.60,
          freeFlowHrs: 198.25,
          scfi: 0.361,
          type: "node",
        },
        {
          id: 5,
          activity: "Truck Transportation from Houston, Tx to Woodlands, Tx",
          travelDwellTimeHrs: 1.22,
          freeFlowHrs: 1.19,
          scfi: 0.975,
          type: "transportation",
        },
        {
          id: 6,
          activity: "Woodlands, Tx Wholesaler",
          travelDwellTimeHrs: 1029.74,
          freeFlowHrs: 1002.48,
          scfi: 0.974,
          type: "node",
        },
        {
          id: 7,
          activity: "Total",
          travelDwellTimeHrs: 1736.90,
          freeFlowHrs: 1340.22,
          scfi: 0.772,
          type: "total",
        },
      ],
      scfiContributionsPercent: [
        {
          node: 90.8,
          transportaion: { maritime: 0.0, rail: 9.1, truck: 0.1 },
        },
      ],
    },
       ],
},
{
  caseStudy: "Plastics and Rubber",
  options: [
    {
      option: "Sea/truck",
      webMap: "TBD",
      summaryData: [
        {
          id: 1,
          activity: "Ship Transportation of raw material from Mexico (Veracruz, Veracruz) to Port of Houston, TX",
          travelDwellTimeHrs: 129.60,
          freeFlowHrs: 120.00,
          scfi: 0.926,
          type: "transportation",
        },
        {
          id: 2,
          activity: "Port of Houston storage",
          travelDwellTimeHrs: 653.87,
          freeFlowHrs: 641.86,
          scfi: 0.982,
          type: "node",
        },
        {
          id: 3,
          activity: "Truck Transportation from Port of Houston to Houston, Tx",
          travelDwellTimeHrs: 0.11,
          freeFlowHrs: 0.11,
          scfi: 1.000,
          type: "transportation",
        },
        {
          id: 4,
          activity: "Plastic Manufacturer in Houston, TX",
          travelDwellTimeHrs: 178.68,
          freeFlowHrs: 173.52,
          scfi: 0.971,
          type: "node",
        },
        {
          id: 5,
          activity: "Truck Transportation from Plastic Manufacturer in Houston, TX to point-of-entry (POE) at Laredo Tx",
          travelDwellTimeHrs: 5.34,
          freeFlowHrs: 5.08,
          scfi: 0.951,
          type: "transportation",
        },
        {
          id: 6,
          activity: "POE at Laredo, Tx",
          travelDwellTimeHrs: 0.13,
          freeFlowHrs: 0.02,
          scfi: 0.113,
          type: "node",
        },
        {
          id: 7,
          activity: "Truck Transportation from POE at Laredo, Tx to Mexico City industrial chemical wholesaler",
          travelDwellTimeHrs: 18.83,
          freeFlowHrs: 14.42,
          scfi: 0.765,
          type: "transportation",
        },
        {
          id: 8,
          activity: "Mexico City industrial chemical wholesaler",
          travelDwellTimeHrs: 240.61,
          freeFlowHrs: 108.68,
          scfi: 0.452,
          type: "node",
        },
        {
          id: 9,
          activity: "Total",
          travelDwellTimeHrs: 1227.05,
          freeFlowHrs: 1063.66,
          scfi: 0.867,
          type: "total",
        },
      ],
      scfiContributionsPercent: [
        {
          node: 87.5,
          transportaion: { maritime: 10.5, rail: 0, truck: 2.0 },
        },
      ],
    },
       ],
},
{
  caseStudy: "Machine Metals",
  options: [
    {
      option: "Truck",
      webMap: "TBD",
      summaryData: [
        {
          id: 1,
          activity: "Truck transportation of raw material from Miguel Hidalgo, Mexico City, Mexico to Monterrey, Mexico",
          travelDwellTimeHrs: 49.84,
          freeFlowHrs: 11.57,
          scfi: 0.232,
          type: "transportation",
        },
        {
          id: 2,
          activity: "Monterrey, Mexico machine metal manufacturing",
          travelDwellTimeHrs: 290.96,
          freeFlowHrs: 116.22,
          scfi: 0.399,
          type: "node",
        },
        {
          id: 3,
          activity: "Truck Transportation from Monterrey, Mexico machine metal manufacturing to border point-of-entry (POE)",
          travelDwellTimeHrs: 6.01,
          freeFlowHrs: 4.10,
          scfi: 0.681,
          type: "transportation",
        },
        {
          id: 4,
          activity: "Border POE",
          travelDwellTimeHrs: 0.99,
          freeFlowHrs: 0.22,
          scfi: 0.218,
          type: "node",
        },
        {
          id: 5,
          activity: "Truck Transportation from Border POE to Laredo, Tx warehousing/distribution center",
          travelDwellTimeHrs: 0.19,
          freeFlowHrs: 0.10,
          scfi: 0.529,
          type: "transportation",
        },
        {
          id: 6,
          activity: "Laredo, Tx warehousing/distribution center",
          travelDwellTimeHrs: 825.81,
          freeFlowHrs: 803.02,
          scfi: 0.972,
          type: "node",
        },
        {
          id: 7,
          activity: "Truck Transportation from Laredo warehousing/distribution center to Houston, Tx machine metal company",
          travelDwellTimeHrs: 5.47,
          freeFlowHrs: 4.79,
          scfi: 0.875,
          type: "transportation",
        },
        {
          id: 8,
          activity: "Houston machine metal company - assembly, manufacturing ",
          travelDwellTimeHrs: 2649.80,
          freeFlowHrs: 26.80,
          scfi: 0.243,
          type: "node",
        },
        {
          id: 9,
          activity: "Truck Transportation from Houston machine metal company to wholesale",
          travelDwellTimeHrs: 0.32,
          freeFlowHrs: 0.40,
          scfi: 1.236,
          type: "transportation",
        },
        {
          id: 10,
          activity: "Wholesale",
          travelDwellTimeHrs: 825.81,
          freeFlowHrs: 803.02,
          scfi: 0.972,
          type: "node",
        },
        {
          id: 11,
          activity: "Total",
          travelDwellTimeHrs: 4655.20,
          freeFlowHrs: 1770.23,
          scfi: 0.380,
          type: "total",
        },
      ],
      scfiContributionsPercent: [
        {
          node: 98.7,
          transportaion: { maritime: 0, rail: 0, truck: 1.3 },
        },
      ],
    },
       ],
},

];

export const railDwellTimes = [
  {
    id: 1,
    name: "BNSF Alliance",
    coordinates: [-97.349597, 32.990009],
    averageTerminalDwellTimeHours: 33.3,
    data: [28.9,
      30.2,
      30.1,
      29.7,
      37.4,
      29.1,
      38.1,
      31.1,
      34.0,
      31.2,
      33.7,
      40.8,
      38.0]
  },
  {
    id: 2,
    name: "Union Pacific Fort Worth",
    coordinates: [-97.383603, 32.719938],
    averageTerminalDwellTimeHours: 22.7,
    data: [21.3,
      21.1,
      21.8,
      21.2,
      25.1,
      22.4,
      24.6,
      22.8,
      22.8,
      22.8,
      23.8,
      22.3,
      22.9]
  },
  {
    id: 3,
    name: "Union Pacifc Englewood",
    coordinates: [-95.298734, 29.790558],
    averageTerminalDwellTimeHours: 21.5,
    data: [21.5,
      17.9,
      20.0,
      21.5,
      22.3,
      19.6,
      21.9,
      22.2,
      25.5,
      23.3,
      20.9,
      21.4,
      20.9]
  },
  {
    id: 4,
    name: "Union Pacific Santa Teresa",
    coordinates: [-106.739309, 31.901014],
    averageTerminalDwellTimeHours: 20.1,
    data: [19.3, 20.4, 16.0, 16.7, 18.6, 16.5, 22.7, 19.5, 23.8, 24.2, 27.1, 19.8, 16.6]
  },
];

export const airportDelay = [
  {
    id: 1,
    name: "Alliance Fort Worth",
    coordinates: [-97.319, 32.99],
    averageArrrivalsDelayMinutes: 15,
    averageDeparturesDelayMinutes: 13,
    monthlyData: [
      {
        month: "Jan.-2023",
        averageArrrivalsDelayMinutes: 32,
        averageDeparturesDelayMinutes: 54,
      },
      {
        month: "Feb.-2023",
        averageArrrivalsDelayMinutes: 16,
        averageDeparturesDelayMinutes: 12,
      },
      {
        month: "Mar.-2023",
        averageArrrivalsDelayMinutes: 13,
        averageDeparturesDelayMinutes: 10,
      },
    ],
  },
];

export const countrywideAirportDelay=
[
 

  {id:1, city: "Hebron", airportCode: "KCVG", name: "Cincinnati/Northern Kentucky International Airport", link: 'https://flightaware.com/resources/airport/KCVG', long: -84.668, lat: 39.049, arrivals: {totalDelay:2254, totalFlights: 55, averageDelay: 41}, departures: {totalDelay:2905, totalFlights: 66, averageDelay: 44}},
  {id:2, city: "Allentown", airportCode: "KABE", name: "Lehigh Valley Intl", link: 'https://flightaware.com/resources/airport/KABE', long:-75.440, lat: 40.652, arrivals: {totalDelay: 1500, totalFlights: 51, averageDelay: 29}, departures: {totalDelay: 1755, totalFlights: 53, averageDelay: 33}},
  {id:3, city: "Omaha", airportCode: "KOMA", name: "Eppley Airfield", link:' https://flightaware.com/resources/airport/KOMA', long: -95.894, lat: 41.303, arrivals: {totalDelay: 2574, totalFlights: 47, averageDelay: 55}, departures: {totalDelay: 1729, totalFlights: 52, averageDelay: 33}},
  {id:4, city: "Wichita", airportCode: "KICT", name: "Wichita Eisenhower", link: 'https://flightaware.com/resources/airport/KICT', long: -97.433, lat: 37.650, arrivals: {totalDelay: 1701, totalFlights: 46, averageDelay: 37}, departures: {totalDelay: 2836, totalFlights: 59, averageDelay: 48}},
  {id:5, city: "Des Moines", airportCode: "KDSM", name: "Des Moines Intl", link: 'https://flightaware.com/resources/airport/KDSM', long: -93.663, lat: 41.534, arrivals: {totalDelay: 3205, totalFlights: 44, averageDelay: 73}, departures: {totalDelay: 2161, totalFlights: 54, averageDelay: 40}},
  {id:6, city: "Houston", airportCode: "KIAH", name: "Houston Bush Int'ctl", link: 'https://flightaware.com/resources/airport/KIAH', long: -95.341, lat: 29.984, arrivals: {totalDelay: 2966, totalFlights: 44, averageDelay: 67}, departures: {totalDelay: 1081, totalFlights: 38, averageDelay: 28}},
  {id:7, city: "Los Angeles", airportCode: "KLAX", name: "Los Angeles Intl", link: 'https://flightaware.com/resources/airport/KLAX', long: -118.408, lat: 33.942, arrivals: {totalDelay: 738, totalFlights: 43, averageDelay: 17}, departures: {totalDelay: 274, totalFlights: 8, averageDelay: 34}},
  {id:8, city: "Mobile", airportCode: "KBFM", name: "Mobile Intl", link: 'https://flightaware.com/resources/airport/KBFM', long: -88.068, lat: 30.627, arrivals: {totalDelay: 2927, totalFlights: 42, averageDelay: 70}, departures: {totalDelay: 1930, totalFlights: 47, averageDelay: 41}},
  {id:9, city: "Lakeland", airportCode: "KLAL", name: "Lakeland Linder Intl", link:' https://flightaware.com/resources/airport/KLAL', long: -82.019, lat: 27.988, arrivals: {totalDelay: 762, totalFlights: 42, averageDelay: 18}, departures: {totalDelay: 99, totalFlights: 3, averageDelay: 33}},
  {id:10, city: "Seattle", airportCode: "KSEA", name: "Seattle-Tacoma Intl", link: 'https://flightaware.com/resources/airport/KSEA', long: -122.312, lat: 47.450, arrivals: {totalDelay: 1405, totalFlights: 40, averageDelay: 35}, departures: {totalDelay: 2135, totalFlights: 61, averageDelay: 35}},
  {id:11, city: "San Francisco", airportCode: "KSFO", name: "San Francisco Int'l", link: 'https://flightaware.com/resources/airport/KSFO', long: -122.375, lat: 37.619, arrivals: {totalDelay: 1410, totalFlights: 40, averageDelay: 35}, departures: {totalDelay: 1628, totalFlights: 56, averageDelay: 29}},
  {id:12, city: "Austin", airportCode: "KAUS", name: "Austin-Bergstrom Intl", link: 'https://flightaware.com/resources/airport/KAUS', long: -97.670, lat: 30.195, arrivals: {totalDelay: 1037, totalFlights: 40, averageDelay: 26}, departures: {totalDelay: 929, totalFlights: 15, averageDelay: 62}},
  {id:13, city: "Phoenix", airportCode: "KPHX", name: "Phoenix Sky Harbor Intl", link:'https://flightaware.com/resources/airport/KPHX', long: -112.012, lat: 33.434, arrivals: {totalDelay: 874, totalFlights: 40, averageDelay: 22}, departures: {totalDelay: 1255, totalFlights: 47, averageDelay: 27}},
  {id:14, city: "Manchester", airportCode: "KMHT", name: "Manchester Boston Rgnl", link: 'https://flightaware.com/resources/airport/KMHT', long: -71.436, lat: 42.933, arrivals: {totalDelay: 27, totalFlights: 1, averageDelay: 27}, departures: {totalDelay: 2580, totalFlights: 56, averageDelay: 46}},
  {id:15, city: "Chicago/Rockford", airportCode: "KRFD", name: "Chicago/Rockford Intl", link: 'https://flightaware.com/resources/airport/KRFD', long: -89.097, lat: 42.195, arrivals: {totalDelay: 92, totalFlights: 2, averageDelay: 46}, departures: {totalDelay: 1732, totalFlights: 55, averageDelay: 31}},
  {id:16, city: "Las Vegas", airportCode: "KLAS", name: "Harry Reid Intl", link: 'https://flightaware.com/resources/airport/KLAS', long: -115.152, lat: 36.080, arrivals: {totalDelay: 625, totalFlights: 27, averageDelay: 23}, departures: {totalDelay: 1109, totalFlights: 53, averageDelay: 21}},
  {id:17, city: "Portland", airportCode: "KPDX", name: "Portland Intl", link:' https://flightaware.com/resources/airport/KPDX', long: -122.597, lat: 45.589, arrivals: {totalDelay: 14, totalFlights: 1, averageDelay: 14}, departures: {totalDelay: 1555, totalFlights: 51, averageDelay: 30}},
  {id:18, city: "Lubbock", airportCode: "KLBB", name: "Lubbock Smith Intl", link: 'https://flightaware.com/resources/airport/KLBB', long: -101.821, lat: 33.664, arrivals: {totalDelay: 2466, totalFlights: 39, averageDelay: 63}, departures: {totalDelay: 1502, totalFlights: 45, averageDelay: 33}},
  {id:19, city: "Toledo", airportCode: "KTOL", name: "Eugene F Kranz Toledo Express", link: 'https://flightaware.com/resources/airport/KTOL', long: -83.808, lat: 41.587, arrivals: {totalDelay: 1313, totalFlights: 34, averageDelay: 39}, departures: {totalDelay: 818, totalFlights: 42, averageDelay: 19}},
  {id:20, city: "Minneapolis", airportCode: "KMSP", name: "Minneapolis/St Paul Intl", link: 'https://flightaware.com/resources/airport/KMSP', long: -93.222, lat: 44.882, arrivals: {totalDelay: 211, totalFlights: 10, averageDelay: 21}, departures: {totalDelay: 988, totalFlights: 42, averageDelay: 24}},
  {id:21, city: "Baltimore", airportCode: "KBWI", name: "Baltimore/Washington Intl", link: 'https://flightaware.com/resources/airport/KBWI', long: -76.669, lat: 39.176, arrivals: {totalDelay: 24, totalFlights: 1, averageDelay: 24}, departures: {totalDelay: 839, totalFlights: 40, averageDelay: 21}},
  {
    id: 22,
    city: "Fort Worth",
    airportCode:"",
    name: "Alliance Fort Worth",
    link: 'https://flightaware.com/resources/airport/KABE',
    long: -97.319,
    lat: 32.99, 
    arrivals: {totalDelay:2254, totalFlights: 55, averageDelay: 15},
     departures: {totalDelay:2905, totalFlights: 66, averageDelay: 13}
      },  
]
  

  
