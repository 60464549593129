import React, { useState, useEffect } from "react";

import {
    Grid,
    Box,
    Typography,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import Table from 'react-bootstrap/Table'
import '../ViewPage/view.css';



function Resiliency(props) {
    const gridStyle =
    {
        width: 'auto',
        height: 'auto',
        backgroundColor: '',
        marginTop: "140px",
        textAlign: 'justify'
    }

    return (

        <Grid container spacing={0} className="freight-resiliency" sx={{ ...gridStyle }}>
            <Grid item xs={12}>
                <h4>Resilience of the Texas Highway Freight Network to Road </h4>
                <p className="stepDescription">
                    This document describes the methodology and results of a study on the resilience of the Texas Highway Freight Network to road closures caused by natural events such
                    as wildfire and flooding or human generated events such as construction projects or accidents.
                    The goal of this study is to
                    <br />
                    <ol>
                        <li>Assess the location, frequency and duration of road closures,</li>
                        <li>
                            Assess how road closures affect the functioning of the Texas Highway Freight Network (THFN), and
                        </li>
                        <li>
                            Provide insights into how to increase the resilience of the freight network to road closures.
                        </li>
                    </ol>
                </p>
            </Grid>

            <Grid item xs={12}>
                <h4>Resilience and Freight Transportation</h4>
                <p className="stepDescription">
                    The Federal Highways Administration currently define resilience as
                    <b> the ability to anticipate, prepare for, and adapt to changing conditions and withstand, respond to, and recover rapidly from disruptions.</b>
                </p>
            </Grid>

            <Grid item xs={12}>
                <h4>Analysis of Texas Road Closure Data</h4>
                <p className="stepDescription">
                    This section provides a summary of road closures on Texas roads from January 01, 2014, to December 31, 2021, as recorded in TxDOT’s Highway Condition Reporting System (HCRS).

                </p>
                <h5><em>Background to the HCRS</em></h5>
                <p className="stepDescription">
                    According to the Highway Condition Reporting System Manual, the goal of the HCRS is to “provide accurate and timely highway condition information to the public and to the State Operation Center for Emergency” (referenced at http://onlinemanuals.txdot.gov/txdotmanuals/mmt/highway_condition_reporting_system.htm) . The manual states that the information in the HCRS is used by the Governor’s Office of Emergency Management, FHWA, Federal Emergency Agency (FEMA) and law enforcement agencies during emergency conditions.
                </p>
                <h5><em>Preparing the HCRS data</em></h5>
                <p className="stepDescription">
                <ol>
                    <li>
                        All duplicate road closure entries were identified in the data set, and a new consolidated entry was created with the most up to date information on the road closure. Multiple road closures with the same descriptions and locations are common in the HCRS database because operators of the system are encouraged to update the status of road closures regularly. After this step the database contained all unique road closures.
                    </li>
                    <li>
                        The researchers identified 10 separate road closure categories by browsing through the free text descriptions of closures and the TYPE data field present in the database.
                        The purpose of this step was to categorize closures based on a primary cause that is relevant to transportation studies (for example accident, construction, fire, dust). In contrast, the TYPE field in the database contains information on both the cause of closures and the consequence of closures (e.g., whether it is a road closure, a lane closure or advisory). Classifying the closures into new categories also provided an opportunity to extract extra information from the text descriptions on the cause of each closure.
                    </li>
                    <li>
                        The researchers used Natural Language Processing (NLP) techniques to extract the road closure category information from the database.  The NLP was also used to populate additional fields that flag whether the closure affected main lanes or frontage roads only, and whether the closure record was an advisory, a lane closure, or a full road closure.
                    </li>
                    <li>
                        Each road closure record was matched to a GIS file describing the Texas Highway Freight Network to determine whether the road closure occurred on the freight network or non-freight network. The research team used the Texas National Highway Freight Network GIS data downloaded from the TxDOT Open Data Portal (https://gis-txdot.opendata.arcgis.com/datasets/TXDOT::texas-national-highway-freight-network/) for this mapping.
                    </li>
                    <li>
                        The researcher’s quality assured each road closure record and tested the accuracy of the NLP methods.
                    </li>
                </ol>
                </p>
                <br />
                <Box sx={{ paddingLeft: 10, paddingRight: 10 }}>
                    <Table id="roadClosure" bordered hover>
                        
                        <thead>
                            <tr>
                                <th colSpan={1}>Reason for closure</th>
                                <th colSpan={1}>Examples of text descriptions entered into the HCRS</th>
                            </tr>

                        </thead>
                        <tbody>

                            <tr>
                                <td>Accident</td>
                                <td>
                                    &#8902; Travel lanes reduced to one lane. Motorists should expect delays. Accident on Loop 304, in front of Hospital, both southbound lanes closed, northbound lanes are open and being used for northbound and southbound traffic. Use caution.<br></br>

                                </td>
                            </tr>
                            <tr>
                                <td >Construction</td>
                                <td>
                                    &#8902; Pilot car in use. Watch for flag personnel directing traffic. Motorists should expect delays.<br></br>

                                </td>
                            </tr>

                            <tr>
                                <td >Damage</td>
                                <td>
                                    &#8902;The roadway is closed due to damage. FM 1379 is closed near the FM 3095 intersection due to debris on the road from a severe storm. Lanes will be opened once crews can remove debris from the roadway.<br></br>

                                </td>
                            </tr>
                            <tr>
                                <td >Dust</td>
                                <td>
                                    &#8902;The roadway is closed due to low visibility from blowing sand. SH 44 closed from FM 70 to CR 105. Use FM 1931 (Flournoy) to FM 665.

                                </td>
                            </tr>
                            <tr>
                                <td >Fire</td>
                                <td>
                                    &#8902;Travel lanes reduced to one lane. Vehicle fire limiting traffic to one lane on US 84 between CR115 and CR125. Right lane closed.

                                </td>
                            </tr>

                            <tr>
                                <td >Flood</td>
                                <td>
                                    &#8902;Low water crossings are flooding. Water over roadway.

                                </td>
                            </tr>


                            <tr>
                                <td >Fog</td>
                                <td>
                                    &#8902;Low visibility due to fog. Motorists should expect delays. Low visibility due to fog. Motorist should expect delays.

                                </td>
                            </tr>

                            <tr>
                                <td >Ice and Winter Weather</td>
                                <td>
                                    &#8902;Patches of ice and snow present, travel with caution. Motorists should expect delays.


                                </td>
                            </tr>

                            <tr>
                                <td >Other-Unclassified</td>
                                <td>
                                    &#8902;Frontage road only, main lanes are open.


                                </td>
                            </tr>
                            <tr>
                                <td >Other-Visibility</td>
                                <td>
                                    &#8902;Low visibility due to smoke. Watch for flag personnel directing traffic.


                                </td>
                            </tr>
                        </tbody>
                        <caption>
                            <h6>Table 1. Road closure categories used to classify the cause of each road closure for this study with examples of text descriptions typical of each category.</h6>
                        </caption>
                    </Table>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <h5><em>Analysis Results</em></h5>
                <p className="stepDescription">
                    Processing the original HCRS database yielded a GIS database containing unique road closure records between January 01, 2014, to December 31, 2021, and with fields describing:
                    <ol>
                        <li>
                            The reason for the closure (e.g., flood, accident, etc. specified in Table 1).
                        </li>
                        <li>
                            The consequence or road closure type - one of:
                            <ul style={{ listStyleType: "none" }}>
                                <li>
                                    Advisory - the road is operational but there is a travel warning (e.g., drive carefully).
                                </li>
                                <li>
                                    Lane outage - at least one lane is closed to traffic.
                                </li>
                                <li>
                                    Fully closed – all lanes in one or both directions are closed.
                                </li>
                            </ul>
                        </li>
                        <li>
                            The direction of travel that the closure affected (one direction or both).
                        </li>
                        <li>
                            Whether the outage was on the Texas National Highway Freight Network or elsewhere on the TxDOT system.
                        </li>
                    </ol>
                </p>
                <Grid container>
                    <Grid item xs={12} lg={5} >
                        <img alt="ff" height="700px" src={require("./SpatialDistributionOfClosures.png")} />
                        <h6>Figure 1. Spatial distribution of road closures of different causes. The maps show all outages for both freight and non-freight networks. The colored 1.55 x 1.55-mile cells show the number of road closures that occurred in each location divided by the number of roads at that location.</h6>
                    </Grid>

                    <Grid item xs={12} lg={1}></Grid>

                    <Grid item xs={12} lg={6}>
                        <img alt="ff" height="700px" src={require("./monthlyOutages.png")} />
                        <h6>Figure 2. Monthly number of outages in non-freight and freight network for categories with high severity from January 01, 2014, to December 31, 2021. </h6>
                    </Grid>

                    <Grid item>
                        <p className="stepDescription">
                            Figure 1 shows  the spatial distribution of different causes of road closures across Texas. This map was created by dividing Texas’ area into 2500 m x 2500 m cells (approximately 1.55 mi x 1.55 mi) and counting the number of road closures (of different causes) in each cell, and then dividing this quantity by the number of roads (for either the freight or non-freight network) in each grid cell. This step was performed to normalize the road closures – in other words to account for the fact that areas of high road density are innately more likely to incur closures than areas of low road density.
                        </p>
                        <p className="stepDescription">
                            Figure 2 shows time series of the number of road closures, by the cause of closure, for the analysis period. In these graphs the road closures have been aggregated by calendar month. Patterns of accident and construction closures tend to be less variable through time than other causes (e.g., floods), although seasonal reductions in the number of accident and construction closures can be observed around Christmas (especially for construction). Over time, there is a trend of increasing number of closures caused by accidents on the freight network (although the trend is not as detectable on the non-freight network). It is important to note that this trend is not necessarily indicative of an increasing number of accidents, and instead may be due to an increase in the probability that such accidents are reported to the HCRS.
                        </p>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <h4>Measuring the Effect of Road Closures on the Freight Network</h4>
                <p className="stepDescription">
                    The research team investigated the effect of these road closures on the function of the freight network. These analyses are done in two ways.

                </p>
                <h5>Direct method</h5>
                <p className="stepDescription">
                    The first, most direct method estimates the total number of vehicles that would ordinarily use the closed roadway section and the normal travel time through the road section. Then, using information from the road closure database, the research team estimated the loss of service caused by the road closure based on the direction(s) of travel affected, and whether it was a lane or full directional closure. Herein, this is referred to as the direct method.

                </p>
                <h5>Network method</h5>
                <p className="stepDescription">
                    The research team also used a second method of estimating road closure impacts herein referred to as the network method. The network method accounts for the fact that, if there is some forewarning of a closed road, vehicles may be able to reroute to avoid being directly delayed by a closure. In this method, the researchers assume a set of multiple origin and destination points on the broader freight network and use a custom routing tool to calculate the shortest path from each origin to each destination, along with travel times and travel distances.
                </p>
                <Grid container >
                    <Grid item sm={12} md={6.5} lg={6.5} xl={4}>
                        <img alt="ff" height="600px" src={require("./NetworkAnalysismethod.png")} />
                        <h6>
                            Figure 3. Illustration of the network analysis method.</h6></Grid>
                    <Grid item sm={12} md={5.5} lg={5.5} xl={8}>
                        <p className="stepDescription">The thick red line(Figure 3) illustrates a road closure on the network (the full network is illustrated on image by the light grey lines. The inner and outer blue circles define two areas of interest centered upon the road closure. In this case they are defined to be 60 and 100 km (~37 and 62 miles) from the road closure. A minimum set of origin and destination points are selected from freight network roads falling between the outer and inner areas of interest. These are illustrated by the yellow highlighted links. Shortest path distances are then calculated for each origin and destination pair (illustrated by the thick grey paths) and those that pass through the road closure noted. Finally, the road closure is simulated, and the shortest paths calculated for each origin-destination pair that had an original path through the closure (shown by the red lines). The impact of the road closure is calculated by comparing the travel times of the original shortest paths with and without the road closure in place.

                        </p>
                    </Grid>
                </Grid>
            </Grid>

            <a href="./Resilience of Texas Freight Network to Road Closures 3.3.pdf" download><h5>Download PDF for more infomation</h5></a>

        </Grid>

    );
}
export default Resiliency;
