const elpaso_docs={
  "title":"El Paso",
  "steps": 
    [
      {
        "heading": " A Contour Map for Freight Traveling from Downtown El Paso.",
        "title": "Step 1.Review Contour Maps.",
        "description": " Contour Maps show regional travel times for a selected time of the day to or from a freight generator, such as the Downtown area of El Paso (see Figure 1). The most congested corridors have a shorter distance from the generator to the contour. For the weekday 5 p.m. contour map, you notice the distance traveled for freight heading west—on I-10, north on US54, and east on US180—is shorter than on I-10heading east, indicating more truck congestion heading northon US54 or west on I-10 from downtown. You decide to take acloser look."
      },
      {
        "heading":"Heat Map of Truck Patterns at El Paso",
        "title": "Step 2. Analyze Heat Maps:",
        "description": "Heat Maps use GPS data to show vehicle paths (trips) crossing a selected segment during a certain time period, revealing problematic areas. Because origin/destination data is necessary to conduct this analysis, this Freight Fluidity Analysis method cannot be performed in this area."
      },
      {
        "title": "Step 3. Check Travel-Time Traces: ",
        "description": " Check Travel-Time Traces: Travel-time traces show the average travel time and the 95th percentile travel time for a length of roadway beginning at various times of day. This Freight Fluidity Analysis method has not yet been performed in this area."
      },
      {
        "title": "Step 4. Review the Texas 100 Most Congested Roadways List and Texas 50 Most Congested Freight Bottlenecks:  ",
        "description": ": Next, cross-checking the Texas 100 Most Congested Roadways List and Texas 50 Most Congested Freight Bottlenecks shows two segments in the Texas 100 Most Congested Roadways List and one on the Texas 50 Most Congested Freight Bottlenecks (see Figure 2). A quick check of the overall list shows an additional segment that is number 52 on the Texas 50 Most Congested Freight Bottlenecks list and also number 116 on the Texas 100 Most Congested Roadways List list (see Table 1)."
      },
      {
        "heading":"Problematic Segments in the El Paso Area"
        
      }

    ]
  }

  export default elpaso_docs;