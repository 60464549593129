import React, { useRef, useState, useEffect } from "react";
import "../../index.css";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
// import Contours from "./contourData.json";

import { Form, Button } from "react-bootstrap";

import { downloadAzureStorageGJ } from "../../data-loaders/downloadAzureStorage";

import { contourFilenames } from "../../common/constants";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN;

const ContourView = (props) => {
  const { statusGuide } = props;
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [zoom, setZoom] = useState(7);
  const contourColors = [
    { driveTimeDuration: 60, color: "#8B0000" },
    { driveTimeDuration: 45, color: "#6676ed" },
    { driveTimeDuration: 30, color: "#e6e620" },
    { driveTimeDuration: 15, color: "#f0a3ed" },
  ];

  const [contourData, setCountourData] = useState(null);
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      // center: [-97.2293, 32.9342],
      center: [-99.9018, 31.9686],
      zoom: zoom,
      maxPitch: 65,
    });



    // Add zoom and rotation controls to the map.
    map.current.addControl(
      new mapboxgl.NavigationControl({ visualizePitch: true }),
      "top-left"
    );

    map.current.on("load", () => {
      let fileName = contourFilenames.filter(
        (itm) => itm.statusGuide === statusGuide
      )[0]["am7"];
      downloadAzureStorageGJ("contours", fileName).then((response) => {
        map.current.addSource("duration", {
          type: "geojson",
          data: response,
        });

        map.current.flyTo({
          center: response.features[0].properties.location,
          // essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          // zoom: zoom,
          speed: 1,
          curve: 1.25,
          // easing(t) {
          //   return t;
          // },

          // This can be any easing function: it takes a number between
          // 0 and 1 and returns another number between 0 and 1.
          easing: (t) => t,

          // this animation is considered essential with respect to prefers-reduced-motion
          essential: true,
        });

        map.current.addLayer({
          id: "duration-layer",
          type: "fill-extrusion",
          source: "duration", // reference the data source
          layout: { visibility: "visible" },
          paint: {
            "fill-extrusion-color": [
              "match",
              ["get", "driveTimeDuration"],
              60,
              contourColors.filter((obj) => obj.driveTimeDuration === 60)[0]
                .color,
              45,
              contourColors.filter((obj) => obj.driveTimeDuration === 45)[0]
                .color,
              30,
              contourColors.filter((obj) => obj.driveTimeDuration === 30)[0]
                .color,
              15,
              contourColors.filter((obj) => obj.driveTimeDuration === 15)[0]
                .color,

              "green",
            ],
            "fill-extrusion-opacity": 0.7,

            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["get", "driveTimeDuration"],
              15,
              25000,
              60,
              1000,
            ],

            "fill-extrusion-base": 0,
          },
        });
//console.log("GEOJSON",response.features[0].properties.location)
          // Create a default Marker and add it to the map.
         const marker1 = new mapboxgl.Marker({ color: 'black'})
         .setLngLat(response.features[0].properties.location)
         .addTo(map.current);

        
      });

       
    });
  });

  useEffect(() => {
    if (map.current.getSource("duration")) {
      let fileName = contourFilenames.filter(
        (itm) => itm.statusGuide === statusGuide
      )[0]["am7"];

      downloadAzureStorageGJ("contours", fileName).then((response) => {
        const geojsonSource = map.current.getSource("duration");
        geojsonSource.setData(response);
        // console.log("geojsonSource===",geojsonSource._data.features[0].properties.location)
        map.current.flyTo({
          center: response.features[0].properties.location,
          // essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          // zoom: zoom,
          speed: 1,
          curve: 1.25,
          // easing(t) {
          //   return t;
          // },

          // This can be any easing function: it takes a number between
          // 0 and 1 and returns another number between 0 and 1.
          easing: (t) => t,

          // this animation is considered essential with respect to prefers-reduced-motion
          essential: true,
        });

        // Create a default Marker and add it to the map.
        const marker1 = new mapboxgl.Marker({ color: 'black'})
          .setLngLat(geojsonSource._data.features[0].properties.location)
          .addTo(map.current);

      });

      map.current.setZoom(zoom);
      map.current.setPitch(0);
      map.current.setBearing(0);
    }
    setTimeperiod("am7");
  }, [statusGuide]);

  const [timeperiod, setTimeperiod] = useState("am7");

  const handleTimeChange = (e) => {
    setTimeperiod(e.target.value);
    let fileName = contourFilenames.filter(
      (itm) => itm.statusGuide === statusGuide
    )[0][e.target.value];

    downloadAzureStorageGJ("contours", fileName).then((response) => {
      const geojsonSource = map.current.getSource("duration");
      geojsonSource.setData(response);
    });

    // Create a popup, but don't add it to the map yet.
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.current.on("mouseenter", "duration-layer", (e) => {
      // Change the cursor style as a UI indicator.
      map.current.getCanvas().style.cursor = "pointer";

      // Copy coordinates array.
      // const coordinates = e.features[0].geometry.coordinates.slice();
      const coordinates = e.features[0].geometry.coordinates[0];
      // const description = e.features[0].properties.description;
      //   console.log("coordinates-check", e.features[0].geometry.coordinates[0]);

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup
        //   .setLngLat(coordinates)
        // .setLngLat(e.features[0].geometry.coordinates[0])
        .setLngLat([e.lngLat.lng, e.lngLat.lat])
        .setHTML(
          '<div className="mappop">' +

          '<p style="font-size:14px;margin-bottom: 0px;padding-bottom: 0px;"><strong>Location </strong>  ' +
          e.features[0].properties.locationDescription +
          "</p>" +
          '<p style="font-size:14px;margin-bottom: 0px;padding-bottom: 0px;"> <strong>Drive Time Duration: </strong>' +
          e.features[0].properties.driveTimeDuration +
          "</p>" +

          "</div>"
        )
        .addTo(map.current);
    });

    map.current.on("mouseleave", "duration-layer", () => {
      map.current.getCanvas().style.cursor = "";
      popup.remove();
    });
  };




  return (
    <div>
      <div ref={mapContainer} id="map" className="map-container">
        <div className="legend">
          <h6>Travel time(minutes)</h6>
          <div>
            <span className="l60"></span>60
          </div>
          <div>
            <span className="l45"></span>45
          </div>
          <div>
            <span className="l30"></span>30
          </div>
          <div>
            <span className="l15"></span>15
          </div>
          {/* <div>
            <span className="l10"></span>10 minutes
          </div> */}

          <hr></hr>
          <div>
            <h6>Time Period</h6>

            <Form>
              <Form.Group controlId="timeperiod">
                <Form.Check
                  value="am7"
                  type="radio"
                  aria-label="7 am"
                  label="7 a.m."
                  onChange={handleTimeChange}
                  // checked
                  checked={timeperiod === "am7"}
                />
                <Form.Check
                  value="am12"
                  type="radio"
                  aria-label="12 am"
                  label="12 a.m."
                  onChange={handleTimeChange}
                  checked={timeperiod === "am12"}
                />
                <Form.Check
                  value="pm5"
                  type="radio"
                  aria-label="5 pm"
                  label="5 p.m."
                  onChange={handleTimeChange}
                  checked={timeperiod === "pm5"}
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContourView;
