import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Grid, Box, Card } from "@material-ui/core";
import Papa from "papaparse";
import ForwardIcon from "@mui/icons-material/Forward";

import "./view.css";

import ContourView from "../contour/ContourView";
import HeatMap from "../heatmap/Heatmap";
import Traveltrace from "../travelTraces/travelTrace";
import Tx100View from "../tx100/Tx100View";
import TableView from "../table/Table";
import ScrollTop from "./ScrollTop";

import ElpasoText from "../../data/text/elpasodocs";
import HoustonText from "../../data/text/houston_docs";
import DFWtext from "../../data/text/dfw_docs";
import Itext from "../../data/text/i35_docs";

import { downloadAzureStorage } from "../../data-loaders/downloadAzureStorage";

const ViewLocationVisuals = () => {
  const { search } = useLocation();
  const match = search.match(/location=(.*)/);
  const statusGuide = decodeURI(match?.[1]);

  const gridStyle1 = {
    width: "auto",
    height: "auto",
    backgroundColor: "",
    display: "grid",
    marginX: "150px",
    alignContent: "center",
    // marginY: "100px",
    marginTop: "100px",
  };
  const gridStyle = {
    width: "auto",
    height: "auto",
    backgroundColor: "",
    display: "grid",
    marginX: "150px",
    alignContent: "center",
    // marginY: "100px",
  };

  const dividerStyle = {
    width: "auto",
    height: "20px",
    backgroundColor: "#2DB3D1",
    borderRadius: "50%",
  };

  let textData = "";

  if (statusGuide === "DFW") {
    textData = DFWtext;
  }
  if (statusGuide === "Houston") {
    textData = HoustonText;
  }
  if (statusGuide === "El Paso") {
    textData = ElpasoText;
  }
  if (statusGuide === "I-35") {
    textData = Itext;
  }

  const travelTimeCategories = [
    "12:00A",
    "12:15A",
    "12:30A",
    "12:45A",
    "01:00A",
    "01:15A",
    "01:30A",
    "01:45A",
    "02:00A",
    "02:15A",
    "02:30A",
    "02:45A",
    "03:00A",
    "03:15A",
    "03:30A",
    "03:45A",
    "04:00A",
    "04:15A",
    "04:30A",
    "04:45A",
    "05:00A",
    "05:15A",
    "05:30A",
    "05:45A",
    "06:00A",
    "06:15A",
    "06:30A",
    "06:45A",
    "07:00A",
    "07:15A",
    "07:30A",
    "07:45A",
    "08:00A",
    "08:15A",
    "08:30A",
    "08:45A",
    "09:00A",
    "09:15A",
    "09:30A",
    "09:45A",
    "10:00A",
    "10:15A",
    "10:30A",
    "10:45A",
    "11:00A",
    "11:15A",
    "11:30A",
    "11:45A",
    "12:00P",
    "12:15P",
    "12:30P",
    "12:45P",
    "01:00P",
    "01:15P",
    "01:30P",
    "01:45P",
    "02:00P",
    "02:15P",
    "02:30P",
    "02:45P",
    "03:00P",
    "03:15P",
    "03:30P",
    "03:45P",
    "04:00P",
    "04:15P",
    "04:30P",
    "04:45P",
    "05:00P",
    "05:15P",
    "05:30P",
    "05:45P",
    "06:00P",
    "06:15P",
    "06:30P",
    "06:45P",
    "07:00P",
    "07:15P",
    "07:30P",
    "07:45P",
    "08:00P",
    "08:15P",
    "08:30P",
    "08:45P",
    "09:00P",
    "09:15P",
    "09:30P",
    "09:45P",
    "10:00P",
    "10:15P",
    "10:30P",
    "10:45P",
    "11:00P",
    "11:15P",
    "11:30P",
    "11:45P",
  ];

  let travelTimeTracesTitle =
    "Travel-Time Traces for I-35W Southbound (Denton to Hillsboro)";

  const [travelTimeTraces, setTtravelTimeTraces] = useState({
    wd_meanttmin: [],
    we_meanttmin: [],
    wd_pcttt95min: [],
    we_pcttt95min: [],
    chartTitle: "Travel-Time Traces for I-35W Southbound (Denton to Hillsboro)",
  });

  useEffect(() => {
    downloadAzureStorage("travel-time-traces", "travel_time_traces.csv").then(
      (response) => {
        Papa.parse(response, {
          download: false,
          header: true,
          complete: function(input) {
            // console.log("input-check", input);
            const storageRecords = input.data;
            // console.log("storageRecords-check", storageRecords);

            let dataTemp = [];
            let travelTimeTracesTemp = {
              wd_meanttmin: [],
              we_meanttmin: [],
              wd_pcttt95min: [],
              we_pcttt95min: [],
              chartTitle: "",
            };

            if (statusGuide === "DFW" || statusGuide === "I-35") {
              dataTemp = storageRecords.filter(
                (obj) => obj.area === "i35wsbdenthills"
              );
              travelTimeTracesTemp.chartTitle =
                "Travel-Time Traces for I-35W Southbound (Denton to Hillsboro)";
            }
            if (statusGuide === "Houston") {
              dataTemp = storageRecords.filter(
                (obj) => obj.area === "i10welatohou"
              );
              travelTimeTracesTemp.chartTitle =
                "Travel-Time Traces for I-10 WB";
            }
            if (statusGuide === "El Paso") {
              dataTemp = storageRecords.filter(
                (obj) => obj.area === "i10ebnmtohorizonep"
              );
              travelTimeTracesTemp.chartTitle =
                "Travel-Time Traces for I-10 EB from NM border to Horizon";
            }

            travelTimeCategories.map((ttc) => {
              travelTimeTracesTemp.wd_meanttmin.push(
                parseFloat(
                  dataTemp.filter(
                    (item) => item.daytype === "WD" && item.begintime === ttc
                  )[0].meanttmin
                )
              );
            });

            travelTimeCategories.map((ttc) => {
              travelTimeTracesTemp.we_meanttmin.push(
                parseFloat(
                  dataTemp.filter(
                    (item) => item.daytype === "WE" && item.begintime === ttc
                  )[0].meanttmin
                )
              );
            });

            travelTimeCategories.map((ttc) => {
              travelTimeTracesTemp.wd_pcttt95min.push(
                parseFloat(
                  dataTemp.filter(
                    (item) => item.daytype === "WD" && item.begintime === ttc
                  )[0].pcttt95min
                )
              );
            });

            travelTimeCategories.map((ttc) => {
              travelTimeTracesTemp.we_pcttt95min.push(
                parseFloat(
                  dataTemp.filter(
                    (item) => item.daytype === "WE" && item.begintime === ttc
                  )[0].pcttt95min
                )
              );
              return 0;
            });
            // console.log("travelTimeTracesTemp-check", travelTimeTracesTemp);

            setTtravelTimeTraces(travelTimeTracesTemp);
          },
        });
      }
    );
  }, [statusGuide]);

  return (
    <div className="caseStudies">
      <Box className="grid1" sx={{ ...gridStyle1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <p className="stepDescription">
              TxDOT studied freight fluidity at four key locations in Texas
              important for freight movement. The purpose of this analysis was
              to look at freight movement from a business perspective to assess
              how key routes at freight intensive or freight generating
              locations perform. TxDOT can use this information to prioritize
              investments or operational strategies to improve freight flow, and
              businesses can use this information to make decisions about
              routing, time of travel, and locations.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <p className="stepDescription" style={{ marginTop: "0px" }}>
              These select locations (DFW, El Paso, I-35, and Houston) are
              provided in this freight fluidity tool with updated data and allow
              users to interact with the information shown. These freight
              fluidity analytics involved four steps, and each of the
              interactive visualizations is based on one of these steps.
              <strong>
                <br></br>
                <ForwardIcon /> Step 1{" "}
              </strong>
              is understanding the travel times from a select freight generator
              by time of day (contour maps).
              <strong>
                <br></br>
                <ForwardIcon /> Step 2{" "}
              </strong>
              is assessing the roadway segments or links for volumes or vehicle
              paths during different times of day to know traffic demand.
              <strong>
                <br></br>
                <ForwardIcon /> Step 3{" "}
              </strong>
              is looking at travel time traces to understand congestion and
              reliability at different times of day. This is useful in knowing
              which times of day are best for making a trip when you want to
              minimize delay and potential for unexpected delays.
              <strong>
                <br></br>
                <ForwardIcon />
                Step 4{" "}
              </strong>
              is consulting TxDOT resources, specifically, Texas’ 100 Most
              Congested Roadways to know the bottleneck detail or severity on
              select routes. This information combined can help understand the
              business experience to and from or through key freight areas and
              then work to invest or operated roadways that reduce delay as much
              as possible for Texas industries.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="grid1" sx={{ ...gridStyle }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <ContourView statusGuide={statusGuide} />
            <h6 className="figure1">{textData.steps[0].heading}</h6>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <h6 className="heading">Step 1: Contour Maps </h6>
            <p className="stepDescription">
              {" "}
              Contour maps help show regional travel times for a select time of
              day to or from a freight generator, such as a
              warehouse/distribution center, factory, airport, or port. The most
              congested corridors have a shorter distance from the generator to
              the contour. From a freight fluidity perspective, contour maps can
              help businesses understand the travel time conditions at different
              times of day to and from points they need to travel to assist in
              routing decision making. This contour map uses INRIX Drive Time
              Polygon data for the most recent driving profiles as of February
              2022.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="divider" sx={{ ...dividerStyle }}></Box>

      <Box className="grid2" sx={{ ...gridStyle }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <HeatMap
              statusGuide={statusGuide}
              // locationFlowbandData={locationFlowbandData}
            />
            <h6 className="figure2">{textData.steps[1].heading}</h6>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <h6 className="heading">Step 2: Heat Maps</h6>
            <p className="stepDescription heatmap">
              Heat maps use GPS data to show vehicle paths (trips) crossing a
              selected segment during a certain time, revealing problematic
              areas. This heat map uses INRIX trajectory data for 2019.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="divider" sx={{ ...dividerStyle }}></Box>

      <Box className="grid3" sx={{ ...gridStyle }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Card>
              <Traveltrace
                statusGuide={statusGuide}
                travelTimeTracesTitle={travelTimeTracesTitle}
                travelTimeCategories={travelTimeCategories}
                travelTimeTraces={travelTimeTraces}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <h6 className="heading">Step 3: Travel Time Traces </h6>
            <p className="stepDescription">
              Travel time traces show the average travel time and the 95th
              percentile travel time for a length of roadway beginning at
              various times of day. The 95th percentile time reflects the
              variability in the travel times through the corridor due to
              construction, crashes, or other incidents. Look for gaps between
              the weekday 95th percentile travel time and the average weekday
              travel times. This will indicate congestion is causing the delay
              and that the delay is not a routine bottleneck caused by other
              factors. This travel time trace uses National Performance
              Management Research Data Set (NPMRDS) for 2020.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="divider" sx={{ ...dividerStyle }}></Box>

      <Box className="grid4" sx={{ ...gridStyle }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Tx100View statusGuide={statusGuide} />
            <h6 className="figure3">{textData.steps[4].heading}</h6>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <h6 className="heading">
              Step 4: Texas 100 Most Congested Roadways{" "}
            </h6>
            <p className="stepDescription">
              Cross-checking freight routes with the Texas 100 Most Congested
              Roadway List can help show bottlenecks and offer valuable
              information about the severity of the delay. This can help
              businesses assess routes and locations and can identify where
              investments might help make freight flow most efficient. This list
              uses the Texas 100 Most Congested Roadways data for 2019.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="divider" sx={{ ...dividerStyle }}></Box>

      <Box className="grid5" sx={{ ...gridStyle }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <TableView statusGuide={statusGuide} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <span>
        <ScrollTop />
      </span>
    </div>
  );
};

export default ViewLocationVisuals;
