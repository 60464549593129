import { Link } from "react-router-dom";

import { Grid, Box } from "@material-ui/core";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TexasRoads from "../../images/texas_roads.jpg";

import CallSplitIcon from "@mui/icons-material/CallSplit";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

import { locations } from "../../common/constants";
import ScrollTop from "./ScrollTop";

const gridStyle = {
  width: "auto",
  height: "auto",
  backgroundColor: "",
  display: "flex",
  // paddingY: "100px",
  // marginTop: "25px",
  // paddingBottom: "25px",
  // marginX: "200px",
  margin: "25px",
  alignContent: "center",
};
const gridStyle1 = {
  width: "auto",
  height: "auto",
  // backgroundColor: "#D3D3D3",
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  display: "flex",
  // paddingY: "100px",
  // marginTop: "25px",
  // paddingBottom: "25px",
  // marginX: "200px",
  // margin: "50px",
  padding: "35px",
  paddingLeft: "25px",
  paddingRight: "25px",
  alignContent: "center",
};

const Home = () => {
  return (
    <div className="homelayout">
      <div
        className="homegrid1"
        // style={{
        //   backgroundImage: `url(${TexasRoads})`,
        //   backgroundRepeat: "round",
        //   backgroundPosition: "",
        //   backgroundSize: "contain",
        // }}
        style={{
          backgroundImage: `url(${TexasRoads})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          // opacity: 0.5,
        }}
      >
        <Box sx={{ ...gridStyle1 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              {/* <Card variant="text"> */}
              <h3 className="homeheading">
                Freight Fluidity Visualization Tool
              </h3>
              <h4
                className="homesubheading"
                style={{ marginBottom: 0, paddingBottom: 0 }}
              >
                A Tool for decision making
              </h4>
              {/* </Card> */}
            </Grid>

            <Grid item xs={6} sm={6} style={{ borderRight: "1px solid white" }}>
              <p
                className="homedescription"
                style={{ marginTop: 0, paddingTop: 0 }}
              >
                {/* <h4 className="homesubheading">Freight Fluidity</h4> */}
                Freight Fluidity is measuring trip performance to determine how
                efficiently goods are moving in a region. It involves answering
                questions like:
                <Grid item xs={12}>
                  <WarehouseIcon fontSize="large" /> What are the goods?
                </Grid>
                <Grid item xs={12}>
                  <MoveDownIcon fontSize="large" /> How do they get from point A
                  to point B?
                </Grid>
                <Grid item xs={12}>
                  <CallSplitIcon fontSize="large" /> What’s the route?
                </Grid>
              </p>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <p
                className="homedescription"
                style={{ marginTop: 0, paddingTop: 0 }}
              >
                The tool consists of pages that show freight fluidity
                information and allow interaction with the data. The pages of
                the visualization tool can be accessed using the app bar at the
                top of the page. Each page is also briefly described below.
                Users can return back to this page by clicking on the "Freight
                Fluidity" button in the app bar.
              </p>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card variant="text">
                <ImageList sx={{ width: "auto", height: "auto" }}>
                  <ImageListItem>
                    <img
                    alt="corridors"
                      src={require("../../images/CommodityCorridorsLandingPage.PNG")}
                    />
                  </ImageListItem>
                </ImageList>
              </Card>
            </Grid> */}
          </Grid>
        </Box>
      </div>

      <div className="homegrid2">
        <Box sx={{ ...gridStyle }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card variant="text">
                <ImageList sx={{ width: "auto", height: "auto" }}>
                  <ImageListItem>
                    <img alt="ff" src={require("../../images/DSC_3097.jpg")} />
                  </ImageListItem>
                </ImageList>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Card variant="text">
                <h3 className="homeheading"> Purpose</h3>
                <hr></hr>
                <p className="homedescription">
                  The purpose of this freight fluidity visualization tool is to
                  provide an interactive environment for users to experience the
                  different types of information that help tell the freight
                  fluidity story. This tool provides a snapshot of fluidity
                  information for:
                  <br></br>
                  <span>
                    <AddLocationIcon sx={{ fontSize: "40px" }} />{" "}
                  </span>{" "}
                  Select locations trip performance
                  <br></br>
                  <span>
                    {" "}
                    <EmojiTransportationIcon sx={{ fontSize: "40px" }} />{" "}
                  </span>{" "}
                  Freight performance for key corridors and commodities
                  <br></br>
                  <span>
                    <AllInclusiveIcon sx={{ fontSize: "40px" }} />{" "}
                  </span>{" "}
                  Transportation performance on Texas business supply chains
                  <br></br>
                  <span>
                    {" "}
                    <AddRoadIcon sx={{ fontSize: "40px" }} />{" "}
                  </span>{" "}
                  Freight resiliency
                  <br></br>
                  <span>
                    {" "}
                    <MultilineChartIcon sx={{ fontSize: "40px" }} />{" "}
                  </span>
                  Multimodal trip performance
                </p>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>

      {/* location specific case studies */}
      {/* imagelist */}
      <div className="homegrid3">
        <Box sx={{ ...gridStyle }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card variant="text" sx={{ textAlign: "center" }}>
                <h3 className="homeheading"> Location Specific Case Studies</h3>
                <hr></hr>
                <p className="homedescription">
                  Freight fluidity analysis can help decision-makers understand
                  freight movement and how to more efficiently manage it at the
                  local, state, regional and national levels The Case Studies page
                  shows the information related to case studies of three freight
                  significant locations, meaning that these locations are places
                  in Texas with heavy freight movement. The visualizations show
                  four different ways that these locations were assessed to
                  understand the business user experience and to provide context
                  for freight making trips through these locations. The four
                  approaches to assess how well freight experiences these
                  locations includes:
                </p>
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} className="imagelist">
              <Card variant="text">
                <ImageList sx={{ width: "auto", height: "auto" }}>
                  <ImageListItem>
                    <img
                      alt="contours"
                      src={require("../../images/time-contours.PNG")}
                    />
                  </ImageListItem>
                </ImageList>
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Card variant="text">
                <ImageList sx={{ width: "100%", height: "100%" }}>
                  <ImageListItem>
                    <img
                      alt="heatmaps"
                      src={require("../../images/heatmaps.PNG")}
                    />
                  </ImageListItem>
                </ImageList>
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Card variant="text">
                <ImageList sx={{ width: "100%", height: "100%" }}>
                  <ImageListItem>
                    <img alt="Travel" src={require("../../images/tt.PNG")} />
                  </ImageListItem>
                </ImageList>
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Card variant="text">
                <ImageList sx={{ width: "100%", height: "100%" }}>
                  <ImageListItem>
                    <img alt="tx100" src={require("../../images/tx100.PNG")} />
                  </ImageListItem>
                </ImageList>
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <p className="homesubdescription">
                <span>Contour maps </span> that show how far freight can travel
                by different times of day, useful for route planning.
              </p>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <p className="homesubdescription">
                <span>Heat Maps </span>that show the density of trucks along the
                routes of these locations, useful to understand truck volumes,
                congestion, and key routes.
              </p>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <p className="homesubdescription">
                <span>Travel Time Traces </span>that show how long a trip will
                take through these locations by time of day, useful for routing
                and trip planning.
              </p>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <p className="homesubdescription">
                <span>Texas 100 Most Congested Roadways </span> bottleneck
                mapping, useful for understanding the magnitude of bottlenecks
                based on delay that freight users experience in these locations.
              </p>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card variant="text" sx={{ textAlign: "center" }}>
                <p className="homedescription">
                  The different location specific case studies can be accessed
                  using the dropdown menu in the header bar at the top of the
                  screen label "Case Studies" or clicking on the button below.
                </p>
              </Card>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}></Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Card variant="text" sx={{ marginBottom: "25px" }}>
                <Button variant="contained" className="vizbutton" size="large">
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/case-studies/?location=DFW"
                  >
                    Explore Location Specific Case Studies
                  </Link>
                </Button>
              </Card>
            </Grid>
            {/* <Grid item xs={4} sm={4} md={4} lg={4}></Grid> */}
          </Grid>
        </Box>
      </div>
      {/* <Box className="grid3" sx={{ ...gridStyle }}> */}
      <Box sx={{ ...gridStyle }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card variant="text">
              <h3 className="homeheading">Commodity Corridors</h3>
              <hr></hr>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Card variant="text">
              <ImageList>
                <ImageListItem>
                  <img
                    alt="commodity"
                    src={require("../../images/CommodityCorridorsLandingPage.PNG")}
                  />
                </ImageListItem>
                <ImageListItemBar
                  title="Commodity Corridors Reliability metrics overview"
                  position="below"
                />
              </ImageList>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Card variant="text">
              <p className="homedescription">
                Freight Fluidity analysis helps illustrate why improvements are
                so critical to efficiently move goods and helps justify the
                costs of those improvements. They can also demonstrate how
                proposed improvements can impact the surrounding transportation
                network.<br></br>
                The Commodity Corridors page shows the relative commodity flows
                alongside roadway performance in Texas. This provides a visual
                representation of the user experience for commodities on these
                corridors and where there are the most critical bottlenecks.
                This information can help identify where investments in capacity
                or technology to help with efficiency are needed to support
                specific types of commodities and their routes.
              </p>
              <p className="homedescription"></p>
            </Card>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}></Grid>

          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Card variant="text">
              <Button
                variant="contained"
                title={"Commodity Corridors"}
                className="vizbutton"
                size="large"
              >
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/commodity-corridors"
                >
                  Explore Commodity Corridors
                </Link>
              </Button>
            </Card>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
        </Grid>
      </Box>

      {/* <Box className="footer" xs={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={1}>
            <Box
              component="img"
              sx={{
                height: "7vh",
                m: 1,
                ml: 2,
              }}
              alt="The house from the offer."
              src={require("../../images/Transparent Background Color3lineLOGO_TxDOT_RGB_300dpi.png")}
            />
          </Grid>
          <Grid item xs={1} display="flex">
            <Box
              component="img"
              alt="The house from the offer."
              src={require("../../images/TTI-Color.png")}
              display="flex"
              height={"6vh"}
              alignItems={"right"}
              sx={{ m: 1 }}
            />
          </Grid>
        </Grid>
        <span>
          <ScrollTop />
        </span>
      </Box> */}
      <span>
        <ScrollTop />
      </span>
    </div>
  );
};
export default Home;
