import React, { useState } from "react";
import PropTypes from "prop-types";
import { colorSchemes } from "../../common/constants";

import {
  Grid,
  Typography,
  Box,
  Slider,
  FormControl,
  InputLabel,
  Switch,
  Tooltip,
  Popover,
  Divider,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/FileDownload";

const MapSettings = (props) => {
  const { mapParameters, setMapParameters } = props;
  const [colorSchemeSelected, setColorSchemeSelected] = useState(colorSchemes[0].name);
  const [barColorSelected, setBarColorSelected] = useState("roadwayPerformance");
  const [barHeightSelected, setBarHeightSelected] = useState("commodities");
  const [barOpacity, setBarOpacity] = useState(0.85);
  const [PopupEl, setPopupEl] = useState(null);
  const PopupClick = (event) => {
    setPopupEl(event.currentTarget);
  };
  const PopupClose = () => {
    setPopupEl(null);
  };
  const open = Boolean(PopupEl);

  const handleColorSchemeChange = (event) => {
    setColorSchemeSelected(event.target.value);
    // setMapParameters({
    //   roadwayPerformance: mapParameters.roadwayPerformance,
    //   commodities: mapParameters.commodities,
    //   selectedcommoditiesCategory: mapParameters.selectedcommoditiesCategory,
    //   colorScheme: colorSchemes.filter(
    //     (obj) => obj.name === event.target.value
    //   )[0].colors,
    //   barHeight: mapParameters.barHeight,
    //   barColor: mapParameters.barColor,
    //   barOpacity: mapParameters.barOpacity,
    //   showRoads: mapParameters.showRoads,
    // });

    setMapParameters({
      ...mapParameters,
      colorScheme: colorSchemes.filter(
        (obj) => obj.name === event.target.value
      )[0].colors,
    });
  };
  const handleBarColorChange = (event) => {
    // console.log("event.target.value-check", event.target.value);
    setBarColorSelected(event.target.value);
    // setMapParameters({
    //   roadwayPerformance: mapParameters.roadwayPerformance,
    //   commodities: mapParameters.commodities,
    //   selectedcommoditiesCategory: mapParameters.selectedcommoditiesCategory,
    //   colorScheme: mapParameters.colorScheme,
    //   barHeight: mapParameters.barHeight,
    //   barColor: event.target.value,
    //   barOpacity: mapParameters.barOpacity,
    //   showRoads: mapParameters.showRoads,
    // });

    setMapParameters({
      ...mapParameters,
      barColor: event.target.value,
    });
  };
  const handleBarHeightChange = (event) => {
    setBarHeightSelected(event.target.value);
    // setMapParameters({
    //   roadwayPerformance: mapParameters.roadwayPerformance,
    //   commodities: mapParameters.commodities,
    //   selectedcommoditiesCategory: mapParameters.selectedcommoditiesCategory,
    //   colorScheme: mapParameters.colorScheme,
    //   barHeight: event.target.value,
    //   barColor: mapParameters.barColor,
    //   barOpacity: mapParameters.barOpacity,
    //   showRoads: mapParameters.showRoads,
    // });

    setMapParameters({
      ...mapParameters,
      barHeight: event.target.value,
    });
  };
  const handleBarOpacityChange = (event) => {
    setBarOpacity(event.target.value);
    // setMapParameters({
    //   roadwayPerformance: mapParameters.roadwayPerformance,
    //   commodities: mapParameters.commodities,
    //   selectedcommoditiesCategory: mapParameters.selectedcommoditiesCategory,
    //   colorScheme: mapParameters.colorScheme,
    //   barHeight: mapParameters.barHeight,
    //   barColor: mapParameters.barColor,
    //   barOpacity: event.target.value,
    //   showRoads: mapParameters.showRoads,
    // });

    setMapParameters({
      ...mapParameters,
      barOpacity: event.target.value,
    });
  };
  const handleShowRoadsChange = (event) => {
    // setMapParameters({
    //   roadwayPerformance: mapParameters.roadwayPerformance,
    //   commodities: mapParameters.commodities,
    //   selectedcommoditiesCategory: mapParameters.selectedcommoditiesCategory,
    //   colorScheme: mapParameters.colorScheme,
    //   barHeight: mapParameters.barHeight,
    //   barColor: mapParameters.barColor,
    //   barOpacity: mapParameters.barOpacity,
    //   showRoads: event.target.checked ? "visible" : "none",
    // });

    setMapParameters({
      ...mapParameters,
      showRoads: event.target.checked ? "visible" : "none",
    });
  };

  return (
    <div className="map-control-panel">
      <div>
        <IconButton
          className="icon settings-icon"
          aria-label="map settings"
          size="small"
          onClick={PopupClick}
        >
          <Tooltip
            title={<span style={{ fontSize: 14 }}>{"Map Settings"}</span>}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "common.white",
                  color: "common.black",
                  border: "1px solid",
                },
              },
            }}
            placement="right"
          >
            <SettingsIcon />
          </Tooltip>
        </IconButton>
        <Popover
          id="map-settings-popover"
          open={open}
          anchorEl={PopupEl}
          onClose={PopupClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={{ minWidth: 250, maxWidth: 200, m: 1, p: 1, fontSize: 12 }}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <FormControl fullWidth size="small" sx={{ mt: 2, mb: 2 }}>
                  <InputLabel
                    className="map-control"
                    id="map-color-scheme-select-label"
                  >
                    Color Scheme
                  </InputLabel>
                  <Select
                    className="map-control"
                    labelId="map-color-scheme-label"
                    id="map-color-scheme-select"
                    value={colorSchemeSelected}
                    label="Color Scheme"
                    onChange={handleColorSchemeChange}
                  >
                    {colorSchemes.map((obj, index) => (
                      <MenuItem
                        className="map-control"
                        value={obj.name}
                        key={"map-color-scheme-option-" + index}
                      >
                        {obj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel
                    className="map-control"
                    id="map-bar-color-select-label"
                  >
                    Bar Color
                  </InputLabel>
                  <Select
                    className="map-control"
                    labelId="map-bar-color-label"
                    id="map-bar-color-select"
                    value={barColorSelected}
                    label="Bar Color"
                    onChange={handleBarColorChange}
                  >
                    {[
                      {
                        name: "Roadway Performance",
                        value: "roadwayPerformance",
                      },
                      {
                        name: "Commodities",
                        value: "commodities",
                      },
                    ].map((obj, index) => (
                      <MenuItem
                        className="map-control"
                        value={obj.value}
                        key={"map-bar-color-option-" + index}
                      >
                        {obj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel
                    className="map-control"
                    id="map-bar-height-select-label"
                  >
                    Bar Height
                  </InputLabel>
                  <Select
                    className="map-control"
                    labelId="map-bar-height-label"
                    id="map-bar-height-select"
                    value={barHeightSelected}
                    label="Bar Height"
                    onChange={handleBarHeightChange}
                  >
                    {[
                      {
                        name: "Roadway Performance",
                        value: "roadwayPerformance",
                      },
                      {
                        name: "Commodities",
                        value: "commodities",
                      },
                    ].map((obj, index) => (
                      <MenuItem
                        className="map-control"
                        value={obj.value}
                        key={"map-bar-height-option-" + index}
                      >
                        {obj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography className="map-control" component="div">
                  Bar Opacity: {barOpacity.toFixed(2)}
                  <Slider
                    defaultValue={0.85}
                    aria-label="Road layer opacity"
                    valueLabelDisplay="auto"
                    min={0}
                    max={1}
                    step={0.01}
                    value={barOpacity}
                    onChange={handleBarOpacityChange}
                    sx={{ width: "90%", m: 0, mb: 1, mt: 1, p: 0 }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="map-control" component="div">
                  Show Roads
                  <Switch
                    defaultChecked
                    onChange={handleShowRoadsChange}
                    inputProps={{ "aria-label": "Show roads switch" }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      </div>
      <Divider />
      <div>
        <IconButton
          className="icon export-icon"
          aria-label="export"
          size="small"
          // href="https://arcg.is/1rarjC0"
          href="https://arcg.is/1LK4aj1"
          target="_blank"
        >
          <Tooltip
            title={<span style={{ fontSize: 14 }}>{"Link to downloads"}</span>}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "common.white",
                  color: "common.black",
                  border: "1px solid",
                },
              },
            }}
            placement="right-start"
          >
            <DownloadIcon />
          </Tooltip>
        </IconButton>
      </div>
    </div>
  );
};

MapSettings.propTypes = {
  style: PropTypes.object,
};

export default MapSettings;
