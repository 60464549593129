import React from "react";

import { Box, Grid } from '@mui/material';
import scf from '../../images/scf.png';

const gridStyle =
{
    width: 'auto',
    height: 'auto',
    backgroundColor: '',
    margin:'20px 50px 20px 50px',
    textAlign: 'justify'
}

const ScfBackground = () => {
    return (
        <Box className="supply-chain-management" sx={{ ...gridStyle }}>
            <Grid container>
                <Grid item>
                    <h4>Supply chain fluidity and its activities</h4>
                    <p className="stepDescription">A supply chain is the entire system of producing and distributing services, goods, money, and/or information.
                        Supply chain activities pertain to the different stages, levels or echelons that form the end-to-end supply chain from raw material procurement
                        to market delivery; and may also include activities focused on reverse logistic flows from downstream to upstream supply chain stages.
                        <br />
                        <img className="scf"
                            src={scf}
                            alt="supply-chain-fluidity"
                        />
                    </p>
                    <h4>Supply Chain Fluidity Definition</h4>
                    <p className="stepDescription">
                        Supply Chain Fluidity (SCF) is defined as the movement performance of services, goods, money, and information flows throughout different supply chain activities.
                        Supply Chain Fluidity Measurement focuses on quantitatively assessing how well these flows move through the Supply Chain.
                    </p>
                    <h4>Practical Use/Application</h4>
                    <p className="stepDescription">SCF informs decision-makers about the performance of the supply chain, in terms of services,
                        goods, money and/or information flows movement, so bottlenecks and supply chain weak points can be identified.
                    </p>
                    <h4>Case Study Development</h4>
                    <p className="stepDescription">The research team chose two case studies to apply Supply Chain Fluidity: motor vehicles and computers which are in the form of corridors
                        characterized by the following features:
                        <ol>
                            <li>Geographic: Starting and ending nodes and geography in-between.</li>
                            <li>Infrastructure: Transportation infrastructure segments connecting starting and
                                ending nodes (i.e., roads, railways, seaways) and operational infrastructure at the nodes
                                (e.g., warehouses, distribution centers, manufacturing sites, ports).</li>
                            <li>Product family: Goods in different stages of processing (e.g., raw materials, commodities,
                                components, work-in-progress, finished products) that pertain to the same industry.</li>
                            <li>Supply Chain and Activities: Supply chain levels or echelons with corresponding activities related to flows
                                (e.g., transportation), processing (e.g., manufacturing), or storage (e.g., inventory).</li>
                        </ol>
                        The supply chain for motor vehicles is tracked from the Shanghai, China port as aluminum to its final
                        destination as a complete motor vehicle. Computers were observed from Taiwan to California or
                        Texas as their destination. The selected routes are merely meant to represent potential
                        routes.The corridors were determined based on the most likely traveled truck routes located
                        along the National Highway System (NHS) and the National Highway Freight Network (NHFN)
                    </p>
                    {/* <h4>Motor Vehicles Case Study</h4> */}
                    {/* <p className="stepDescription"> It walks through the entire supply chain and includes specific route options and calculations
                        at each step in the supply chain. It is important to remember that the Supply Chain Fluidity
                        estimation does not consider manufacturing and lead times within the supply chain process.
                        The research team calculated the travel time for the six possible combination options.
                        The options are as follows:
                        <ul>
                            <li>Option 1: Trucks Using I-10 to El Paso, Texas, that End in Austin, Texas</li>
                            <span>
                                For a truck that departs the Port of Long Beach, California, and arrives in El Paso, Texas,
                                using I-10, where the final destination is the car dealership in Austin, Texas, the average total
                                travel time for one sheet of aluminum to be made into a motor vehicle is 356.8 days and free-flow
                                travel time is 302.7 days. The SCFI for this route is 0.808, a reasonably good indicator that this
                                supply chain was operating efficiently between January 2020 and June 2022.
                            </span>
                            <li>Option 2: Trucks Using I-10 to El Paso, Texas, that End in El Monte, California</li>
                            <span>
                                For a truck that departs the Port of Long Beach, California, and arrives in
                                El Paso, Texas, using I-10, where the final destination is the car dealership
                                in El Monte, California, the average total travel time for one sheet of
                                aluminum to be made into a motor vehicle is 359.8 days and free-flow travel
                                time is 305.3 days. The SCFI for this route is 0.848, which is still a
                                reasonably good indicator that this supply chain was operating efficiently
                                between January 2020 and June 2022
                            </span>
                            <li>Option 3: Trucks Using I-8 to El Paso, Texas, that End in Austin, Texas</li>
                            <span>
                                For a truck that departs the Port of Long Beach, California, and arrives in El Paso,
                                Texas, using I-8, where the final destination is the car dealership in Austin,
                                Texas, the average total travel time for one sheet of aluminum to be made into
                                a motor vehicle is 356.8 days and free-flow travel time is 302.7 days.
                                The SCFI for this route is 0.848, a reasonably good indicator that this
                                supply chain was operating efficiently between January 2020 and June 2022.
                            </span>
                            <li>Option 4: Trucks Using I-8 to El Paso, Texas, that End in El Monte, California</li>
                            <span>
                                For a truck that departs the Port of Long Beach, California, and arrives in El Paso, Texas,
                                using I-8, where the final destination is the car dealership in El Monte, California,
                                the average total travel time for one sheet of aluminum to be made into a motor vehicle is
                                359.9 days and free-flow travel time is 305.3 days. The SCFI for this route is also 0.848, a
                                reasonably good indicator that this supply chain was operating efficiently between
                                January 2020 and June 2022.
                            </span>
                            <li>Option 5: Intermodal Transportation to El Paso, Texas, that End in Austin, Texas</li>
                            <span>
                                For an intermodal trip to El Paso, Texas, where the final destination is the car
                                dealership in Austin, Texas, the average total travel time for one sheet of
                                aluminum to be made into a motor vehicle is 358.1 days and free-flow travel time
                                is 303.7 days. The SCFI for this route is 0.848, a reasonably good indicator that
                                this supply chain was operating efficiently between January 2020 and June 2022.
                            </span>
                            <li>Option 6: Intermodal Transportation to El Paso, Texas, that End in El Monte,
                                California</li>
                            <span>
                                For an intermodal trip to El Paso, Texas, where the final destination is the car
                                dealership in El Monte, California, the average total travel time for one sheet of
                                aluminum to be made into a motor vehicle is 361.1, and free-flow travel time is 306.3 days.
                                The SCFI for this route is 0.848, a reasonably good indicator that this supply chain was
                                operating efficiently between January 2020 and June 2022.
                            </span>
                        </ul>
                    </p> */}
                    {/* <h4>Computer Case Study</h4>
                    <p className="stepDescription">
                    The production of the Apple Mac Pro, manufactured in Austin, Texas, was used as a proxy to 
                    determine the best route options in the case study. The supply chain routes were chosen based 
                    on assumptions and intelligence gathered about the specific supply chain. No company-specific 
                    data was used in the Supply Chain Fluidity estimation. The following options are listed as follow:
                    <ol>
                        <li>Option 1: Trucks Using US-290 to Houston, Texas</li>
                        <span>
                        For the route option that has the truck delivering computers to the retailer in Houston 
                        via US-290, the average total travel time for one computer chip to be installed into a
                        computer is 190.9 days, and the free-flow travel time is 173.9 days. The SCFI for this 
                        route is 0.911.
                        </span>
                        <li>Option 2: Trucks Using Hwy 71/I-10 to Houston, Texas</li>
                        <span>
                        The route option that has the truck delivering computers to the retailer in Houston via 
                        Hwy 71/I-10 also has an average total travel time of 190.9 days and a free-flow travel time
                         of 173.9 days. The SCFI for this route is 0.911.
                        </span>
                        <li>Option 3: Trucks Using I-10 to Elk Grove, California</li>
                        <span>
                        The route option that has the truck delivering computers to the wholesaler/warehouse in Elk Grove via
                        I-10 has an average total travel time of 180.7 days and a free-flow travel time of 166.1 days. 
                        The SCFI for this route is 0.919. The SCFI may indicate to transportation planners that the routes to 
                        California are operating slightly more efficiently than those to Houston.
                        </span>
                        <li>Option 4: Trucks Using I-40 to Elk Grove, California</li>
                        <span>
                        The route option that has the truck delivering computers to the wholesaler/warehouse in 
                        Elk Grove via I-40 has an average total travel time of 180.7 days and a free-flow travel 
                        time of 166.1 days. The SCFI for this route is 0.919.
                        </span>
                    </ol>
                    </p>
                    <a href="javascript;:" target="_blank">Click for more information</a> */}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ScfBackground;