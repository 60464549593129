import React, { useState, useEffect } from "react";

import {
  Grid,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import '../ViewPage/view.css';



function BorderScs(props) {


  return (

    <Grid container spacing={0} >
       

        <Grid  xs={12} >
          <iframe className="border-supply-chain"
            src={'https://app.powerbi.com/view?r=eyJrIjoiNTVlZWJjOGItZmYzNy00MWM3LWJmYTktODQyZDM0MzNmNDE3IiwidCI6IjVmMThkYjczLTIwZTYtNGEwOC05ZWVjLWUzNjY5MTZiZTg3MSIsImMiOjN9'}
            title="Border Supply chain sensitives iframe"
            width="100%"
            frameborder="0"
            // allowfullscreen
            allow="geolocation"
          ></iframe>
        </Grid>

        
        </Grid>
    
  );
}
export default BorderScs;
