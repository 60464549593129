import React, { useRef, useState, useEffect } from "react";
import "../../index.css";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

import axios from "axios";

import { locations, commodities } from "../../common/constants";

import { downloadAzureStorageGJ } from "../../data-loaders/downloadAzureStorage";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN;

const Heatmap = (props) => {
  const { statusGuide } = props;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom, setZoom] = useState(7);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      // center: [-97.2293, 32.9342],
      center: [-99.9018, 31.9686],
      zoom: zoom,
    });

    // Add zoom and rotation controls to the map.
    map.current.addControl(
      new mapboxgl.NavigationControl({ visualizePitch: true }),
      "top-left"
    );

    let flowbandFile = "";
    if (statusGuide === "DFW") flowbandFile = "I35_flowband.geojson";
    if (statusGuide === "El Paso") flowbandFile = "elpaso_I10_flowband.geojson";
    if (statusGuide === "Houston")
      flowbandFile = "houston_I10_flowband.geojson";
    if (statusGuide === "I-35") flowbandFile = "I35_flowband.geojson";

    map.current.on("load", () => {
      downloadAzureStorageGJ("flowbands", flowbandFile).then((response) => {
        let tempData = response.features;
        //   console.log("tempData-check", tempData);
        let maxValue = 0;
        let minValue = 1000000000;
        tempData.map((obj) => {
          maxValue = Math.max(maxValue, obj.properties.freq);
          minValue = Math.min(minValue, obj.properties.freq);
        });
        //   console.log("min-maxValue-check", maxValue, minValue);
        setMinMaxFreq({ min: minValue, max: maxValue });

        map.current.setCenter(
          locations.filter((obj) => obj.name === statusGuide)[0].location
        );
        map.current.setZoom(zoom);
        map.current.setPitch(0);
        map.current.setBearing(0);

        map.current.addSource("segId", {
          type: "geojson",
          // lineMetrics: true,
          data: response,
        });

        // the layer must be of type 'line'
        map.current.addLayer({
          type: "line",
          source: "segId",
          id: "segId",
          paint: {
            "line-color": [
              "interpolate",
              ["linear"],
              ["get", "freq"],
              minMaxFreq.min,
              ["to-color", "#04c96a"],
              (minMaxFreq.min + minMaxFreq.max) * 0.25,
              ["to-color", "#f0a3ed"],
              (minMaxFreq.min + minMaxFreq.max) * 0.5,
              ["to-color", "#e6e620"],
              (minMaxFreq.min + minMaxFreq.max) * 0.75,
              ["to-color", "#6676ed"],
              minMaxFreq.max,
              ["to-color", "#fc0905"],
            ],
            "line-width": [
              "interpolate",
              ["linear"],
              ["get", "freq"],
              minMaxFreq.min,
              4,
              minMaxFreq.max,
              13,
            ],
          },
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
        });
      });
    });
  });

  const [minMaxFreq, setMinMaxFreq] = useState({
    min: 100,
    max: 4000,
  });
  useEffect(() => {
    let flowbandFile = "";
    if (statusGuide === "DFW") flowbandFile = "I35_flowband.geojson";
    if (statusGuide === "El Paso") flowbandFile = "elpaso_I10_flowband.geojson";
    if (statusGuide === "Houston")
      flowbandFile = "houston_I10_flowband.geojson";
    if (statusGuide === "I-35") flowbandFile = "I35_flowband.geojson";
    if (map.current.getSource("segId")) {
      downloadAzureStorageGJ("flowbands", flowbandFile).then((response) => {
        let tempData = response.features;
        //   console.log("tempData-check", tempData);
        let maxValue = 0;
        let minValue = 1000000000;
        tempData.map((obj) => {
          maxValue = Math.max(maxValue, obj.properties.freq);
          minValue = Math.min(minValue, obj.properties.freq);
        });
        //   console.log("min-maxValue-check", maxValue, minValue);
        setMinMaxFreq({ min: minValue, max: maxValue });

        const geojsonSource = map.current.getSource("segId");
        geojsonSource.setData(response);

        map.current.setPaintProperty({
          "line-color": [
            "interpolate",
            ["linear"],
            ["get", "freq"],
            minValue,
            ["to-color", "#04c96a"],
            (minValue + maxValue) * 0.25,
            ["to-color", "#f0a3ed"],
            (minValue + maxValue) * 0.5,
            ["to-color", "#e6e620"],
            (minValue + maxValue) * 0.75,
            ["to-color", "#6676ed"],
            maxValue,
            ["to-color", "#fc0905"],
          ],
          "line-width": [
            "interpolate",
            ["linear"],
            ["get", "freq"],
            minValue,
            10,
            maxValue,
            18,
          ],
        });
      });

      map.current.setCenter(
        locations.filter((obj) => obj.name === statusGuide)[0].location
      );
      map.current.setZoom(zoom);
      map.current.setPitch(0);
      map.current.setBearing(0);
    }

    // Create a popup, but don't add it to the map yet.
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.current.on("mouseenter", "segId", (e) => {
      // Change the cursor style as a UI indicator.
      map.current.getCanvas().style.cursor = "pointer";

      // Copy coordinates array.
      // const coordinates = e.features[0].geometry.coordinates.slice();
      const coordinates = e.features[0].geometry.coordinates[0];
      // const description = e.features[0].properties.description;
      //   console.log("coordinates-check", e.features[0].geometry.coordinates[0]);

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup
        //   .setLngLat(coordinates)
        // .setLngLat(e.features[0].geometry.coordinates[0])
        .setLngLat([e.lngLat.lng, e.lngLat.lat])
        .setHTML(
          '<div className="mappop">' +
            
            '<p style="font-size:14px;margin-bottom: 0px;padding-bottom: 0px;"> <strong>Road: </strong>' +
            e.features[0].properties.roadname +
            "</p>" +
            
            '<p style="font-size:14px;margin-bottom: 0px;padding-bottom: 0px;"> <strong>Frequency: </strong>' +
            e.features[0].properties.freq +
            "</p>" +
            "</div>"
        )
        .addTo(map.current);
    });

    map.current.on("mouseleave", "segId", () => {
      map.current.getCanvas().style.cursor = "";
      popup.remove();
    });
  }, [statusGuide]);

  return (
    <div>
      <div ref={mapContainer} id="heatmap" className="map-container">
        <div className="legend legend-heatmap">
          
          <h5>Heatmap</h5>
          <div className="d-flex">
            <span className="l60"></span>
            <h6>&lt; {minMaxFreq.max.toLocaleString()} trips (High)</h6>
          </div>
        
          <div className="d-flex">
            <span className="l45"></span>
            <h6>&lt; {((minMaxFreq.min + minMaxFreq.max) * 0.75).toLocaleString()} trips (High-medium) </h6>  
          </div>
          
          <div className="d-flex">
            <span className="l30"></span>
            <h6>&lt; {((minMaxFreq.min + minMaxFreq.max) * 0.5).toLocaleString()} trips (Medium)</h6>  
          </div>
        
          <div className="d-flex">
            <span className="l15"></span>
           <h6>&lt; {((minMaxFreq.min + minMaxFreq.max) * 0.25).toLocaleString()} trips (Medium-low)</h6>  
          </div>
         
          <div className="d-flex">
            <span className="l10"></span>
            <h6>&lt; {minMaxFreq.min.toLocaleString()} trips (Low)</h6>  
          </div>

        </div>
      </div>
    </div>
  );
};
export default Heatmap;
